import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import hotels from "image/LogoSafmo.png";
import {
  ConnexionBody,
  ImageLogin,
  LogoForm,
} from "./LoginPageStyle";
import { DASHBOARD_PATH } from "routes/navigation/navigationPaths";
import { useLoginUserMutation } from "actions/auth/mutations";
import {
  addNewVoyageur,
  setAccessToken,
  setFirstName,
  setLastName,
  setRefreshToken,
  setUser,
} from "redux/features/userSliceWithTokenValidation";
import { useDispatch } from "react-redux";
import COLORS from "utils/Variables/color";
import { useFormContext } from "react-hook-form";
import imageLogin1 from "../../image/ImageLogin1.png";
import imageLogin2 from "../../image/ImageLogin2.png";
import imageLogin3 from "../../image/ImageLogin3.png";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "pages/Admin/DashboardAdmin/GestionVoyageur/Cadre.css";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";

const ConnexionForm = () => {
  const images = [imageLogin1, imageLogin2, imageLogin3];
  const { register, handleSubmit, formState, reset } = useFormContext();
  const [loginUser, { isLoading }] = useLoginUserMutation();
  const [remember, setRemember] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const handleLogin = async (data) => {
    const { data: userData, error } = await loginUser(data);
    try {
      if (error) {
        if (error.status === 401) {
          toast.error("Vous n'êtes pas autorisé à vous connecter.");
        } else {
          toast.error("Une erreur est survenue lors de la connexion.");
        }
      } else {
        if (userData.status === "ADMIN") {
          const userId = userData.id;
          dispatch(setUser(userId));
          dispatch(setAccessToken(userData.access));
          dispatch(setRefreshToken(userData.refresh));
          dispatch(setFirstName(userData.first_name));
          dispatch(setLastName(userData.last_name));
          dispatch(addNewVoyageur(userData.voyageur));
          toast.success("Connexion réussie.");
          navigate(`/${DASHBOARD_PATH}`);
          reset();
        }
      }
    } catch (err) {
      console.log("Error during login:", err);
    }
  };
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="overflow-x-hidden">
      <ConnexionBody className="row">
        <div className="d-none d-md-block col-md-6 col-lg-6 col-xl-6 p-0">
          <ImageLogin imageUrl={images[currentImageIndex]} />
        </div>
        <div className="col-12 col-md-6 p-0">
          <div className="d-flex flex-column align-items-center justify-content-center px-4">
          <div className="text-center mt-3">
            <LogoForm src={hotels} alt="Hotels" className="w-25" />
          </div>
          <form
            className=""
            autoComplete="off"
            onSubmit={handleSubmit(handleLogin)}
          >
            <div
              className="d-flex flex-column justify-content-center gap-2"
            >
            <div style={{ fontSize: "26px", fontWeight: "600" }} className="py-4">
              Se connecter en tant que Admin
              </div>
              <div>
                <label htmlFor="mail" className="fw-bold pb-1">E-mail</label>
              <div className="input-group mb-3 border border-black rounded">
                <span className="input-group-text py-3 bg-transparent border border-0" id="basic-addon1">
                  <MdOutlineAlternateEmail />
                </span>
                <input
                  type="text"
                  className="form-control p-0 border border-0 bg-transparent"
                  placeholder="information@gmail.com"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  id="mail"
                  {...register("email", { required: true })}
                />
              </div>
              {formState.errors && formState.errors.email && (
                <div
                  className="alert alert-danger gfa-alert-danger"
                  role="alert"
                >
                  {formState.errors.email?.message}
                </div>
              )}
              </div>
              <div>
              <label htmlFor="password" className="fw-bold pb-1">Mot de passe</label>
                <div className="input-group mb-3 border border-black rounded">
                  <span className="input-group-text py-3 bg-transparent border border-0" id="basic-addon1">
                    <FaLock />
                  </span>
                  <input
                    style={{ outline: "none" }}
                    type={showPassword ? "text" : "password"}
                    className="form-control p-0 bg-transparent border border-0"
                    placeholder="................."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    id="password"
                    {...register("password", { required: true })}
                  />
                  <span className="d-flex align-items-center p-3"
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ cursor: "pointer" }}>
                     {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
                {formState.errors && formState.errors.password && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState.errors.password?.message}
                  </div>
                )}
              </div>
                <div className="text-end">
                  <span
                    className="me-1"
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Mot de passe oublié?
                  </span>
                  <Link to="/auth/forgetPassword">
                    <span
                      style={{
                        fontSize: "16px",
                        textDecoration: "none",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      Cliquez ici
                    </span>
                  </Link>
                </div>
              <div className="form-check d-flex align-items-center gap-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="remember"
                      style={{
                        width: "24px",
                        height: "24px",
                        border: "2.67px solid #9E9E9E",
                      }}
                      checked={remember}
                      onChange={(e) => setRemember(e.currentTarget.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="remember"
                      style={{
                        color: "rgba(0, 0, 0, 0.87)",
                        fontFamily: "Roboto",
                        fontSize: "18.67px",
                        fontWeight: 400,
                        marginTop: "2px",
                      }}
                    >
                     Se souvenir de moi
                    </label>
                  </div>
              </div>
              <div className="pt-5">
                <button
                  type="submit"
                  className="w-100"
                  style={{
                    backgroundColor: `${COLORS.activeColor}`,
                    borderRadius: "5.333px",
                    padding: "12px 0px",
                    border: "none",
                    fontSize: "21.33px",
                    fontWeight: "500",
                    color: "#000",
                  }}
                >
                  <span>{isLoading ? "Connexion..." : "Se connecter"}</span>
                </button>
            </div>
          </form>
          </div>
        </div>
        <ToastContainer className="mt-5" />
      </ConnexionBody>
    </div>
  );
};

export default ConnexionForm;
