import { ApiMananger } from "actions/rtkquery";
// import { serviceBaseEndpoint } from "routes/api/endpoints";

export const preCheckInApi = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    getServiceBase: builder.query({
      query: (id) => ({
        url: `${id}`,
        method: "GET",
      }),
    }),
    getAllServiceBase: builder.query({
      query: () => ({
        url: "",
        method: "GET",
      }),
    }),
    getFormInStay: builder.query({
      query: (id) => ({
        url: `cofiguration/instay/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetServiceBaseQuery, useGetAllServiceBaseQuery, useGetFormInStayQuery } =
  preCheckInApi;
