import React from "react"
import { InputSelect, BoutonFiltre } from "pages/Admin/DashboardAdmin/GestionVoyageur/GestionVoyageurCss"
import PropTypes from "prop-types";

export const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  return (
    <div className="pagination">
      <BoutonFiltre onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
        Précédent
      </BoutonFiltre>
      <div>
      {Array.from({ length: totalPages }, (_, index) => (
        <button
          key={index + 1}
          onClick={() => onPageChange(index + 1)}
          className={index + 1 === currentPage ? "active" : ""}
        >
          {index + 1}
        </button>
      ))}
      </div>
      <BoutonFiltre onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>
        Suivant
      </BoutonFiltre>
    </div>
  );
};

export const PaginationFiltre = ({ limit, onChange }) => {
    return (
      <div className="">
        <InputSelect className="border-0" value={limit} onChange={onChange}>
          <option value="5">5 par page</option>
          <option value="15">15 par page</option>
          <option value="20">20 par page</option>
          <option value="25">25 par page</option>
          <option value="30">30 par page</option>
          <option value="50">50 par page</option>
        </InputSelect>
      </div>
    );
  };

  Pagination.propTypes = {
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
  };

  PaginationFiltre.propTypes = {
    limit: PropTypes.number.isRequired,
    onLimitChange: PropTypes.func.isRequired,
  };