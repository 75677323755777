import styled from "styled-components";

// .navbar-container {
//   display: flex;
//   flex-wrap: wrap;
//   padding: 5px;
//   background-color: "#FFFFFF" !important;
//   position: fixed;
//   z-index: 1000;
//   height: 55px;
//   border: none;
//   margin: 0;
//   width: 76.5%;
//   margin-top: 15px;
// }

export const NavbarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  background-color: #ffffff !important;
  position: fixed;
  z-index: 1000;
  height: 55px;
  border: none;
  margin: 0;
  width: 80%;
`;

export const ImgSearchInput = `
  data:image/svg+xml,
  %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E
    %3Cpath d='M15.5 14h-.79l-.28-.27a6.5 6.5 0 1 0-.7.7l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 
    0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z' fill='%23000' /%3E
  %3C/svg%3E
`;

export const SearchInput = styled.input`
  font-size: 14px;
  padding: 12px 10px 12px 38px;
  border-radius: 20px;
  border: 1px solid #ccc;
  width: 415px;
  height: 34px;
  background-image: url("${ImgSearchInput}");
  background-repeat: no-repeat;
  background-position: 10px 50%;
  background-size: 20px 20px;
  outline: none;

  ::placeholder {
    color: black;
  }

  @media (max-width: 882px) {
    width: 150px;
  }
  @media (max-width: 436px) {
    width: 50px;
  }
`;
