import React from "react";
import { ProgressBar } from "primereact/progressbar";
import "./customProgressBar.css";

export default function BarProgress({ value, nomHotel, prix }) {
  return (
    <div className="my-2">
      <div className="d-flex justify-content-between">
        <p className="nomHotel">{nomHotel}</p>
        <p className="nomHotel">{prix}</p>
      </div>
      <ProgressBar
        value={value}
        style={{ height: "10px" }}
        className="custom-progress-success"
      >
        {null}
      </ProgressBar>
    </div>
  );
}
