import {
  logout,
  setAccessToken,
} from "redux/features/userSliceWithTokenValidation";
// import api from "routes/api/global-vars";
import { authentificate } from "routes/api/endpoints";
import { ApiMananger } from "actions/rtkquery";

export const API = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: ({ email, password }) => ({
        url: authentificate.onLogin(),
        method: "POST",
        body: { email, password },
      }),
    }),
    logoutUser: builder.mutation({
      query: () => ({
        url: authentificate.onLogout(),
        method: "POST",
      }),
      onSuccess: (_, { dispatch }) => {
        dispatch(logout());
      },
    }),
    refreshToken: builder.mutation({
      query: () => ({
        url: authentificate.onRefreshToken(),
        method: "POST",
      }),
      onSuccess: (response, { dispatch }) => {
        const { access } = response.data;
        dispatch(setAccessToken(access));
      },
    }),
    forgotPassword: builder.mutation({
      query: ({ email }) => ({
        url: authentificate.onForgotPassword(),
        method: "POST",
        body: { email },
      }),
      onSuccess: (response, { dispatch }) => {
        const message = response.data.message;
        if (message) {
          dispatch(showSuccessMessage(message));
        } else {
          dispatch(
            showSuccessMessage(
              "Instructions de réinitialisation envoyées avec succès.",
            ),
          );
        }
      },
    }),
    resetPassword: builder.mutation({
      query: ({ code, email, new_password_confirm, new_password }) => ({
        url: authentificate.onResetPassword(),
        method: "POST",
        body: { code, email, new_password_confirm, new_password },
      }),
    }),
  }),
});

export const {
  useLoginUserMutation,
  useLogoutUserMutation,
  useRefreshTokenMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = API;

export default API;
