/*
|--------------------------------------------------------------------------
! Add Your Application andpoints here
|--------------------------------------------------------------------------
*/

export const DEFAULT_API = process.env.REACT_APP_API_URL;

export const authentificate = {
    onLogin() {
        return "auth/login/";
    },
    onLogout() {
        return "auth/logout/";
    },
    onRefreshToken() {
        return "auth/refresh/";
    },
    onForgotPassword() {
        return "request-reset-password/";
    },
    onResetPassword() {
        return "reset-password/";
    }
};

export const usersEndpoint = {
    getUsers() {
        return "users/";
    },
    getCurrentUser() {
        return "users/me";
    }
    // Autres méthodes
};
export const voyageurReservationEndpoint = {
    addNewVoyageurReservation() {
        return "voyageur_reservation/";
    }
    // Autres méthodes
};
export const voyageurEndpoint = {
    addNewVoyageur() {
        return "voyageur/";
    }
    // Autres méthodes
};

export const reservationEndpoint = {
    addNewReservation() {
        return "reservation/";
    }
    // Autres méthodes
};

export const roomsEndpoint = {
    getRooms() {
        return "chambre/";
    }
    // Autres méthodes
};
export const HotelierEndpoint = {
    addNewHotelier() {
        return "create_hoteliers/";
    },
    updateHotelier(id) {
        return `hoteliers/${id}/`;
    },
    deleteHotelier(id) {
        return `hoteliers/${id}/`;
    },
    getAllHoteliers() {
        return "hoteliers_by_admin/";
    }
    // Autres méthodes
};
export const charteGrapiqueEndpoint = {
    charteGraphique(id) {
        return `charte_grapique/${id}/`;
    }
};
export const serviceBaseEndpoint = {
    addServiceBase() {
        return "services_base/";
    },
    getAllServiceBase() {
        return "services_base/";
    },
    getServiceBaseById(id) {
        return `services_base/${id}`;
    },
    editServiceBase(id) {
        return `services_base/${id}`;
    },
    deleteServiceBase(id) {
        return `services_base/${id}`;
    },
    updateServiceBase(id) {
        return `services_base/${id}`;
    }
};
export const serviceCommandeeEndpoint = {
    addServiceCommandee() {
        return "services_commandees/";
    },
    getAllServiceCommandee() {
        return "services_commandees/";
    },
    getServiceCommandeeById(id) {
        return `services_commandees/${id}`;
    },
    editServiceCommandee(id) {
        return `services_commandees/${id}`;
    },
    deleteServiceCommandee(id) {
        return `services_commandees/${id}`;
    },
    updateServiceCommandee(id) {
        return `services_commandees/${id}`;
    }
};
export const formulaireEndpoint = {
    addFormulaire(id) {
        return `hotels/${id}/forms/`;
    },
    getAllFormulaire(id) {
        return `hotels/${id}/forms/`;
    },
    getFormulaireById(id_hotel, id_form) {
        return `hotels/${id_hotel}/forms/${id_form}`;
    },
    editFormulaire(id_hotel, id_form) {
        return `hotels/${id_hotel}/forms/${id_form}`;
    },
    deleteFormulaire(id_hotel, id_form) {
        return `hotels/${id_hotel}/forms/${id_form}`;
    }
};

export const configEmailEndpoint = {
    getConfigEmail(hotelId) {
        return `config-emails/?hotel_id=${hotelId}`;
    },
    updateConfigEmail(hotelId) {
        return `config-emails/?hotel_id=${hotelId}`;
    },
    getSingleConfigEmail(configId) {
        return `update-config/${configId}/`;
    },

    // Update a specific configuration email by its ID
    updateConfigEmailbyId(configId) {
        return `update-config/${configId}/`;
    }
};

export const emailEndpoint = {
    getAllEmails() {
        return "emails/";
    },
    getEmailById(emailId) {
        return `emails/${emailId}/`;
    },
    updateEmail(emailId) {
        return `emails/${emailId}/`;
    },
    deleteEmail(emailId) {
        return `emails/${emailId}/`;
    },
    createEmail() {
        return "create-email/";
    }
};
