import { ApiMananger } from "actions/rtkquery";
import { serviceBaseEndpoint } from "routes/api/endpoints";

export const serviceBaseApi = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    addServiceBase: builder.mutation({
      query: (serviceData) => ({
        url: serviceBaseEndpoint.addServiceBase(),
        method: "POST",
        body: serviceData,
      }),
    }),
    updateServiceBase: builder.mutation({
      query: ({ id, serviceData }) => ({
        url: serviceBaseEndpoint.editServiceBase(id),
        method: "PUT",
        body: serviceData,
      }),
    }),
    patchServiceBase: builder.mutation({
      query: ({ id, serviceData }) => ({
        url: serviceBaseEndpoint.updateServiceBase(id),
        method: "PATCH",
        body: serviceData,
      }),
    }),
    deleteServiceBase: builder.mutation({
      query: (id) => ({
        url: serviceBaseEndpoint.deleteServiceBase(id),
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddServiceBaseMutation,
  useUpdateServiceBaseMutation,
  usePatchServiceBaseMutation,
  useDeleteServiceBaseMutation,
} = serviceBaseApi;
