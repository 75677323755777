import React, { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import styled from "styled-components";

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: -90px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
  border-radius: 6px;
  margin-top: 12px;
  width: 250px;
`;

const MenuItem = styled.p`
  margin: 0;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #d3eafd;
  }
`;

const HotelDropdown = ({ nomHotel, setNomHotel }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <DropdownContainer>
      <div
        onClick={toggleDropdown}
        style={{
          marginLeft: 10,
          marginRight: 15,
          color: "#000000",
          fontFamily: "Roboto",
          fontWeight: "400",
          cursor: "pointer",
          transition: "color 0.3s",
          position: "relative",
        }}
        aria-haspopup="true"
        aria-expanded={isDropdownVisible}
        role="button"
      >
        Azalaï Hôtel {nomHotel} <IoMdArrowDropdown />
      </div>
      {isDropdownVisible && (
        <DropdownMenu>
          <MenuItem onClick={() => setNomHotel(" Dakar")}>
            <a className="dropdown-item" href="#">
              Azalaï Hôtel Dakar
            </a>
          </MenuItem>
          <MenuItem onClick={() => setNomHotel(" Abidjan")}>
            <a className="dropdown-item" href="#">
              Azalaï Hôtel Abidjan
            </a>
          </MenuItem>
          <MenuItem onClick={() => setNomHotel(" Bamako")}>
            <a className="dropdown-item" href="#">
              Azalaï Hôtel Bamako
            </a>
          </MenuItem>
          <MenuItem onClick={() => setNomHotel(" Nouakchott")}>
            <a className="dropdown-item" href="#">
              Azalaï Hôtel Nouakchott
            </a>
          </MenuItem>
          <MenuItem onClick={() => setNomHotel(" Cotonou")}>
            <a className="dropdown-item" href="#">
              Azalaï Hôtel Cotonou
            </a>
          </MenuItem>
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default HotelDropdown;
