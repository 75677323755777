import { ApiMananger } from "actions/rtkquery";

const inStayApi = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    addInStay: builder.mutation({
      query: (serviceData) => ({
        url: "cofiguration/instay/",
        method: "POST",
        body: serviceData,
      }),
    }),
    updateInStay: builder.mutation({
      query: ({ id, serviceData }) => ({
        url: `${id}`,
        method: "PUT",
        body: serviceData,
      }),
    }),
    patchInStay: builder.mutation({
      query: ({ id, serviceData }) => ({
        url: `${id}`,
        method: "PATCH",
        body: serviceData,
      }),
    }),
    deleteInStay: builder.mutation({
      query: (id) => ({
        url: `${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddInStayMutation,
  useDeleteInStayMutation,
  usePatchInStayMutation,
  useUpdateInStayMutation,
} = inStayApi;
