import React, { useEffect, useState } from "react";
import {
  FormContainer,
  Label,
  CardBody,
  Button,
} from "components/ConfigHotel/StyledCard";
import {
  Input,
  FormGroup,
  FormTitle,
} from "pages/Admin/DashboardAdmin/HotelConfig/Hotel/StyledHotel";
import { ButtonRow } from "pages/Admin/DashboardAdmin/HotelConfig/Hotel/StyledHotel";
import defaultImage from "image/defaultImage.svg";
import { useAddServiceBaseMutation } from "actions/ServiceBase/mutations";
import { toast } from "react-toastify";

const ServiceDeBases = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageFile, setImageFile] = useState(null); // Store the image file
  const [imageSrc, setImageSrc] = useState(defaultImage);

  const [addNewService, { isLoading, isError, isSuccess }] =
    useAddServiceBaseMutation();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "title") {
      setTitle(value);
    } else if (name === "description") {
      setDescription(value);
    }
  };

  useEffect(() => {
    if (isError) {
      console.error(
        "An error occurred while adding Service." + isError.message,
      );
    }
    if (isSuccess) {
      console.log("Service added successfully!");
    }
    if (isLoading) {
      console.log("Service loading!");
    }
  }, [isError, isSuccess, isLoading]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setImageFile(file); // Store the file for later use

      // Update the image preview
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);

    if (imageFile) {
      formData.append("image", imageFile); // Append the image file itself
    }

    try {
      const result = await addNewService(formData).unwrap();

      // Assuming the backend returns only the file name in 'image_url'
      if (result.status === "success") {
        console.log("Service added successfully with ID:", result.id);
        console.log("Image Filename:", result.image_url); // This should now be the filename
        toast.success(
          `Service added successfully! Image Filename: ${result.image_url}`,
        );
      } else {
        toast.error("Failed to add service, please try again.");
      }
    } catch (error) {
      toast.error("An error occurred while adding the service.");
      console.error("Error:", error);
    }
  };

  return (
    <div
      className="card"
      style={{ backgroundColor: "#F3F3F3", marginTop: "30px" }}
    >
      <div className="card-body">
        <FormContainer>
          <FormTitle>Services de Base</FormTitle>
          <div className="row">
            <div className="col-md-6">
              <FormGroup>
                <Label>Titre du service</Label>
                <Input
                  type="text"
                  name="title"
                  value={title}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup className="mt-3">
                <Label>Description du service</Label>
                <Input
                  type="text"
                  name="description"
                  value={description}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <CardBody>
                <div>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <Button
                    type="button"
                    className="btn"
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    Télécharger une image
                  </Button>
                </div>
                <div style={{ border: "1px solid dotted", marginTop: "20px" }}>
                  <img
                    src={imageSrc}
                    alt="Uploaded"
                    style={{ width: "100%" }}
                  />
                </div>
              </CardBody>
            </div>
            <ButtonRow>
              <button type="button" className="btn">
                Annuler
              </button>
              <button
                type="button"
                className="btn"
                onClick={handleSave}
                disabled={isLoading}
              >
                {isLoading ? "Chargement..." : "Sauvegarder"}
              </button>
            </ButtonRow>
          </div>
        </FormContainer>
      </div>
    </div>
  );
};

export default ServiceDeBases;
