import styled from "styled-components";
import BgGroup from "image/BgGroup.png";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(${BgGroup});
  background-size: cover;
  background-position: center;
`;
export const Title = styled.h3`
  color: #ffffff;
  font-size: 26px;
  font-family: roboto;
  text-decoration: underline;
  text-align: center;
  padding-top: 90px;
  margin-bottom: 50px;
`;
export const Cadre = styled.div`
  background-color: white;
  color: black;
  width: 90%;
  margin: auto;
  margin-bottom: 50px;
  border-radius: 14px;
  height: calc(73vh - 120px);
`;
export const CadreHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
  --bs-gutter-x: 0rem !important;
`;
export const Navig = styled.div`
  display: flex;
  gap: 10px;
`;
export const GrpBtn = styled.div`
  display: flex;
  gap: 10px;
`;
export const CadreHeader2 = styled.div`
  margin: 20px 0;
  --bs-gutter-x: 0rem !important;
`;
export const ContainSelectData = styled.div`
border-radius: 50px;
  border: 1px solid #ffc857;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`
export const InputSelectDate = styled.select`
  border: none;
  outline: none;
  padding: 4px;
   appearance: none; /* Pour enlever la flèche sur la plupart des navigateurs */
  -webkit-appearance: none; /* Pour enlever la flèche sur Safari */
  -moz-appearance: none; /* Pour enlever la flèche sur Firefox */
  background-color: transparent; /* Garder le fond transparent */
`;
export const SpanIcon = styled.span`
position: absolute;
left: 7.75em;
font-size: 16px;
`
export const InputSelect = styled.select`
  border-radius: 50px;
  border: 1px solid #ffc857;
  padding: 4px;
  outline: none;
`;
export const Input = styled.input`
  border-radius: 50px;
  border: 1px solid #ffc857;
  padding: 8px;
  outline: none;
`;
export const CadreTab = styled.div`
  // width: 95%;
  margin: auto;
  box-shadow: 1px 1px 3px gray;
  margin-top: 30px;
`;
export const Icone = styled.div`
  font-size: 14px;
  font-weight: bold;
`;
export const TextBut = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  // padding-top: 16px;
`;

export const Select = styled.select`
  height: 30px;
  outline: none;
  border-radius: 20px;
  border: 1px solid gray;
  font-size: 14px;
`;
export const BoutonFiltre = styled.button`
background: white !important;
border: none !important;
font-weight: 700;
`