import React from "react";
import { SearchInput } from "components/HeaderListe/Navbarcss";
import { useNavigate } from "react-router-dom";
import { TEXTESPREPARES_NEW } from "routes/navigation/navigationPaths";
import styled from "styled-components";
import COLORS from "utils/Variables/color";

export const Button = styled.button`
  border: 1px solid ${COLORS.primaryBorderColor};
  border-radius: 7px;
  font-family: Roboto !important;
  font-weight: 700;
  font-size: 16px !important;
  color: #000;
  text-decoration: none;
  transition: background-color 0.3s ease;
  &.active {
    background-color: ${COLORS.activeColor};
    color: "#fff";
  }
`;
function HeaderEmailTextPrep() {
  const navigate = useNavigate();
  const switchEmail = () => {
    navigate(TEXTESPREPARES_NEW);
  };
  return (
    <div className="d-flex p-0 py-3 align-items-center">
      <div className="col-md-6">
        <SearchInput placeholder="Rechercher par nom" />
      </div>
      <div className="col-md-6 d-flex justify-content-end align-items-center">
        <Button onClick={switchEmail}>Nouveau E-mail</Button>
      </div>
    </div>
  );
}

export default HeaderEmailTextPrep;
