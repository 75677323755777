import React from "react";
import {
  DASHBOARD_PATH,
  GESTION_HOTELIER_PATH,
  GESTION_ENVOIE_PATH,
  TEXTESPREPARES_ALL,
  TEXTESPREPARES_MESSAGES,
  TEXTESPREPARES_FORMULAIRE,
  TEXTESPREPARES_NEW,
  STATISTIQUE,
  PLUS,
  GESTION_PATH,
  CONFIGURATION_HOTEL_PATH,
  HOTEL,
  EMAIL_EXPEDITEURS,
  GESTION_NOTIFICATION,
  CONFIGURATION_AVANCEE,
} from "../navigationPaths";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import SplitShell from "layouts/AppShells/SplitShell";
import RelationClient from "pages/RelationClient/RelationClient";
import DashboardRelation from "pages/RelationClient/DashboardRelationClient/DashboardRelation";
import TextesPrepares from "pages/Admin/DashboardAdmin/TextesPrepares/TextesPrepares";
import GestionHotelier from "pages/Admin/DashboardAdmin/GestionVoyageur/GestionHotelier";
import NouvelleEmail from "../../../components/AdminTextPrepares/NouvelleEmail/NouvelleEmail";
import TableEmailTextPrepares from "../../../components/AdminTextPrepares/TextesPrepares/TableEmailTextPrepares";
import GestionClient from "pages/RelationClient/GEstionClient/GestionClientt";
import Statistique from "pages/RelationClient/Statististique/Statistique";
import Plus from "pages/RelationClient/Plus/Plus";
import HotelConfig from "pages/Admin/DashboardAdmin/HotelConfig/HotelConfig";
import Hotel from "pages/Admin/DashboardAdmin/HotelConfig/Hotel/Hotel";
import EmailExpediteurs from "pages/Admin/DashboardAdmin/HotelConfig/EmailExpediteurs/EmailExpediteurs";
import GestionNotifications from "pages/Admin/DashboardAdmin/HotelConfig/GestionNotifications/GestionNotifications";
import ConfigurationAvancee from "pages/Admin/DashboardAdmin/HotelConfig/ConfigrationAdvanced/ConfigurationAvancee";
import { Navigate, Outlet } from "react-router-dom";
import GestionConfigHotelLayout from "pages/Admin/DashboardAdmin/HotelConfig/Layouts/GestionConfigHotelLayout";
import ConfigHotelHeaderLayout from "pages/Admin/DashboardAdmin/HotelConfig/Layouts/ConfigHotelHeaderLayout";
import ConfigurationHotelLayout from "pages/Admin/DashboardAdmin/HotelConfig/Layouts/ConfigurationHotelLayout";
import ParametreEmailPage from "pages/Admin/DashboardAdmin/HotelConfig/Hotel/pages/ParametreEmailPage";
import HotelNotificationPage from "pages/Admin/DashboardAdmin/HotelConfig/Hotel/pages/HotelNotificationPage";
import ConfigurationavancePage from "pages/Admin/DashboardAdmin/HotelConfig/Hotel/pages/ConfigurationavancePage";
import CharteGraphiqueLayout from "pages/Admin/DashboardAdmin/HotelConfig/Layouts/CharteGraphiqueLayout";
import ParametreEmailLayout from "pages/Admin/DashboardAdmin/HotelConfig/Layouts/ParametreEmailLayout";
import FormulaireLayout from "pages/Admin/DashboardAdmin/HotelConfig/Layouts/FormulaireLayout";
import IdentiteVisuellePage from "pages/Admin/DashboardAdmin/HotelConfig/CharteGraphique/IdentiteVisuellePage";
import SocialMediaSettingsPage from "pages/Admin/DashboardAdmin/HotelConfig/CharteGraphique/SocialMediaSettingsPage";
import EmailActivationPage from "pages/Admin/DashboardAdmin/HotelConfig/Emails/EmailActivationPage";
import EmailListPage from "pages/Admin/DashboardAdmin/HotelConfig/Emails/EmailListpage";
import EmailSignaturePage from "pages/Admin/DashboardAdmin/HotelConfig/Emails/EmailSignaturePage";
import EmailAvantageDeCodeDeFidelitePage from "pages/Admin/DashboardAdmin/HotelConfig/Emails/EmailAvantageDeCodeDeFidelitePage";
import FormPreStayPage from "pages/Admin/DashboardAdmin/HotelConfig/Formulaires/FormPreStayPage";
import FormInStayPage from "pages/Admin/DashboardAdmin/HotelConfig/Formulaires/FormInStayPage";
import FormPostStay from "pages/Admin/DashboardAdmin/HotelConfig/Formulaires/FormPostStay";

/*
|---------------------------------------------------------------
| Les routes privates
|---------------------------------------------------------------
| Toute page qui necessite une authentification doit etre ici
|
*/

export const privateRoutes = [
  {
    path: "*",
    element: <Navigate to={DASHBOARD_PATH} />,
  },
  {
    path: "",
    errorElement: <ErrorBoundary />,
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <SplitShell />,
        children: [
          {
            path: "",
            element: <Navigate to={DASHBOARD_PATH} />,
          },
          {
            path: DASHBOARD_PATH,
            element: <RelationClient />,
            breadcrumb: "Relation Client",
            children: [
              {
                path: "",
                element: <DashboardRelation />,
              },
              {
                path: GESTION_PATH,
                element: <GestionClient />,
                breadcrumb: "Gestion Client",
              },
              {
                path: STATISTIQUE,
                element: <Statistique />,
              },
              {
                path: PLUS,
                element: <Plus />,
              },
            ],
          },
          {
            path: GESTION_HOTELIER_PATH,
            element: <GestionHotelier />,
          },
          {
            path: CONFIGURATION_HOTEL_PATH,
            element: <GestionConfigHotelLayout />,
            breadcrumb: "Configuration de l’hôtel",
            children: [
              {
                path: "",
                element: "Choisir un hotel",
                breadcrumb: "hôtel",
              },
              {
                path: ":hotelId",
                element: <ConfigHotelHeaderLayout />,
                breadcrumb: "hôtel-item",
                children: [
                  {
                    path: "",
                    element: <ConfigurationHotelLayout />,
                    breadcrumb: "config",
                    children: [
                      {
                        path: "",
                        element: <HotelConfig />,
                        breadcrumb: "hotel",
                      },
                      {
                        path: "parametre-emails",
                        element: <ParametreEmailPage />,
                        breadcrumb: "parametre-emails",
                      },
                      {
                        path: "notifications",
                        element: <HotelNotificationPage />,
                        breadcrumb: "notifications",
                      },
                      {
                        path: "configuration-avance",
                        element: <ConfigurationavancePage />,
                        breadcrumb: "configuration avancée",
                      },
                    ],
                  },
                  {
                    element: <CharteGraphiqueLayout />,
                    path: "charte-graphique",
                    breadcrumb: "charte graphique",
                    children: [
                      {
                        path: "",
                        element: <IdentiteVisuellePage />,
                        breadcrumb: "Identité visuelle",
                      },
                      {
                        path: "social-media-settings",
                        element: <SocialMediaSettingsPage />,
                        breadcrumb: "Paramètres des réseaux sociaux",
                      },
                    ],
                  },
                  {
                    element: <ParametreEmailLayout />,
                    path: "emails",
                    breadcrumb: "emails",
                    children: [
                      {
                        path: "",
                        element: <EmailActivationPage />,
                        breadcrumb: "Activation e-mails",
                      },
                      {
                        path: "list",
                        element: <EmailListPage />,
                        breadcrumb: "E-mails",
                      },
                      {
                        path: "Signatures",
                        element: <EmailSignaturePage />,
                        breadcrumb: "signatures",
                      },
                      {
                        path: "loyalty-benefits-codes",
                        element: <EmailAvantageDeCodeDeFidelitePage />,
                        breadcrumb: "Avantages et codes de fidélité",
                      },
                    ],
                  },
                  {
                    element: <FormulaireLayout />,
                    path: "formulaires",
                    breadcrumb: "formulaires",
                    children: [
                      {
                        path: "",
                        element: <FormPreStayPage />,
                        breadcrumb: "Pre-Stay",
                      },
                      {
                        path: "form-in-stay",
                        element: <FormInStayPage />,
                        breadcrumb: "In-Stay",
                      },
                      {
                        path: "form-post-stay",
                        element: <FormPostStay />,
                        breadcrumb: "Post-Stay",
                      },
                    ],
                  },
                  // ------------------------------
                  {
                    path: "config",
                    element: <HotelConfig />,
                    breadcrumb: "hôtel",
                  },
                  {
                    path: HOTEL,
                    element: <Hotel />,
                    breadcrumb: "hôtel",
                  },
                  {
                    path: EMAIL_EXPEDITEURS,
                    element: <EmailExpediteurs />,
                    breadcrumb: "Paramètres expéditeur e-mails",
                  },
                  {
                    path: GESTION_NOTIFICATION,
                    element: <GestionNotifications />,
                    breadcrumb: "Gestion des notifications",
                  },
                  {
                    path: CONFIGURATION_AVANCEE,
                    element: <ConfigurationAvancee />,
                    breadcrumb: "Configuration avancée",
                  },
                ],
              },
            ],
          },
          {
            path: GESTION_ENVOIE_PATH,
            element: <TextesPrepares />,
            breadcrumb: "Textes Préparés",
            children: [
              {
                path: TEXTESPREPARES_ALL,
                element: "ALL",
              },
              {
                path: TEXTESPREPARES_MESSAGES,
                element: "MESSAGES",
              },
              {
                path: TEXTESPREPARES_FORMULAIRE,
                element: "FORMULAIRE",
              },
              {
                path: TEXTESPREPARES_NEW,
                element: <NouvelleEmail />,
              },
              {
                path: "",
                element: <TableEmailTextPrepares />,
              },
            ],
          },
        ],
      },
    ],
  },
];
