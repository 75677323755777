import { NavLink } from "react-router-dom";
import styled from "styled-components";
import COLORS from "utils/Variables/color";

export const Container = styled.div`
    margin-top: 70px;
`;
export const ContainerConfig = styled.div``;

export const DropdownWrapper = styled.div`
    position: relative;
    width: 300px;
    margin-bottom: 20px;
    border-color: ${COLORS.activeColor};
`;

export const SelectedHotel = styled.div`
    padding: 10px;
    border: 1.3px solid;
    border-color: ${COLORS.activeColor};
    border-radius: 45px;
    cursor: pointer;
    font-style: italic;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;
    background-color: white;

    &:hover {
        border-color: ${COLORS.activeColor};
    }
`;

export const DropdownContainer = styled.div`
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    right: 0;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    background-color: white;
    z-index: 1;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 13px 8px;
`;

export const SearchInput = styled.input`
    padding: 10px;
    width: 100%;
    border: none;
    border: 1px solid #d9d9d9;
    outline: none;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 400;
    border-radius: 8px;
    color: black;
`;

export const HotelList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 300px;
    overflow-y: scroll;
`;
export const SelectDropdown = styled.select`
    padding: 10px;
    width: 100%;
    border: none;
    border: 1px solid #d9d9d9;
    outline: none;
    font-size: 16px;
    border-radius: 45px;
    font-weight: bold;
    font-family: Roboto;
`;

export const HotelItem = styled.li`
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 8px;
    margin: 5px 0px;

    &:hover {
        background-color: ${COLORS.primary};
        color: white;
    }
`;

export const CreateNewButton = styled.button`
    padding: 10px;
    width: 100%;
    border: none;
    border-radius: 45px;
    background-color: ${COLORS.activeColor};
    color: black;
    font-size: 16px;
    cursor: pointer;
    text-align: center;

    &:hover {
        background-color: ${COLORS.primary};
        color: white;
    }
`;

export const Header = styled.h2`
    font-size: 30px;
    font-weight: 300;
    font-family: Roboto;
    margin-bottom: 6px;
`;

export const SubHeader = styled.p`
    font-size: 14px;
    margin-bottom: 20px;
    font-family: Montserrat;
    font-weight: 400;
`;

export const HotelName = styled.p`
    font-size: 30px;
    font-weight: 300;
    font-family: Roboto;
    margin: 10px 0px;
`;

export const Dropdown = styled.select`
    width: 300px;
    margin-bottom: 20px;
    border-radius: 45px;
    border-color: ${COLORS.activeColor};
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    font-family: Roboto;

    &:focus,
    &:active {
        outline: none;
        box-shadow: none;
        border-color: ${COLORS.primary};
    }

    &:last-child {
        padding: 6px 15px;
        border-radius: 45px;
        background-color: ${COLORS.activeColor};
        color: black;
    }
`;

export const TabsContainer = styled.ul`
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #ccc;
    margin-bottom: 32px;
`;

export const Tab = styled(NavLink)`
    padding: 5px 10px;
    border: 1px solid #ccc;
    font-size: 16px;
    cursor: pointer;
    color: black;
    background-color: transparent;
    text-decoration: none; /* Pour enlever le soulignement par défaut des liens */
    transition:
        background-color 0.3s,
        color 0.3s; /* Pour des transitions douces */

    &.active {
        color: white;
        background-color: ${COLORS.primary};
    }

    &:hover {
        background-color: ${COLORS.primary};
        opacity: 0.9;
        color: white;
    }
`;

export const ButtonRow = styled.div`
    display: flex;
    // margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;

    & > hover {
        background-color: ${COLORS.activeColor};
        opacity: 0.9;
    }

    & > button {
        margin-right: 10px;
        padding: 6px 15px;
        border-radius: 45px;
        color: black;
        border: 1px solid ${COLORS.activeColor};
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: between;
        gap: 18px;
    }

    & > .active {
        background-color: ${COLORS.activeColor};
        color: black;
        border-color: ${COLORS.activeColor};
    }
`;

export const SubNavLink = styled(NavLink)`
    display: flex;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;

    &:hover {
        background-color: ${COLORS.activeColor};
        opacity: 0.9;
    }

    margin-right: 10px;
    padding: 6px 15px;
    border-radius: 45px;
    color: black;
    border: 1px solid ${COLORS.activeColor};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 18px;

    &.active {
        background-color: ${COLORS.activeColor};
        color: black;
        border-color: ${COLORS.activeColor};
    }
`;

export const ButtonPill = styled.button`
    padding: 6px 15px;
    border-radius: 45px;
    background-color: ${COLORS.activeColor};
    color: black;
`;
