import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import VerticalBorder from "image/VerticalBorder.png";
// import { stateLink } from "./data"

const Container = styled.div`
  display: flex;
  gap: 25px;
  @media (max-width: 768px) {
    gap: 12px;
  }
`;
const ContainLink = styled(Link)`
  color: ${(props) => props.color || "#4A4A4A"};
  text-decoration: none;
  font-family: "Roboto";
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Number = styled.p`
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 0;
`;
const Texte = styled.p`
  margin-top: 0;
  font-size: 10px;
  margin-bottom: 0;
`;
const Img = styled.img`
  width: 1%;
`;

function LinkState({ color, number, texte, Link }) {
  return (
    <Container>
      <ContainLink to={Link} color={color}>
        <Number>{number}</Number>
        <Texte>{texte}</Texte>
      </ContainLink>
      <Img src={VerticalBorder} />
    </Container>
  );
}

export default LinkState;
