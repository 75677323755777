import React, { useState } from "react";
import { MdLanguage } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import styled from "styled-components";
import DrapeauAllemagne from "image/DrapeauAllemagne.jpg";
import DrapeauAngleterre from "image/DrapeauAngleterre.jpg";
import DrapeauChine from "image/DrapeauChine.jpg";
import DrapeauEspagne from "image/DrapeauEspagne.png";
import DrapeauFrance from "image/DrapeauFrance.png";
import DrapeauPortugal from "image/DrapeauPortugal.png";
import { Img } from "components/RelationClientDetail/InfoClient/InfoClientCSS";

const DropdownContainer = styled.div`
  position: relative;
`;

const SmallDropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
  border-radius: 6px;
  margin-top: 12px;
  width: 150px;
`;

const MenuItem = styled.p`
  margin: 0;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #d3eafd;
  }
`;

const LanguageDropdown = ({ lang, setLang }) => {
  const [isLangDropdownVisible, setIsLangDropdownVisible] = useState(false);

  const toggleLangDropdown = () => {
    setIsLangDropdownVisible(!isLangDropdownVisible);
  };

  return (
    <DropdownContainer>
      <div
        onClick={toggleLangDropdown}
        style={{
          marginLeft: 10,
          marginRight: 15,
          color: "#000000",
          fontFamily: "Roboto",
          fontWeight: "400",
          cursor: "pointer",
          transition: "color 0.3s",
          position: "relative",
        }}
        aria-haspopup="true"
        aria-expanded={isLangDropdownVisible}
        role="button"
      >
        <MdLanguage /> {lang}{" "}
        <IoMdArrowDropdown style={{ marginLeft: "5px" }} />
      </div>
      {isLangDropdownVisible && (
        <SmallDropdownMenu>
          <MenuItem onClick={() => setLang("DE")}>
            <Img src={DrapeauAllemagne} alt="German" /> Allemand
          </MenuItem>
          <MenuItem onClick={() => setLang("ANG")}>
            <Img src={DrapeauAngleterre} alt="English" /> Anglais
          </MenuItem>
          <MenuItem onClick={() => setLang("CN")}>
            <Img src={DrapeauChine} alt="Chinese" /> Chinois
          </MenuItem>
          <MenuItem onClick={() => setLang("ES")}>
            <Img src={DrapeauEspagne} alt="Spanish" /> Espagnol
          </MenuItem>
          <MenuItem onClick={() => setLang("FR")}>
            <Img src={DrapeauFrance} alt="French" /> Français
          </MenuItem>
          <MenuItem onClick={() => setLang("PT")}>
            <Img src={DrapeauPortugal} alt="Portuguese" /> Portugais
          </MenuItem>
        </SmallDropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default LanguageDropdown;
