import React from "react";
import { Outlet } from "react-router-dom";
import SpliTemplateScreen from "layouts";
import NavbarComponent from "layouts/navbar/NavbarComponent";
import SidebarComponent from "layouts/sidebar/SidebarComponent";

const SplitShell = () => {
  return (
    <SpliTemplateScreen>
      <SidebarComponent
        activeLinkBgColor="#FFF"
        activeLinkColor="black"
        linkColor="#FFF"
      />
      <NavbarComponent />
      <Outlet />
    </SpliTemplateScreen>
  );
};

export default SplitShell;
