import React, { useRef } from "react";
import styled from "styled-components";
import { FaChevronLeft } from "react-icons/fa";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import { useAddNewVoyageurMutation } from "actions/Voyageurservices";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
// import csv from 'csv-parser';
// import fs from 'fs';

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
`;
const Entete = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
`;
const Icone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid gray;
  font-size: 15px;
  padding: 10px;
  cursor: pointer;
`;
const Title = styled.h2`
  display: flex;
  gap: 15px;
`;
const Bottom = styled.div`
  border: 2px dashed gray;
  padding: 20px;
  border-radius: 7px;
`;
const BottomText = styled.div`
  font-size: 25px;
  text-align: center;
  padding: 0;
`;
const BottomIcone = styled.div`
  font-size: 40px;
  text-align: center;
  font-weight: bold;
`;
const BottomPlus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  margin: auto;
  padding: 17px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-color: #ffc857;
  cursor: pointer;
`;

const ImportModal = ({ isOpen, onRequestClose }) => {
  if (!isOpen) return null;
  const fileInputRef = useRef(null);
  const handleImportButtonClick = () => {
    fileInputRef.current.click();
  };

  // Id hotelier connecté
  const userId = useSelector((state) => state?.persist?.auth.user_id);
  const parseExcelOrCSV = (fileData) => {
    const parsedData = [];
    const workbook = XLSX.read(fileData, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    console.log({ data });
    data.forEach((row) => {
      const [prenom, nom, adresse, email, telephone, numero_passport] = row;
      parsedData.push({
        nom,
        prenom,
        email,
        numero_passport,
        adresse,
        telephone,
        user: userId,
      });
    });

    return parsedData;
  };
  const [addNewVoyageurMutation] = useAddNewVoyageurMutation();
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async (event) => {
      const fileData = event.target.result;
      const parsedData = parseExcelOrCSV(fileData);
      parsedData.forEach(async (voyageurData) => {
        try {
          const data = await addNewVoyageurMutation(voyageurData);
          alert("Voyageur ajouté avec succès!");
          console.log("Voyageur ajouté avec succès!", data);
          onRequestClose();
        } catch (error) {
          console.error("Erreur lors de l'ajout du voyageur :", error);
        }
      });
    };
    // Vérifiez le type de fichier et choisissez la méthode de lecture appropriée
    if (file.type === "csv" || file.name.endsWith(".csv")) {
      reader.readAsText(file);
    } else if (
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel" ||
      file.name.endsWith(".xlsx")
    ) {
      reader.readAsBinaryString(file);
    } else {
      console.error("Type de fichier non pris en charge.");
    }
  };

  return (
    <ModalBackdrop onClick={onRequestClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <Entete>
          <Icone onClick={onRequestClose}>
            {" "}
            <FaChevronLeft />{" "}
          </Icone>
          <Title>Importer Excel / CSV</Title>
        </Entete>
        <Bottom>
          <BottomIcone>
            {" "}
            <IoCloudUploadOutline />{" "}
          </BottomIcone>
          <BottomText>
            <p>Parcourez et choisissez les fichiers que vous voulez</p>
            <p>importer depuis votre ordinateur</p>
          </BottomText>
          <BottomPlus onClick={handleImportButtonClick}>
            {" "}
            <FaPlus />{" "}
          </BottomPlus>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileUpload}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </Bottom>
      </ModalContent>
    </ModalBackdrop>
  );
};

export default ImportModal;
