import React from "react";
import SidebarItem from "./SidebarItem";
import { affichAll, messages } from "../UtilsSidebar";
import { TEXTESPREPARES_NEW } from "routes/navigation/navigationPaths";
import { useLocation } from "react-router-dom";
// import SIZE from "utils/Variables/size";

function SidebarTextPrepares() {
  const PATH_EMAIL = `/${TEXTESPREPARES_NEW}`;
  const location = useLocation();
  return (
    <div className="px-3 pt-2">
      <div className="row px-2">
        <p style={{ fontSize: "17px", fontWeight: "400" }}>
          Admin / E-mail pendant le séjour
        </p>
      </div>
      <div className="row gap-1 px-2 pt-1">
        <div className="">
          {affichAll.map((item, index) => (
            <SidebarItem {...item} key={index} />
          ))}
        </div>
        <div className="mb-2">
          <span style={{ fontSize: "18px", fontWeight: "500" }}>Messages</span>
          {location.pathname === `${PATH_EMAIL}`
            ? messages
                .filter((items) => items.id === "email")
                .map((item, index) => <SidebarItem {...item} key={index} />)
            : messages.map((item, index) => (
                <SidebarItem {...item} key={index} />
              ))}
        </div>
      </div>
    </div>
  );
}

export default SidebarTextPrepares;
