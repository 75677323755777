import React from "react";
import styled from "styled-components";
import Logo from "components/Navbar/Logo";
import Language from "components/Navbar/Language";

const NavbarContainer = styled.div`
  width: 100%;
  background: #d26129;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 9%;
  position: fixed;
  z-index: 1;

  @media (max-width: 768px) {
    padding: 0px 2%;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Navbar = ({ logoSrc, lang, setLang, link }) => {
  return (
    <>
      <NavbarContainer>
        <LogoWrapper>
          <Logo src={logoSrc} link={link} />
        </LogoWrapper>
        <Language lang={lang} setLang={setLang} />
      </NavbarContainer>
    </>
  );
};

export default Navbar;
