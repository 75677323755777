import React from "react";
import {
  ButtonRow,
  FormContainer,
  FormGroup,
  FormRow,
  FormTitle,
  Input,
  Label,
} from "../Hotel/StyledHotel";
import Card from "components/ConfigHotel/Card";

export default function EmailExpediteurs() {
  return (
    <FormContainer>
      <FormTitle>Paramètres expéditeur e-mails</FormTitle>
      <Card
        title="Ci dessous, info pour le midstay uniquement"
        description="Laissez ces champs vides si vous voulez utiliser le nom et l'email par défaut. Cette fonctionnalité est désactivée car le client en testeur avec des mails et sms parés."
        notice="Les forwards sont automatiquement crées et mise a jour sur information.com lors de l’enregistrement."
        notice2="Les forwards sont sélectionnés dans le premier champs non vide dans cet ordre"
        listItem="Email midday e-mail"
        listItem2="Default e-mail"
        noticeColor="#e7f3ff"
      >
        <FormRow>
          <FormGroup>
            <Label>Email espace (midstay)</Label>
            <Input type="text" defaultValue="test@azalaihote.com" />
          </FormGroup>
          <FormGroup>
            <Label>Nom espace (midstay)</Label>
            <Input type="text" defaultValue="Midstay Default" />
          </FormGroup>
        </FormRow>
        <ButtonRow>
          <button className="btn">Sauvegarder</button>
        </ButtonRow>
      </Card>

      <Card
        title="E-mails - Suite"
        description="Texte dans les entêtes d'emails pour les changeurs de langues"
      >
        <FormRow>
          <FormGroup>
            <Label>Français</Label>
            <Input type="text" defaultValue="Fr" />
            <ButtonRow>
              <button className="btn">Sauvegarder</button>
            </ButtonRow>
          </FormGroup>
          <FormGroup>
            <Label>Anglais</Label>
            <Input type="text" defaultValue="En" />
            <ButtonRow>
              <button className="btn">Sauvegarder</button>
            </ButtonRow>
          </FormGroup>
        </FormRow>
      </Card>

      <Card
        title="SMS"
        notice="Le SMS n'en peut contenir que des lettres sans accents, et aucun espace (11 caractères max)."
        notice2="Le nom d'hôtel ne doit pas contenir de ^ ni doit être en version courte car il sera inclus dans le corps du texte."
        noticeColor="#ffe6e6"
        textColor="#FF1C0E"
      >
        <FormRow>
          <FormGroup>
            <Label>SMS From</Label>
            <Input type="text" defaultValue="Azalai Hôtel Dakar" />
          </FormGroup>
          <FormGroup>
            <Label>Nom Hôtel SMS</Label>
            <Input type="text" defaultValue="Midstay Azalaï Dakar" />
          </FormGroup>
        </FormRow>
        <ButtonRow>
          <button className="btn">Sauvegarder</button>
        </ButtonRow>
      </Card>

      <Card
        title="E-mails"
        description="Les forwards sont automatiquement créés et mise à jour sur priyon Progress.com lors de l'enregistrement"
        notice="Les forwards sont automatiquement crées et mise a jour sur pylon Progress-.com lors de l’enregistrement."
        notice2="Les forwards sont sélectionnés dans le premier champs non vide dans cet ordre"
        noticeColor="#e7f3ff"
        listItem="azalai e-mail forward"
        listItem2="Debrief e-mail"
      >
        <FormRow>
          <FormGroup>
            <Label>Email espace (midstay)</Label>
            <Input type="text" defaultValue="test@azalaihote.com" />
          </FormGroup>
          <FormGroup>
            <Label>Nom espace (midstay)</Label>
            <Input type="text" defaultValue="Midstay Default" />
          </FormGroup>
        </FormRow>
        <ButtonRow>
          <button className="btn">Sauvegarder</button>
        </ButtonRow>
      </Card>
    </FormContainer>
  );
}
