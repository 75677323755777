import { ApiMananger } from "actions/rtkquery";

export const hotelierQueryApi = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    getHoteliers: builder.query({
      query: () => "hoteliers_by_admin/",
      providesTags: ["HOTELIERS"],
    }),
    getHotelById: builder.query({
      query: (id) => `hoteliers/${id}`,
      providesTags: ["HOTELIERS"],
    }),
    providesTags: ["hotel"],
  }),
});

export const { useGetHoteliersQuery, useGetHotelByIdQuery } = hotelierQueryApi;
