import React from "react";
import { AdminTableEmail, AdminTableEmailTh } from "pages/Admin/StyledAdmin";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEye } from "react-icons/fa";
import { TableEmailContent } from "../UtilsSidebar";
import HeaderEmailTextPrep from "./HeaderEmailTextPrep";

function TableEmailTextPrepares({ switchedToEmail }) {
  return (
    <div className="container">
      <div className="row pb-2">
        <HeaderEmailTextPrep switchedButton={switchedToEmail} />
        <AdminTableEmail className="card p-0 shadow">
          <div className="table-responsive">
            <table className="table table-borderless pb-0 m-0">
              <thead>
                <tr>
                  <AdminTableEmailTh
                    scope="col"
                    className="rounded-start py-3 ps-4"
                  >
                    Nom
                  </AdminTableEmailTh>
                  <AdminTableEmailTh scope="col" className="py-3">
                    sujet
                  </AdminTableEmailTh>
                  <AdminTableEmailTh scope="col" className="rounded-end py-3">
                    Actions
                  </AdminTableEmailTh>
                </tr>
              </thead>
              <tbody>
                {TableEmailContent.map((item, index) => (
                  <tr key={index} className="border-bottom">
                    <td className="py-4 ps-4">{item.nom}</td>
                    <td className="py-4">{item.sujet}</td>
                    <td className="d-flex gap-3 py-4">
                      <FaEye
                        className="fs-4"
                        style={{ color: "#667085", cursor: "pointer" }}
                      />
                      <BiEditAlt
                        className="fs-4"
                        style={{ color: "blue", cursor: "pointer" }}
                      />
                      <RiDeleteBin6Line
                        className="fs-4"
                        style={{ color: "red", cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </AdminTableEmail>
      </div>
    </div>
  );
}

export default TableEmailTextPrepares;
