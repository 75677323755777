import React, { useState } from "react";
// import { MdLanguage } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import styled from "styled-components";
import DrapeauAngleterre from "image/DrapeauAngleterre.jpg";
import DrapeauFrance from "image/DrapeauFrance.png";
import { Img } from "components/RelationClientDetail/InfoClient/InfoClientCSS";

const DropdownContainer = styled.div`
  position: relative;
`;

const SmallDropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: -15%;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
  border-radius: 6px;
  margin-top: 12px;
  width: 150px;

  @media (max-width: 768px) {
    width: 100px;
  }
`;

const MenuItem = styled.p`
  margin: 0;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &:hover {
    background-color: #d3eafd;
  }
  @media (max-width: 768px) {
    padding: 0px;
  }
`;
const NomLangue = styled.span`
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Language = ({ lang, setLang }) => {
  const [isLangDropdownVisible, setIsLangDropdownVisible] = useState(false);
  const currentFlag = lang === "Français" ? DrapeauFrance : DrapeauAngleterre;
  const toggleLangDropdown = () => {
    setIsLangDropdownVisible(!isLangDropdownVisible);
  };

  return (
    <DropdownContainer>
      <div
        onClick={toggleLangDropdown}
        style={{
          color: "white",
          fontFamily: "Roboto",
          fontWeight: "400",
          cursor: "pointer",
          transition: "color 0.3s",
          position: "relative",
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
        }}
        aria-haspopup="true"
        aria-expanded={isLangDropdownVisible}
        role="button"
      >
        <Img src={currentFlag} alt="Français" />
        <NomLangue>{lang}</NomLangue>
        <IoMdArrowDropdown style={{ marginLeft: "5px" }} />
      </div>
      {isLangDropdownVisible && (
        <SmallDropdownMenu>
          <MenuItem onClick={() => setLang("Anglais")}>
            <Img src={DrapeauAngleterre} alt="Anglais" /> <span>Anglais</span>
          </MenuItem>
          <MenuItem onClick={() => setLang("Français")}>
            <Img src={DrapeauFrance} alt="Français" /> <span>Français</span>
          </MenuItem>
        </SmallDropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default Language;
