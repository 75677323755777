import React from "react";
import styled from "styled-components";

const Title = styled.h1`
    font-family: Roboto;
    font-size: 32.28px;
    font-weight: 600;
    line-height: 38.74px;
    letter-spacing: -0.9684px;

    @media (max-width: 768px) {
        font-size: 24px;
    }
    }
`;

const Titre = ({ text, style }) => {
  return <Title style={style}>{text}</Title>;
};

export default Titre;
