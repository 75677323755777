import { useGetConfigEmailQuery } from "actions/configEmail/queries";
import { useCreateEmailMutation } from "actions/Email/mutations";
import { useGetEmailsQuery } from "actions/Email/queries"; import { DivSignePlus } from "components/RelationClientDetail/InfoClient/InfoClientCSS";
import {
  ButtonRow,
  DropdownInput,
  FormContainer,
  FormGroupFull,
  FormRow,
  FormTitle,
  Input,
  Label,
  LangueFormGroupFull,
  ObjectFormGroupFull,
} from "pages/Admin/DashboardAdmin/HotelConfig/Hotel/StyledHotel";
import { Editor } from "primereact/editor";
import React, { useEffect, useState } from "react";
import {
  HiArrowNarrowLeft,
  HiArrowNarrowRight,
  HiOutlineDotsVertical,
} from "react-icons/hi";
import { toast } from "react-toastify";

export default function Email({ selectedHotel }) {
  const renderHeader = () => {
    return (
      <div className="ql-toolbar ql-snow d-flex flex-wrap">
        <span className="ql-formats">
          <button className="ql-bold" aria-label="Bold"></button>
          <button className="ql-italic" aria-label="Italic"></button>
          <button className="ql-underline" aria-label="Underline"></button>
          <select className="ql-color" aria-label="Text Color">
            <option value="red"></option>
            <option value="green"></option>
            <option value="blue"></option>
            <option style={{ border: "1px solid #ccc" }} value="white"></option>
            <option value="orange"></option>
            <option value="purple"></option>
            <option value="black"></option>
          </select>
          <select className="ql-background" aria-label="Background Color">
            <option value="red"></option>
            <option value="green"></option>
            <option value="blue"></option>
            <option value="orange"></option>
            <option style={{ border: "1px solid #ccc" }} value="white"></option>
            <option value="purple"></option>
            <option value="black"></option>
          </select>
        </span>
        <span className="ql-formats">
          <button
            className="ql-align"
            value=""
            aria-label="Align Left"
          ></button>
          <button
            className="ql-align"
            value="center"
            aria-label="Align Center"
          ></button>
          <button
            className="ql-list"
            value="ordered"
            aria-label="Ordered List"
          ></button>
          <button
            className="ql-list"
            value="bullet"
            aria-label="Unordered List"
          ></button>
        </span>
        <span className="ql-formats">
          <button className="ql-link" aria-label="Insert Link"></button>
          <button className="ql-image" aria-label="Insert Image"></button>
        </span>
        <div className="d-flex align-items-center gap-3">
          <div className="d-flex align-items-center gap-2">
            <DivSignePlus className="mb-1">+</DivSignePlus>
            <Label style={{ color: "#FFC857" }}>Ajouter une balise</Label>
          </div>
          <div className="d-flex align-items-center gap-2">
            <DivSignePlus className="mb-1">+</DivSignePlus>
            <Label style={{ color: "#FFC857" }}>Ajouter des buttons</Label>
          </div>
        </div>
        <div className="d-flex align-items-center gap-2 ms-auto">
          <HiArrowNarrowLeft />
          <HiArrowNarrowRight />
          <HiOutlineDotsVertical />
        </div>
      </div>
    );
  };

  const header = renderHeader();
  const [emailConfig, setEmailConfig] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null); // To store selected email ID
  const [emailData, setEmailData] = useState({
    title: "Sans un titre",
    object: "Sans un titre",
    langue: "fr",
    contenu: "",
    signature: "",
  });

  const { data: listConfig } = useGetConfigEmailQuery(selectedHotel);
  const { data: emails } = useGetEmailsQuery();
  const [createEmail, { isLoading }] = useCreateEmailMutation();

  const emailTypes = [
    "Pre-stay",
    "In-Stay",
    "Post-Stay",
    "Process Pre-checkin",
    "Activités",
    "Thank you Stay",
    "Thank you Stay 2",
    "Ask Review",
    "Bring Back",
    "Birthday",
    "Confirmation de réservation",
    "Confirmation d’annulation",
    "Confirmation de mise à jour",
  ];

  useEffect(() => {
    if (listConfig) {
      setEmailConfig(listConfig);
    }
  }, [listConfig]);

  const findConfigByType = (type) => {
    const typeMapping = {
      "Pre-stay": "pre_stay",
      "In-Stay": "in_stay",
      "Post-Stay": "post_stay",
      "Process Pre-checkin": "process_pre-checking",
      "Activités": "activity",
      "Thank you Stay": "thank_you_stay",
      "Thank you Stay 2": "thank_you_stay2",
      "Ask Review": "ask_review",
      "Bring Back": "bring_back",
      "Birthday": "birthday",
      "Confirmation de réservation": "confirmation_reservation",
      "Confirmation d’annulation": "confirmation_annulation",
      "Confirmation de mise à jour": "confirmation_update",
    };

    return emailConfig.find(
      (config) => config?.form_type === typeMapping[type]
    );
  };

  const availableEmailTypes = emailTypes.filter((type) =>
    findConfigByType(type)
  );

  // When a type is selected, fetch the existing email data
  const handleEmailTypeChange = (event) => {
    const selectedId = event.target.value;
    setSelectedEmail(selectedId);

    // Find the selected email's data from the emails array
    const selectedEmailData = emails.find((email) => email.model_email === parseInt(selectedId));

    if (selectedEmailData) {
      // If the email exists, update the form with the data
      setEmailData({
        title: selectedEmailData?.title || "Sans un titre",
        object: selectedEmailData?.object || "Sans un titre",
        langue: selectedEmailData?.langue || "fr",
        contenu: selectedEmailData?.contenu || "",
        signature: selectedEmailData?.signature || "",
      });
    } else {
      // Reset if no data found for the selected email
      setEmailData({
        title: "Sans un titre",
        object: "Sans un titre",
        langue: "french",
        contenu: "",
        signature: "",
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const emailPayload = {
        title: emailData.title,
        object: emailData.object,
        langue: emailData.langue,
        contenu: emailData.contenu,
        signature: emailData.title,
        model_email: selectedEmail ? parseInt(selectedEmail) : null, // Send selected email ID
      };

      // Call the mutation to create the email
      await createEmail(emailPayload);
      toast.success("Email créé avec succès !");

      // Optionally reset the form
      setEmailData({
        title: "Sans un titre",
        object: "Sans un titre",
        langue: "french",
        contenu: "",
        signature: "",
      });
    } catch (error) {
      toast.error("Échec de la création de l'e-mail.");
      console.error("Failed to create email:", error);
    }
  };

  return (
    <FormContainer className="gap-3">
      <FormRow>
        <FormTitle>E-mails</FormTitle>
        <ButtonRow>
          <button
            type="button"
            className="btn"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Chargement..." : "Sauvegarder"}
          </button>
        </ButtonRow>
      </FormRow>
      <FormRow className="gap-3 mb-3">
        <FormGroupFull>
          <Label>Titre de l'e-mail</Label>
          <Input
            type="text"
            value={emailData.title}
            onChange={(e) => setEmailData({ ...emailData, title: e.target.value })}
          />

        </FormGroupFull>
      </FormRow>
      <FormRow className="gap-3 mb-3">
        <ObjectFormGroupFull>
          <Label>Objet</Label>
          <Input
            type="text"
            value={emailData.object}
            onChange={(e) => setEmailData({ ...emailData, object: e.target.value })}
          />
        </ObjectFormGroupFull>
        <LangueFormGroupFull>
          <Label>Langue</Label>
          <DropdownInput
            value={emailData.langue}
            onChange={(e) => setEmailData({ ...emailData, langue: e.target.value })}
          >
            <option value="french">Français</option>
            <option value="english">Anglais</option>
          </DropdownInput>
        </LangueFormGroupFull>
      </FormRow>
      <FormRow className="gap-3 mb-3">
        <FormGroupFull>
          <Label>Sélectionner un Modèle de Mail</Label>
          <DropdownInput onChange={handleEmailTypeChange}>
            <option value="">Select</option>
            {availableEmailTypes.map((type, index) => {
              const config = findConfigByType(type);
              return <option key={index} value={config?.id}>{type}</option>;
            })}
          </DropdownInput>
        </FormGroupFull>
      </FormRow>
      <FormRow className="gap-3 mb-3">
        <FormGroupFull>
          <Label>Contenu de l'e-mail</Label>
          <Editor
            value={emailData.contenu}
            onTextChange={(e) => setEmailData({ ...emailData, contenu: e.htmlValue })}
            headerTemplate={header}
            style={{ height: "150px" }}
          />
        </FormGroupFull>
      </FormRow>
    </FormContainer>
  );
}
