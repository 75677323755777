import React from "react";
import { FormContainer, FormTitle } from "../Hotel/StyledHotel";
// import Welcom from "./components/Welcom";
// import PreCheckin from "./components/PreCheckin";
import PreStay from "./components/PreStay";
import InStay from "./components/InStay";
import PostStay from "./components/PostStay";
// import AskReview from "./components/AskReview";
// import ThankYouStay from "./components/ThankYouStay";
// import BirthDay from "./components/Birthday";
// import Alertes from "./components/Alertes";
// import Devise from "./components/Devise";

export default function ConfigurationAvancee() {
  return (
    <FormContainer>
      <FormTitle>Configuration avancée</FormTitle>

      {/*  */}
      {/* <Welcom /> */}

      {/*  */}
      {/* <PreCheckin /> */}

      {/*  */}
      <PreStay />

      {/*  */}
      <InStay />

      {/*  */}
      <PostStay />

      {/*  */}
      {/* <AskReview /> */}

      {/*  */}
      {/* <ThankYouStay /> */}

      {/*  */}
      {/* <BirthDay /> */}

      {/*  */}
      {/* <Alertes /> */}

      {/*  */}
      {/* <Devise /> */}

      {/*  */}
      {/* <ButtonRow>
        <button className="btn">Annuler</button>
        <button className="btn">Sauvegarder</button>
      </ButtonRow> */}
    </FormContainer>
  );
}
