import React from "react";
import { VscLayersDot } from "react-icons/vsc";
import { BsChatRightText } from "react-icons/bs";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { MdOutlineEventNote } from "react-icons/md";
import { RxDragHandleDots2 } from "react-icons/rx";
import { BiArrowFromRight, BiArrowToRight } from "react-icons/bi";
import { BiArrowFromLeft } from "react-icons/bi";
import { MdContactPhone } from "react-icons/md";
import COLORS from "utils/Variables/color";

export const messages = [
  {
    icon: <BsChatRightText className="fs-5" />,
    title: "Message",
    positionIcon: (
      <RxDragHandleDots2
        className="fs-4"
        style={{ color: `${COLORS.primaryBorderColor}`, cursor: "pointer" }}
      />
    ),
    path: "messages",
    id: "message",
  },
  {
    icon: <HiOutlineEnvelope className="fs-4" />,
    title: "E-mail",
    positionIcon: (
      <RxDragHandleDots2
        className="fs-4"
        style={{ color: `${COLORS.primaryBorderColor}`, cursor: "pointer" }}
      />
    ),
    path: "",
    id: "email",
  },
  {
    icon: <MdOutlineEventNote className="fs-4" />,
    title: "Formulaire",
    positionIcon: (
      <RxDragHandleDots2
        className="fs-4"
        style={{ color: `${COLORS.primaryBorderColor}`, cursor: "pointer" }}
      />
    ),
    path: "formulaire",
    id: "formulaire",
  },
];

export const affichAll = [
  {
    icon: <VscLayersDot className="fs-4" />,
    title: "Afficher tout",
    positionIcon: (
      <RxDragHandleDots2
        className="fs-4"
        style={{ color: `${COLORS.primaryBorderColor}`, cursor: "pointer" }}
      />
    ),
    path: "all",
  },
];

export const enquete = [
  {
    icon: <BiArrowFromRight className="fs-4" />,
    title: "Avant le séjour",
    positionIcon: (
      <RxDragHandleDots2
        className="fs-4"
        style={{ color: `${COLORS.primaryBorderColor}`, cursor: "pointer" }}
      />
    ),
    path: "before-sejour",
  },
  {
    icon: <MdContactPhone className="fs-4" />,
    title: "Conciergerie",
    positionIcon: (
      <RxDragHandleDots2
        className="fs-4"
        style={{ color: `${COLORS.primaryBorderColor}`, cursor: "pointer" }}
      />
    ),
    path: "conciergerie",
  },
  {
    icon: <BiArrowToRight className="fs-4" />,
    title: "Pendant le séjour",
    positionIcon: (
      <RxDragHandleDots2
        className="fs-4"
        style={{ color: `${COLORS.primaryBorderColor}`, cursor: "pointer" }}
      />
    ),
    path: "sejour",
  },
  {
    icon: <BiArrowFromLeft className="fs-4" />,
    title: "Après le séjour",
    positionIcon: (
      <RxDragHandleDots2
        className="fs-4"
        style={{ color: `${COLORS.primaryBorderColor}`, cursor: "pointer" }}
      />
    ),
    path: "after-sejour",
  },
];

export const TableEmailContent = [
  {
    nom: "(EN) Ask a review",
    sujet: "Thank you would you give us your review",
  },
  {
    nom: "(FR) Demandez un avis",
    sujet: "Merci pourriez-vous nous donner votre avis ?",
  },
  {
    nom: "(EN) E-mail de bienvenue d'un voyageur",
    sujet: "Bienvenue chez Azalai Hôtel...",
  },
  {
    nom: "(EN) E-mail de bienvenue d’un voyageur",
    sujet: "Welcome to Azalai Hotel...",
  },
  {
    nom: "(FR) E-mail - chambre d’un voyageur",
    sujet: "Comment trouvez vous la chambre?",
  },
  {
    nom: "(EN) E-mail sur la chambre d’un voyageur",
    sujet: "How do you find the room?",
  },
  {
    nom: "(ES) E-mail sur la chambre d’un voyageur",
    sujet: "Bienvenidos al Hotel Azalai...",
  },
  {
    nom: "(AR) E-mail de bienvenue d’un voyageur",
    sujet: "مرحبا بكم في فندق أزالي...",
  },
];
