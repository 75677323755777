import { ApiMananger } from "actions/rtkquery";
import { configEmailEndpoint } from "routes/api/endpoints";

export const configEmailApi = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    updateConfigEmail: builder.mutation({
      query: ({ hotelId, configData }) => ({
        url: configEmailEndpoint.updateConfigEmail(hotelId),
        method: "PUT",
        body: configData,
      }),
    }),
    updateConfigEmailById: builder.mutation({
      query: ({ configId, configData }) => ({
        url: configEmailEndpoint.updateConfigEmailbyId(configId),
        method: "PUT",
        body: configData,
      }),
    }),
  }),
});

export const {
  useUpdateConfigEmailMutation,
  useUpdateConfigEmailByIdMutation,
} = configEmailApi;
