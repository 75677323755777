import React from "react";
import {
  ButtonRow,
  FormContainer,
  FormRow,
  FormTitle,
  Label,
} from "../Hotel/StyledHotel";
import { FormGroup, TextArea } from "./StyledGestionNotification";

export default function GestionNotifications() {
  return (
    <FormContainer>
      <FormTitle>Gestion des notifications</FormTitle>
      <FormRow className="gap-3">
        <FormGroup>
          <Label>E-mail de débriefing</Label>
          <TextArea
            defaultValue={
              "information@gmail.com\ncontact@gmail.com\ninformation265@gmail.com\ninfo125@gmail.com\ninformation3659@gmail.com"
            }
          />
          <Label>
            Mails auquel les debriefs sont envoyés, si vide prend l'adresse mail
            information@gmail.com
          </Label>
        </FormGroup>
        <FormGroup>
          <Label>E-mail Prestay</Label>
          <TextArea defaultValue="" />
          <Label>
            Mails auquel les debriefs Prestay sont envoyés, si vide prend
            l’adresse mail information@gmail.com
          </Label>
        </FormGroup>
      </FormRow>
      <FormRow className="mt-5 gap-3">
        <FormGroup>
          <Label>E-mail Instay</Label>
          <TextArea defaultValue="" />
          <Label>
            Mails auquel les debriefs Instay sont envoyés, si vide prend
            l’adresse mail information@gmail.com
          </Label>
        </FormGroup>
        <FormGroup>
          <Label>E-mail Poststay</Label>
          <TextArea defaultValue="" />
          <Label>
            Mails auquel les debriefs Poststay sont envoyés, si vide prend
            l’adresse mail information@gmail.com
          </Label>
        </FormGroup>
      </FormRow>
      <ButtonRow>
        <button className="btn">Annuler</button>
        <button className="btn">Sauvegarder</button>
      </ButtonRow>
    </FormContainer>
  );
}
