import React, { useEffect, useState } from "react";
import PrestayComponent from "./Prestay/PrestayComponent";
import { FormTitle } from "pages/Admin/DashboardAdmin/HotelConfig/Hotel/StyledHotel";
import { useGetConfigEmailQuery } from "actions/configEmail/queries";

const emailTypes = [
  "Pre-stay",
  "In-Stay",
  "Post-Stay",
  "Process Pre-checkin",
  "Activités",
  "Thank you Stay",
  "Thank you Stay 2",
  "Ask Review",
  "Bring Back",
  "Birthday",
  "Confirmation de réservation",
  "Confirmation d’annulation",
  "Confirmation de mise à jour",
];

const ActiveEmail = ({ selectedHotel }) => {
  const [emailConfig, setEmailConfig] = useState([]);
  const { data: listConfig } = useGetConfigEmailQuery(selectedHotel);

  useEffect(() => {
    if (listConfig) {
      setEmailConfig(listConfig);
    }
  }, [listConfig]);

  console.log(listConfig);


  const findConfigByType = (type) => {
    const typeMapping = {
      "Pre-stay": "pre_stay",
      "In-Stay": "in_stay",
      "Post-Stay": "post_stay",
      "Process Pre-checkin": "process_pre-checking",
      "Activités": "activity",
      "Thank you Stay": "thank_you_stay",
      "Thank you Stay 2": "thank_you_stay2",
      "Ask Review": "ask_review",
      "Bring Back": "bring_back",
      "Birthday": "birthday",
      "Confirmation de réservation": "confirmation_reservation",
      "Confirmation d’annulation": "confirmation_annulation",
      "Confirmation de mise à jour": "confirmation_update",
    };

    return emailConfig.find(
      (config) => config?.form_type === typeMapping[type]
    );
  };

  // Filter emailTypes based on availability in listConfig
  const availableEmailTypes = emailTypes.filter((type) =>
    findConfigByType(type)
  );

  return (
    <div>
      <div className="mt-3">
        <FormTitle>Activation e-mails</FormTitle>
      </div>
      {availableEmailTypes.map((type, index) => {
        const config = findConfigByType(type);
        console.log(config?.id);

        return (
          <div key={index} className="mb-3">
            <PrestayComponent
              titleLeft={type}
              titleRight="Enregistrement automatique"
              titlelabel={["Disponibilité", "Validé par le client", "Statut"]}
              configId={config?.id}
              switchLabels={[
                config?.disponible ? "Disponible" : "Indisponible",
                config?.validate ? "Validé" : "Invalidé",
                config?.statut ? "Activé" : "Désactivé",
              ]}
              footerLabels={[
                config?.photos_header || "Image du header",
                config?.photos_footer || "Image du footer",
              ]}
              initialToggleStates={[
                config?.disponible,
                config?.validate,
                config?.statut,
              ]}
              onClickButton1={() => console.log("View Email:", type)}
              onClickButton2={() => console.log("Edit Email:", type)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ActiveEmail;
