import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const LogoImage = styled.img`
  width: 25%;
  border-radius: 8.48px 0 0 0;
  opacity: 1;
  position: relative;
  right: 0px;
  @media (max-width: 768px) {
    width: 100px;
  }
`;
const ContainerLogo = styled.div`
  width: 100%;
`;

const Logo = ({ src, link }) => {
  return (
    <ContainerLogo>
      <Link to={link}>
        <LogoImage src={src} alt="Logo" />
      </Link>
    </ContainerLogo>
  );
};

export default Logo;
