import { ApiMananger } from "actions/rtkquery";
import { charteGrapiqueEndpoint } from "routes/api/endpoints";

export const charteGraphiqueApi = ApiMananger.injectEndpoints({
    endpoints: (builder) => ({
        updateCharteGraphique: builder.mutation({
            query: ({ id, hotelierData }) => ({
                url: charteGrapiqueEndpoint.charteGraphique(id),
                method: "PUT",
                body: hotelierData
            })
        }),
        patchCharteGraphique: builder.mutation({
            query: ({ id, hotelierData }) => ({
                url: charteGrapiqueEndpoint.charteGraphique(id),
                method: "PATCH",
                body: hotelierData
            })
        }),
        deleteCharteGraphique: builder.mutation({
            query: (id) => ({
                url: charteGrapiqueEndpoint.charteGraphique(id),
                method: "DELETE"
            })
        })
    })
});

export const {
    useUpdateCharteGraphiqueMutation,
    useDeleteCharteGraphiqueMutation
} = charteGraphiqueApi;
