import { ApiMananger } from "actions/rtkquery";

const postStayApi = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    addPostStay: builder.mutation({
      query: (serviceData) => ({
        url: "cofiguration/poststay/",
        method: "POST",
        body: serviceData,
      }),
    }),
    updatePostStay: builder.mutation({
      query: ({ id, serviceData }) => ({
        url: `${id}`,
        method: "PUT",
        body: serviceData,
      }),
    }),
    patchPostStay: builder.mutation({
      query: ({ id, serviceData }) => ({
        url: `${id}`,
        method: "PATCH",
        body: serviceData,
      }),
    }),
    deletePostStay: builder.mutation({
      query: (id) => ({
        url: `${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddPostStayMutation,
  useDeletePostStayMutation,
  usePatchPostStayMutation,
  useUpdatePostStayMutation,
} = postStayApi;
