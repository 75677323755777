import React from "react";
import styled from "styled-components";

const Button = styled.button`
  padding: 10px 36px;
  font-size: 15px;
  font-weight: 500;
  background-color: #d26129;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-family: "Inter", sans-serif;

  @media (max-width: 768px) {
    padding: 10px 16px;
  }
`;
const Icone = styled.span`
  font-size: 25px;
`;

function Bouton({ onClick, name, icon }) {
  return (
    <Button onClick={onClick}>
      <span>{name}</span>
      <Icone>{icon}</Icone>
    </Button>
  );
}

export default Bouton;
