import React, { useState } from "react";
import {
  FormContainer,
  FormGroup,
  FormTitleSmall,
  Label,
  Select,
} from "components/ConfigHotel/StyledCard";
import "./FormStyles.css";
import cadre from "../../../image/cadre.svg";
import { ButtonRow } from "pages/Admin/DashboardAdmin/HotelConfig/Hotel/StyledHotel";
import { useUpdateConfigEmailByIdMutation } from "actions/configEmail/mutations";
import { toast } from "react-toastify";

const PrestayComponent = ({
  configId,
  titleLeft,
  titleRight,
  switchLabels,
  footerLabels,
  titlelabel,
  initialToggleStates,
  onClickButton1,
  onClickButton2,
}) => {
  // Initialize state for each switch
  const [toggleStates, setToggleStates] = useState(initialToggleStates);

  // Use the mutation hook
  const [updateConfigEmailById] = useUpdateConfigEmailByIdMutation();

  const handleToggle = async (index) => {
    const updatedToggleStates = [...toggleStates];
    updatedToggleStates[index] = !toggleStates[index]; // Toggle the boolean value
    setToggleStates(updatedToggleStates);

    console.log(toggleStates)
    // Define which field is being toggled based on the index
    const fieldToUpdate = {
      0: "disponible",
      1: "validate",
      2: "statut",
    }[index];

    try {
      // Send the updated boolean value in the correct format
      await updateConfigEmailById({
        configId: configId,
        configData: {
          [fieldToUpdate]: updatedToggleStates[index], // Send the new boolean value
        },
      });
      toast.success("Etat de configuration d'email mis à jour");
    } catch (error) {
      toast.error("Une erreur est survenue: " + error.message);
      console.error("Failed to update the config:", error);
    }
  };

  return (
    <FormContainer>
      <div className="form-header">
        <FormTitleSmall>{titleLeft}</FormTitleSmall>
        <FormTitleSmall>
          {titleRight}{" "}
          <span>
            <img src={cadre} alt="cadre" />
          </span>
        </FormTitleSmall>
      </div>
      <div className="row">
        {/* Switch Labels and Buttons */}
        <div className="col-md-6 d-flex flex-column justify-content-between">
          <div className="d-flex flex-wrap gap-3">
            {switchLabels.map((label, index) => (
              <div className="d-flex flex-column me-4" key={index}>
                <Label>{titlelabel[index]}</Label> {/* Title Label */}
                <div className="d-flex align-items-center justify-content-between gap-2">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id={`flexSwitchCheckDefault-${index}`}
                      checked={toggleStates[index]}
                      onChange={() => handleToggle(index)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`flexSwitchCheckDefault-${index}`}
                    >
                      {label}
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <ButtonRow>
            <button type="button" className="btn" onClick={onClickButton1}>
              Consulter l'email
            </button>
            <button type="button" className="btn" onClick={onClickButton2}>
              Editer l'email
            </button>
          </ButtonRow>
        </div>
        {/* Footer Labels */}
        <div className="col-md-6 d-flex flex-column gap-3">
          {footerLabels.map((label, index) => (
            <FormGroup key={index}>
              <Label>{label}</Label>
              <Select type="text" defaultValue="2" />
            </FormGroup>
          ))}
        </div>
      </div>
    </FormContainer>
  );
};

export default PrestayComponent;
