import Card from "components/ConfigHotel/Card";
import {
  ButtonRow,
  FormGroup,
  FormRow,
  Input,
  Label,
  LabelDescription,
} from "../../Hotel/StyledHotel";
import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useAddInStayMutation } from "actions/configurationAvance/InStay/mutations";
import { useGetFormInStayQuery } from "actions/configurationAvance/InStay/queries"
import { ToastContainer, toast } from "react-toastify";

function InStay() {
  const { handleSubmit, register } = useForm({});

  const { hotelId } = useParams();

  const [ADDINSTAY, { isLoading }] = useAddInStayMutation();
  const { data: GETFORMINSTAY, refetch } = useGetFormInStayQuery();
  const getInStay = GETFORMINSTAY;
  // const value = getInStay?.envoi_pour_sejour_min_x_nuit;
  console.log({ GETFORMINSTAY });

  const onSubmit = async (data) => {
    try {
      const res = await ADDINSTAY({ ...data, hotel: hotelId });
      if (res.error) {
        toast.error("Une erreur est survenue lors de la soumission.");
      } else {
        toast.success("Enregistrement réussi");
        await refetch();
        // reset();
        console.log({ res });
      }
    } catch (err) {
      console.error("Erreur lors de la soumission:", err);
      toast.error("Une erreur inattendue est survenue.");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ToastContainer className="mt-5" />
      <Card title="In-Stay">
      <FormRow>
          <FormGroup>
            <Label>Envoi pour les séjours de minimum X nuits</Label>
            <Input
              type="number"
              defaultValue={getInStay?.envoi_pour_sejour_min_x_nuit || "3"}
              {...register("envoi_pour_sejour_min_x_nuit")}
            />
          </FormGroup>
          <FormGroup>
            <Label>Heure d'envoi</Label>
            <Input
              type="time"
              defaultValue={getInStay?.heure_envoi || "07:00"}
              {...register("heure_envoi")}
            />
            <LabelDescription>(Vide ou format HH:MM)</LabelDescription>
          </FormGroup>
        </FormRow>
        <ButtonRow>
          <button className="btn" type="button">Annuler</button>
          <button className="btn" type="submit">
            {isLoading ? "En cours..." : "Sauvegarder"}
          </button>
        </ButtonRow>
      </Card>
    </form>
  );
}

export default InStay;
