import { ConfigProvider } from "providers/ConfigHotel";
import React from "react";
import { Outlet } from "react-router-dom";
import HotelConfigHeader from "../Components/HotelConfigHeader";

function GestionConfigHotelLayout() {
  return (
    <ConfigProvider>
      <HotelConfigHeader />
      <Outlet />
    </ConfigProvider>
  );
}

export default GestionConfigHotelLayout;
