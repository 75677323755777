/*
|--------------------------------------------------------------------------
! All Your Application Navigations Paths Will Be Here
|--------------------------------------------------------------------------
*/

/**
 * @readonly
 * ! PUBLIC ROUTES
 */
export const PUBLIC_PATH = "/";
export const LOGIN_PATH = "login";
export const REGISTER_PATH = "signup";
export const FORGETPASSWORD = "auth/forgetPassword";
export const RESETPASSWORD = "auth/resetPassword";
// export const FORME_PATH = "formulaire-stay";
export const FORME_ACUEIL = "accueilForm";
export const FORME_PATH = "/formulaire-apropos";
export const FORME2_PATH = "/formulaire-sejour";
export const FORME3_PATH = "/formulaire-service";
export const FORME4_PATH = "/formulaire-validation";
export const FORME5_PATH = "/formulaire-termine";

/**
 * @readonly
 * ! PROTECTED ROUTES
 */
export const ACCEUIL_PATH = "acceuil";
export const PRODUCTS_PATH = "products";
export const DASHBOARD_PATH = "dashboard";
export const GESTION_HOTELIER_PATH = "gestion-hotelier";
export const GESTION_ENVOIE_PATH = "gestion-envoie";

export const MARKETING_PATH = "marketing-dashboard";
export const ADMIN_PATH = "admin-dashboard";
export const DASHBOARD_ADMIN_PATH = "dashboard-admin";
export const TEXTESPREPARES = "textes-prepares";
export const DASHBOARD_RELATION_PATH = "dashboard-du-client";
export const ELEMENTS_TRAITES_PATH = "dashboard-du-client/elements-traites";
export const DETAIL_CLIENT_PATH = "detail-client";
export const GESTION_PATH = "gestionaire-du-client";
export const STATISTIQUE = "statistique";
export const PLUS = "plus";
export const DETAIL_CLIENT = "detail-client";
export const TEXTESPREPARES_MESSAGES = "messages";
export const TEXTESPREPARES_MAIL = "email";
export const TEXTESPREPARES_ALL = "all";
export const TEXTESPREPARES_FORMULAIRE = "formulaire";
export const TEXTESPREPARES_NEW = "new-create";
export const ELEMENT_TRAITE = "elements-traites";
export const DEMANDE_CLIENT_PATH = "demande-client";
export const GESTION_VOYAGEUR_PATH = "gestion-voyageur";
export const CONFIGURATION_HOTEL_PATH = "configuration-hotel";
export const CONFIGURATION_HOTEL_ID_PATH = "configuration-hotel/:id";
export const HOTEL = "hotel";
export const EMAIL_EXPEDITEURS = "email-expediteurs";
export const GESTION_NOTIFICATION = "gestion-notification";
export const CONFIGURATION_AVANCEE = "configuration-avancee";
export const NOUVEAU_VOYAGEUR_PATH = "gestion-voyageur/nouvau-voyageur";
export const GESTION_RESERVATION_PATH = "gestion-reservation";
export const NOUVELLE_RESERVATION_PATH =
  "gestion-reservation/nouvelle-reservation";
export const REPORTING = "report";
export const API_CONNECT = "connect-api";
export const PARAMETRE = "parametre";
export const FORMULAIRE = "hotels/:hotel_id/forms/";

/**
 * @readonly
 * ! NOT FOUND ROUTE
 */
export const NOTFOUND_PATH = "*";
