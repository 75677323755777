import { useSelector } from "react-redux";

/**
 *
 * @return {true|false}
 */
function useIsAuthenticate() {
  const isAuthenticated = useSelector(
    (state) => state?.persist?.auth?.isAuthenticated,
  );
  return isAuthenticated;
}

export default useIsAuthenticate;
