import ParametreReseauxSociaux from "components/ParametresRSComponent/ParametreReseauxSociaux";
import useConfigHotel from "hooks/Hotel/useConfigHotel";
import React from "react";

function SocialMediaSettingsPage() {
  const { hotel } = useConfigHotel();
  return hotel?.isLoading ? (
    "Chargement en cours"
  ) : (
    <ParametreReseauxSociaux selectedHotel={hotel?.data || null} />
  );
}

export default SocialMediaSettingsPage;
