import { ApiMananger } from "actions/rtkquery";
import { emailEndpoint } from "routes/api/endpoints";

export const emailApi = ApiMananger.injectEndpoints({
    endpoints: (builder) => ({
        getEmails: builder.query({
            query: () => ({
                url: emailEndpoint.getAllEmails(),
                method: "GET"
            })
        }),
        getEmailById: builder.query({
            query: (emailId) => ({
                url: emailEndpoint.getEmailById(emailId),
                method: "GET"
            })
        })
    })
});

export const { useGetEmailsQuery, useGetEmailByIdQuery } = emailApi;
