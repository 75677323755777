import Email from "components/Email/Email";
import React from "react";
import { useParams } from "react-router-dom";

function EmailListPage() {
  const { hotelId } = useParams();
  return <Email selectedHotel={hotelId} />;
}

export default EmailListPage;
