// HotelConfig.js
import React from "react";
import { Outlet } from "react-router-dom";
import Hotel from "./Hotel/Hotel";
import useConfigHotel from "hooks/Hotel/useConfigHotel";

const HotelConfig = () => {
    const { hotel } = useConfigHotel();

    return (
        <div className="d-flex flex-column">
            {hotel?.isLoading
                ? "Chargement en cours..."
                : hotel?.isError
                    ? "Erreur survenue"
                    : hotel?.data && <Hotel selectedHotel={hotel?.data} />}
            <Outlet />
        </div>
    );
};

export default HotelConfig;
