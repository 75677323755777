import React, { cloneElement } from "react";
import SidebarItem from "../../components/SidebarItem";
import ResourceDataMap from "../../components/ResourceDataMap";
import Ellipse from "image/Ellipse.png";
import { useSelector, useDispatch } from "react-redux";
import {
  selectFirstName,
  selectLastName,
} from "redux/features/userSliceWithTokenValidation";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { logout } from "redux/features/userSliceWithTokenValidation";
import { LOGIN_PATH } from "routes/navigation/navigationPaths";
import { FiLogOut } from "react-icons/fi";
import { FaArrowCircleLeft } from "react-icons/fa";
import {
  setIsReduire,
  setNavbarPanerWidth,
  setNavbarWidth,
  setOutletWidth,
  setSidebarWidth,
} from "redux/features/SplitShellSlice";
import { links, linksNavebar } from "routes/navigation/sidebarLinks";
import { RiRadioButtonLine } from "react-icons/ri";
import { BsDoorOpenFill } from "react-icons/bs";

const DivReducSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  color: black;
  width: 47px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
`;
const DivLogout = styled.div`
  background: #e76f51;
  color: white;
  border-radius: 5px;
  cursor: pointer;
`;
const LinkAdmin = styled.p`
  cursor: pointer;
  font-family: "Roboto";
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 0px;
  padding-left: 13px;
  border-radius: 5px;
  height: 35px;
  width: auto;
  overflow: hidden;
  font-weight: 600;
  text-decoration: none;
  color: white !important;
  font-size: "20px";
`;

const SidebarComponent = ({
  bgColor,
  activeLinkColor,
  activeLinkBgColor,
  colorOnHover,
  linkColor,
}) => {
  const ClonedSidebarItem = ({ linkItem }) =>
    cloneElement(<SidebarItem linkItem={linkItem} />, {
      activeLinkColor,
      activeLinkBgColor,
      colorOnHover,
      linkColor,
    });

  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isReduire = useSelector(
    (state) => state?.persist?.sidebarWidth.isReduire,
  );

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      navigate(LOGIN_PATH);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleSideReduc = async () => {
    dispatch(setIsReduire(!isReduire));
    if (isReduire) {
      dispatch(setSidebarWidth("19%"));
      dispatch(setNavbarWidth("79%"));
      dispatch(setNavbarPanerWidth("99%"));
      dispatch(setOutletWidth("83%"));
    } else {
      dispatch(setSidebarWidth("7%"));
      dispatch(setNavbarWidth("99%"));
      dispatch(setNavbarPanerWidth("99%"));
      dispatch(setOutletWidth("100%"));
    }
  };

  return (
    <div
      className="d-flex flex-column py-1 justify-content-between"
      style={{
        backgroundColor: bgColor ? bgColor : "black",
        height: "92.4vh",
        padding: "0 22px",
        borderRight: "1px solid rgba(0,0,0,0.1)",
        color: "white",
        width: "300px",
      }}
    >
      <div className="d-flex flex-column align-items-center">
        <div
          className="d-flex align-items-center mb-2"
          style={{
            color: "white",
            cursor: "pointer",
            transition: "color 0.3s",
            marginTop: "25px",
          }}
        >
          <img
            className="me-3"
            src={Ellipse}
            alt="Profile"
            style={{ width: "30px", height: "30px", borderRadius: "50%" }}
          />
          {!isReduire && (
            <span className="d-flex flex-column">
              <span style={{ fontSize: "14px" }}>
                {firstName} {lastName}
              </span>
              <span style={{ fontSize: "11px" }}>
                <RiRadioButtonLine
                  style={{ color: "green" }}
                  className="me-2"
                />
                En ligne
              </span>
            </span>
          )}
        </div>
        <div style={{ marginTop: "20px" }}>
          <ResourceDataMap
            resourceData={linksNavebar}
            resourceItem={ClonedSidebarItem}
            resourceName="linkItem"
          />
          {links.map((link, index) => (
            <LinkAdmin key={index}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  fontSize: 20,
                }}
              >
                {link.icon}
                {link.label}
              </div>
            </LinkAdmin>
          ))}
        </div>
      </div>
      <div>
        <div
          className={`d-flex ${isReduire ? "justify-content-center" : "justify-content-end"}`}
        >
          <DivReducSide>
            {isReduire ? (
              <BsDoorOpenFill onClick={handleSideReduc} />
            ) : (
              <FaArrowCircleLeft onClick={handleSideReduc} />
            )}
          </DivReducSide>
        </div>
        <DivLogout
          className="d-flex flex-row align-items-center justify-content-center mt-3 mb-3"
          style={{ height: "35px" }}
          onClick={handleLogout}
        >
          <FiLogOut />
          {!isReduire && <span className="ms-1">Logout</span>}
        </DivLogout>
      </div>
    </div>
  );
};

export default SidebarComponent;
