import styled from "styled-components";

export const Input = styled.input`
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  ::placeholder {
    color: #aaa;
  }
`;
export const Label = styled.label`
  font-weight: 600;
`;
export const Button = styled.button`
  padding: 8px;
  margin-right: 15px;
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  border: ${(props) => props.borderColor};
  border-radius: 5px;
  font-weight: 600;
`;

export const FloatingButtons = styled.div`
  position: fixed;
  bottom: 70px;
  display: flex;
`;
