// Hotel.js
import React, { useEffect, useState } from "react";
import {
  ButtonRow,
  FormContainer,
  FormGroup,
  FormRow,
  FormTitle,
  Input,
  Label,
} from "./StyledHotel";
import { toast } from "react-toastify";
import { useUpdateHotelierMutation } from "actions/createHotel/mutations";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SelectDropdown } from "../StyleCOnfigHotel";

const Hotel = ({ selectedHotel }) => {
  const { hotelId: id } = useParams();
  const [updateHotelier, { isLoading }] = useUpdateHotelierMutation();
  const currentUser = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [countries, setCountries] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [filteredTimezones, setFilteredTimezones] = useState([]);
  const [formData, setFormData] = useState({
    email: selectedHotel?.email || "",
    nom_hotel: selectedHotel?.nom_hotel || "",
    hotelier: selectedHotel?.hotelier || "",
    code_fidelite: selectedHotel?.code_fidelite || "",
    site_internet: selectedHotel?.site_internet || "",
    code_postal: selectedHotel?.code_postal || "",
    ville: selectedHotel?.ville || "",
    pays: selectedHotel?.pays || "",
    phone: selectedHotel?.phone || "",
    adresse: selectedHotel?.adresse || "",
    timezone: selectedHotel?.timezone || "", // Keep timezone if it exists
    status: selectedHotel?.status || "HOTELIER",
    admin: currentUser?.user_id,
  });

  useEffect(() => {
    setFormData({
      email: selectedHotel?.email || "",
      nom_hotel: selectedHotel?.nom_hotel || "",
      hotelier: selectedHotel?.hotelier || "",
      code_fidelite: selectedHotel?.code_fidelite || "",
      site_internet: selectedHotel?.site_internet || "",
      code_postal: selectedHotel?.code_postal || "",
      ville: selectedHotel?.ville || "",
      pays: selectedHotel?.pays || "",
      phone: selectedHotel?.phone || "",
      adresse: selectedHotel?.adresse || "",
      timezone: selectedHotel?.timezone || "", // Keep timezone if it exists
      status: selectedHotel?.status || "HOTELIER",
      admin: currentUser?.user_id,
    });
  }, [selectedHotel, currentUser]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const countries = await response.json();
        const options = countries
          .map((country) => ({
            label: country?.name?.common,
            value: country?.cca2,
            capital: country?.capital?.[0] || "",
            flag: country?.flags.svg,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setCountries(options);
      } catch (error) {
        console.error("Erreur lors du chargement des pays :", error);
      }
    };

    const fetchTimezones = async () => {
      try {
        const response = await fetch(
          "https://timeapi.io/api/timezone/availabletimezones"
        );
        const timezones = await response.json();
        setTimezones(timezones);

        // If there is a pre-existing timezone, filter the dropdown based on it
        if (selectedHotel?.timezone) {
          setFilteredTimezones([selectedHotel.timezone]);
        }
      } catch (error) {
        console.error("Error fetching timezones:", error);
      }
    };

    fetchCountries();
    fetchTimezones();
  }, [selectedHotel]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    const selectedCountryData = countries.find(
      (country) => country.label === selectedCountry
    );

    setFormData((prev) => ({ ...prev, pays: selectedCountry }));

    if (selectedCountryData?.capital) {
      const filtered = timezones.filter((timezone) =>
        timezone.includes(selectedCountryData.capital)
      );
      setFilteredTimezones(filtered);
      if (filtered.length > 0) {
        setFormData((prev) => ({ ...prev, timezone: filtered[0] }));
      }
      if (filtered.length === 0) {
        const filtered = timezones.filter((timezone) =>
          timezone.includes("Etc")
        );
        setFilteredTimezones(filtered);
      }
    } else {
      const filtered = timezones.filter((timezone) =>
        timezone.includes("Etc")
      );
      setFilteredTimezones(filtered);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id && id !== undefined) {
        await updateHotelier({ id, hotelierData: formData }).unwrap();
        toast.success(
          "Les informations de l'hôtel sont modifiées avec succès!"
        );
      } else {
        toast.warning(
          "Veuillez choisir un hôtel avant d'effectuer des modifications"
        );
      }
    } catch (error) {
      console.error("Erreur lors de la modification de l'hôtel:", error.data);
      toast.error("Erreur lors de la modification de l'hôtel");
    }
  };

  const handleCreateNewHotel = () => {
    navigate("/dashboard");
  };

  return (
    <FormContainer>
      <FormTitle>Hôtel</FormTitle>
      <FormRow>
        <FormGroup>
          <Label>Nom de l&lsquo;hôtel</Label>
          <Input
            type="text"
            name="nom_hotel"
            value={formData.nom_hotel}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Nom de l&lsquo;hôtelier</Label>
          <Input
            type="text"
            name="hotelier"
            value={formData.hotelier}
            onChange={handleChange}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>URL Officiel du site internet de l&lsquo;hôtel</Label>
          <Input
            type="text"
            name="site_internet"
            value={formData.site_internet}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>E-mail Officiel de l&lsquo;hôtel</Label>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Code fidélité de l&lsquo;hôtel</Label>
          <Input
            type="text"
            name="code_fidelite"
            value={formData.code_fidelite}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Adresse</Label>
          <Input
            type="text"
            name="adresse"
            value={formData.adresse}
            onChange={handleChange}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Code postal</Label>
          <Input
            type="text"
            name="code_postal"
            value={formData.code_postal}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Ville</Label>
          <Input
            type="text"
            name="ville"
            value={formData.ville}
            onChange={handleChange}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Pays</Label>
          <SelectDropdown name="pays" value={formData.pays} onChange={handleCountryChange}>
            <option value="">Sélectionnez un pays</option>
            {countries.map((country) => (
              <option key={country.label} value={country.label}>
                {country.label}
              </option>
            ))}
          </SelectDropdown>
        </FormGroup>
        <FormGroup>
          <Label>Numéro de téléphone</Label>
          <Input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Timezone</Label>
          <SelectDropdown
            name="timezone"
            value={formData.timezone}
            onChange={handleChange}
          >
            <option value="">Sélectionnez un fuseau horaire</option>
            {filteredTimezones.map((timezone, index) => (
              <option key={index} value={timezone}>
                {timezone}
              </option>
            ))}
          </SelectDropdown>
        </FormGroup>
      </FormRow>
      <ButtonRow>
        <button type="button" className="btn" onClick={handleCreateNewHotel}>
          Annuler
        </button>
        <button
          type="submit"
          className="btn"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? "Chargement..." : "Sauvegarder"}
        </button>
      </ButtonRow>
    </FormContainer>
  );
};

export default Hotel;
