/* eslint-disable react/react-in-jsx-scope */
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import {
  LOGIN_PATH,
  REGISTER_PATH,
  FORGETPASSWORD,
  FORME_PATH,
  FORME_ACUEIL,
} from "../navigationPaths";
import WithoutAuth from "layouts/WithoutAuth";
import ConnexionPage from "pages/connexion/ConnexionPage";
import ErrorPasswordPage from "pages/ErrorPasswordPage/ErrorPasswordPage";
import SplitShellForm from "layouts/AppShells/SplitShellForm";
import Formulaire1 from "pages/FormPre-stay/Formulaire1";
import AccueilForm from "pages/FormPre-stay/AccueilForm";
import { Navigate } from "react-router-dom";

/*
|---------------------------------------------------------------
| Les routes publics
|---------------------------------------------------------------
| Toute page qui ne necessite pas une authentification doit etre ici
|
*/
export const publicRoutes = [
  {
    path: "*",
    element: <Navigate to={LOGIN_PATH} />,
  },
  {
    path: "",
    errorElement: <ErrorBoundary />,
    element: <WithoutAuth />,
    children: [
      {
        path: LOGIN_PATH,
        element: <ConnexionPage />,
      },
      {
        path: "",
        element: <Navigate to={LOGIN_PATH} />,
      },
      {
        path: FORGETPASSWORD,
        element: <ErrorPasswordPage />,
      },
      {
        path: REGISTER_PATH,
        element: <p>Hello fromo register</p>,
      },
      {
        path: FORME_PATH,
        element: <Formulaire1 />,
      },
      {
        path: "form",
        element: <SplitShellForm />,
        children: [
          {
            path: FORME_ACUEIL,
            element: <AccueilForm />,
          },
          {
            path: FORME_PATH,
            element: <Formulaire1 />,
          },
        ],
      },
    ],
  },
];
