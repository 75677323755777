import ActiveEmail from "components/ActiveEmail/ActiveEmail";
import React from "react";
import { useParams } from "react-router-dom";

function EmailActivationPage() {
  const { hotelId } = useParams();
  return <ActiveEmail selectedHotel={hotelId} />;
}

export default EmailActivationPage;
