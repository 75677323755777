import { ApiMananger } from "actions/rtkquery";
import { formulaireEndpoint } from "routes/api/endpoints";

export const formulaireApi = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    addFormulaire: builder.mutation({
      query: ({ id, FormulairData }) => ({
        url: formulaireEndpoint.addFormulaire(id),
        method: "POST",
        body: FormulairData,
      }),
    }),
    updateFormulaire: builder.mutation({
      query: ({ id_hotel, id_form, FormulairData }) => ({
        url: formulaireEndpoint.editFormulaire(id_hotel, id_form),
        method: "PUT",
        body: FormulairData,
      }),
    }),
    deleteFormulaire: builder.mutation({
      query: ({ id_hotel, id_form }) => ({
        url: formulaireEndpoint.deleteFormulaire(id_hotel, id_form),
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddFormulaireMutation,
  useUpdateFormulaireMutation,
  useDeleteFormulaireMutation,
} = formulaireApi;
