import React from "react";
import HotelChambre from "image/chambre.png";
import styled from "styled-components";
import { HiArrowLongRight } from "react-icons/hi2";
import Line from "image/line.png";
import BgImage from "image/bgForm.png";
import { useNavigate } from "react-router-dom";
import { FORME_PATH } from "routes/navigation/navigationPaths";
import Button from "components/FormulairePreStay/Bouton";

const Contain = styled.div`
  background-image: url(${BgImage});
  background-repeat: no-repeat;
  background-position: center;
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-template-rows: auto auto auto;
  align-items: center;
  gap: 1rem;
  padding: 4% 9%;
  @media (max-width: 661px) {
    display: block;
  }
`;
const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 1.5rem;
  color: #d26129;
  font-family: "Roboto", sans-serif;
  grid-column: 1 / 2;
`;
const Transform = styled.p`
  font-weight: 600;
  font-size: 38px;
  @media (min-width: 320px) and (max-width: 1024px) {
    font-size: 20px;
  }
`;
const Message = styled.p`
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 0;
  @media (min-width: 320px) and (max-width: 1024px) {
    font-size: 12px;
  }
`;
const ContainImage = styled.div`
  grid-column: 2 / 2;
  margin-left: 27px;
  @media (max-width: 661px) {
    display: none;
    margin-left: 0;
  }
`;
const Image = styled.img`
  width: 100%;
`;
const ContainTextImage = styled.div`
  display: flex;
  gap: 1rem;
`;
const Image2 = styled.img`
  width: 1%;
  height: 200px;
  @media (min-width: 320px) and (max-width: 1024px) {
    height: 100px;
  }
`;
const TextUtils = styled.p`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
`;

function AccueilForm() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(FORME_PATH);
  };
  return (
    <Contain>
      <Container>
        <ContainerText>
          <Transform>
            Transformons votre sejour chez Azalai Hotel Dakar en moments
            inoubliables
          </Transform>
          <ContainTextImage>
            <Image2 src={Line} />
            <div>
              <Message>
                Cher Monsieur Mouhamet, Merci d'avoir choisi Azalai Hôtel où
                nous aurons le plaisir de vous accueillir dans 7 jours.
              </Message>
              <Message>
                Pour faire de votre séjour une expérience unique et
                personnalisée, nous avons préparé un questionnaire afin de
                préparer au mieux votre séjour à la Maison
              </Message>
            </div>
          </ContainTextImage>
          <Button
            onClick={handleClick}
            name="Remplir le questionnaire"
            icon={<HiArrowLongRight />}
          />
          <div>
            <TextUtils>
              Utilisez le lien suivant si le bouton ci-dessus n'apparaît pas :{" "}
              <br />
              <a
                href="https://www.azalai.com/azalai-hotel-dakar"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.azalai.com/azalai-hotel-dakar
              </a>
            </TextUtils>
          </div>
        </ContainerText>
        <ContainImage>
          <Image src={HotelChambre} />
        </ContainImage>
      </Container>
    </Contain>
  );
}

export default AccueilForm;
