import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userslice from "./features/userslice";
import userSliceWithTokenValidation from "./features/userSliceWithTokenValidation";
import { splitShellSlice } from "./features/SplitShellSlice";
// import API from "actions/auth/mutations";
// import voyageurApi from "actions/Voyageurservices";
// import { reservationApi } from "actions/ReservationService";
// import { hotelierApi } from "actions/createHotel/mutations";
// import { userApi } from "actions/UserService";
// import { hotelierQueryApi } from "actions/createHotel/queries";
// import { charteGraphiqueApi } from "actions/CharteGraphiqurService";
// import { serviceBaseApi } from "actions/ServiceBase/mutations";
// import { serviceComandeeApi } from "actions/ServiceComandee/mutations";
// import { formulaireApi } from "actions/Formulaires/mutations";
// import { configEmailApi } from "actions/configEmail/mutations";
import { rxdApi } from "actions/rtkquery";

const persistConfig = {
  key: "root-safmo",
  storage,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  persist: persistReducer(
    persistConfig,
    combineReducers({
      sidebarWidth: splitShellSlice.reducer,
      auth: userSliceWithTokenValidation,
      user: userslice,
    }),
  ),
  [rxdApi.reducerPath]: rxdApi.reducer,
});

// const rootReducers = combineReducers({
//   sidebarWidth: splitShellSlice.reducer,
//   auth: userSliceWithTokenValidation,
//   user: userslice,
//   [rxdApi.reducer]: rxdApi.reducer,
//   [API.reducerPath]: API.reducer,
//   [voyageurApi.reducerPath]: voyageurApi.reducer,
//   [reservationApi.reducerPath]: reservationApi.reducer,
//   [hotelierApi.reducerPath]: hotelierApi.reducer,
//   [userApi.reducerPath]: userApi.reducer,
//   [hotelierQueryApi.reducerPath]: hotelierQueryApi.reducer, // Corrected line
//   [charteGraphiqueApi.reducerPath]: charteGraphiqueApi.reducer,
//   [serviceBaseApi.reducerPath]: serviceBaseApi.reducer,
//   [serviceComandeeApi.reducerPath]: serviceComandeeApi.reducer,
//   [formulaireApi.reducerPath]: formulaireApi.reducer,
//   [configEmailApi.reducerPath]: configEmailApi.reducer,
// });

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(
      rxdApi.middleware,
      //   API.middleware,
      //   voyageurApi.middleware,
      //   reservationApi.middleware,
      //   hotelierApi.middleware,
      //   userApi.middleware,
      //   hotelierQueryApi.middleware,
      //   charteGraphiqueApi.middleware,
      //   serviceBaseApi.middleware,
      //   serviceComandeeApi.middleware,
      //   configEmailApi.middleware
    ),
  devTools: true,
});

export const persistor = persistStore(store);
