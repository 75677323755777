import React, { useState, useEffect, useRef } from "react";
import {
  FormContainer,
  Label,
  CardBody,
  Select,
  Button,
  FormTitle
} from "components/ConfigHotel/StyledCard";
import {
  Input,
  FormGroup,
  ButtonRow,
} from "pages/Admin/DashboardAdmin/HotelConfig/Hotel/StyledHotel";
import defaultImage from "image/defaultImage.svg";
import { toast } from "react-toastify";
import { useUpdateCharteGraphiqueMutation } from "actions/CharteGraphic/mutations";

const PhotoComponent = ({ selectedHotel }) => {
  const [imageSrc, setImageSrc] = useState(defaultImage);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState({
    photos_header: "",
    photos_footer: "",
  });
  const [loading, setLoading] = useState(false);

  const [updateCharteGraphique] = useUpdateCharteGraphiqueMutation();
  const hasMounted = useRef(false);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }
    if (selectedHotel?.nom_hotel) {
      // Initialize or update any related data if needed
    }
  }, [selectedHotel]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newImages = Array.from(files).map((file) => {
      return { name: file.name, file };
    });
    setUploadedImages((prev) => [...prev, ...newImages]);

    // If you want to show the first image preview
    const reader = new FileReader();
    reader.onload = (e) => {
      setImageSrc(e.target.result);
    };
    reader.readAsDataURL(newImages[0].file);

    if (newImages.length > 0) {
      setSelectedImages((prev) => ({
        ...prev,
        photos_header: newImages[0].name,
      }));
    }
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    const selectedImage = uploadedImages.find((img) => img.name === value);
    if (selectedImage) {
      setImageSrc(URL.createObjectURL(selectedImage.file));
      setSelectedImages((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append(
      "photos_header",
      uploadedImages.find((img) => img.name === selectedImages.photos_header)?.file || ""
    );
    formDataToSend.append(
      "photos_footer",
      uploadedImages.find((img) => img.name === selectedImages.photos_footer)?.file || ""
    );

    try {
      const chartGraphiqueId = selectedHotel?.chart_graphique?.id;
      await updateCharteGraphique({
        id: chartGraphiqueId,
        hotelierData: formDataToSend,
      });
      toast.success("Photos mises à jour avec succès");
    } catch (error) {
      toast.error("Erreur lors de la mise à jour des photos: " + error.message);
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="card" style={{ backgroundColor: "#F3F3F3" }}>
      <div className="card-body">
        <FormContainer>
          <div className="row">
            <FormTitle>Photo</FormTitle>
            <div className="col-md-6">
              <FormGroup>
                <Label>Photo du Header</Label>
                <Select
                  name="photos_header"
                  onChange={handleSelectChange}
                  value={selectedImages.photos_header}
                >
                  <option value="">Sélectionner une image</option>
                  {uploadedImages.map((image, index) => (
                    <option key={index} value={image.name}>
                      {image.name}
                    </option>
                  ))}
                </Select>
              </FormGroup>
              <FormGroup className="mt-3">
                <Label>Photo du Footer</Label>
                <Select
                  name="photos_footer"
                  onChange={handleSelectChange}
                  value={selectedImages.photos_footer}
                >
                  <option value="">Sélectionner une image</option>
                  {uploadedImages.map((image, index) => (
                    <option key={index} value={image.name}>
                      {image.name}
                    </option>
                  ))}
                </Select>
              </FormGroup>
            </div>
            <div className="col-md-6">
              <CardBody>
                <div>
                  <Input
                    type="file"
                    id="fileInputs"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    multiple
                  />
                  <Button
                    type="button"
                    className="btn"
                    onClick={() => document.getElementById("fileInputs").click()}
                  >
                    Télécharger vos fichiers
                  </Button>
                </div>
                <div style={{ border: "1px solid dotted", marginTop: "20px" }}>
                  <img
                    src={imageSrc}
                    alt="Uploaded"
                    style={{ width: "100%" }}
                  />
                </div>
              </CardBody>
            </div>
            <ButtonRow>
              <button type="button" className="btn">
                Annuler
              </button>
              <button
                type="button"
                className="btn"
                onClick={handleSave}
                disabled={loading}
              >
                {loading ? "Chargement..." : "Sauvegarder"}
              </button>
            </ButtonRow>
          </div>
        </FormContainer>
      </div>
    </div>
  );
};

export default PhotoComponent;
