import styled from "styled-components";

export const FormContainer = styled.div`
  padding: 20px;
  background: #f7f7f7;
  border-radius: 8px;
`;

export const FormTitle = styled.h2`
  margin-bottom: 20px;
`;
export const FormTitleSmall = styled.h2`
  margin-bottom: 20px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 28px;
  color: #000000;
`;
export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const FormGroup = styled.div`
  flex: 1;
  margin-right: 10px;
`;

export const Label = styled.label`
  margin-bottom: 8px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  .btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #ffa500;
    color: white;
  }
`;

export const CardContainer = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
`;

export const CardHeader = styled.h3`
  margin-bottom: 25px;
`;

export const CardContent = styled.div`
  margin-bottom: 20px;
`;

export const CardActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const CardNotice = styled.div`
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
`;
export const Select = styled.select`
  width: 100%;
  padding: 8px;
  border: 1.3px solid #dddddd;
  border-radius: 30px;
  font-family: Roboto;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
`;
export const Button = styled.button`
    background-color: #FFC857;
    border-radius: 38.19px;
    border: none;
    color: #000000;
    padding: 7px 30px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 20px
    width: 100%;
    font-family: Roboto;
    font-weight: 500;
    &:hover {
        background-color: #E5B747;
    }
`;
export const Buttons = styled.button`
    background-color: #FFC857;
    border-radius: 38.19px;
    border: none;
    color: #000000;
    padding: 8px 28px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 20px
    width: 100%;
    font-family: Roboto;
    font-weight: 500;
    &:hover {
        background-color: #E5B747;
    }
`;
export const CardBody = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
