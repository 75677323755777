import Card from "components/ConfigHotel/Card";
import {
  ButtonRow,
  FormGroup,
  FormRow,
  Input,
  Label,
} from "../../Hotel/StyledHotel";
import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useAddPostStayMutation } from "actions/configurationAvance/PostStay/mutations";
import { ToastContainer, toast } from "react-toastify";

function PostStay() {
  const { handleSubmit, register } = useForm({});

  const { hotelId } = useParams();

  const [ADDPOSTSTAY, { isLoading }] = useAddPostStayMutation();

  const onSubmit = async (data) => {
    try {
      const res = await ADDPOSTSTAY({ ...data, hotel: hotelId });
      if (res.error) {
        toast.error("Une erreur est survenue lors de la soumission.");
      } else {
        toast.success("Enregistrement réussi");
        console.log({ res });
      }
    } catch (err) {
      console.error("Erreur lors de la soumission:", err);
      toast.error("Une erreur inattendue est survenue.");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ToastContainer className="mt-5" />
      <Card title="Post-stay">
        <FormRow>
          <FormGroup>
            <Label>Envoi de l'email X jour après le départ</Label>
            <Input type="number" defaultValue="1" {...register("envoi_mail_x_jour_apres_depart")} />
          </FormGroup>
          <FormGroup>
            <Label>Heure d'envoi</Label>
            <Input type="time" defaultValue="11:00" {...register("heure_envoi")} />
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <Label>Durée minimum du séjour</Label>
            <Input type="number" defaultValue="1" {...register("dure_min_sejour")} />
          </FormGroup>
        </FormRow>
        <ButtonRow>
        <button className="btn" type="button">Annuler</button>
          <button className="btn" type="submit">
            {isLoading ? "En cours..." : "Sauvegarder"}
          </button>
        </ButtonRow>
      </Card>
    </form>
  );
}

export default PostStay;
