import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

export default function LineDemo() {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary",
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: [
        "Fev",
        "Mar",
        "Avr",
        "Mai",
        "Juin",
        "Juil",
        "Aout",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
        "Jan",
      ],
      datasets: [
        {
          label: "First Dataset",
          data: [10, 16, 17, 16, 16, 15, 16, 16, 17, 17, 16, 18, 19, 26],
          fill: false,
          borderColor: "#1C1B2B",
          tension: 0.4,
        },
        {
          label: "Second Dataset",
          data: [8, 19, 17, 15, 16, 15, 16, 16, 17, 17, 16, 18, 19, 26],
          fill: false,
          borderColor: "#9F5540",
          tension: 0.4,
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false, // Masquer la légende
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            display: false,
          },
        },
        y: {
          ticks: {
            display: false, // Masquer les labels de l'axe des y
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <div
      style={{
        width: "595px",
        height: "300px",
        overflow: "hidden",
        marginLeft: "-15px",
      }}
    >
      <Chart
        type="line"
        data={chartData}
        options={chartOptions}
        width="540"
        height="300"
      />
    </div>
  );
}
