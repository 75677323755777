import React, { useState } from "react";
import { IoVideocamOutline } from "react-icons/io5";
import { FaRegQuestionCircle } from "react-icons/fa";
import { SlEarphonesAlt } from "react-icons/sl";
import { IoMdArrowDropdown } from "react-icons/io";
import styled from "styled-components";

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
  border-radius: 6px;
  margin-top: 12px;
  width: 250px;
`;

const MenuItem = styled.p`
  margin: 0;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #d3eafd;
  }
`;

const IconStyle = {
  marginRight: "10px",
};

const AssistanceDropdown = () => {
  const [isAssistanceDropdownVisible, setIsAssistanceDropdownVisible] =
    useState(false);

  const toggleAssistanceDropdown = () => {
    setIsAssistanceDropdownVisible(!isAssistanceDropdownVisible);
  };

  return (
    <DropdownContainer>
      <div
        onClick={toggleAssistanceDropdown}
        style={{
          marginLeft: 10,
          marginRight: 15,
          color: "#000000",
          fontFamily: "Roboto",
          fontWeight: "400",
          cursor: "pointer",
          transition: "color 0.3s",
          position: "relative",
        }}
        aria-haspopup="true"
        aria-expanded={isAssistanceDropdownVisible}
        role="button"
      >
        Assistance <IoMdArrowDropdown style={{ marginLeft: "5px" }} />
      </div>
      {isAssistanceDropdownVisible && (
        <DropdownMenu>
          <MenuItem>
            <IoVideocamOutline style={IconStyle} /> Vidéos tutoriels
          </MenuItem>
          <MenuItem>
            <FaRegQuestionCircle style={IconStyle} /> Centre d'aide
          </MenuItem>
          <MenuItem>
            <SlEarphonesAlt style={IconStyle} /> Contacter l'assistance
          </MenuItem>
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default AssistanceDropdown;
