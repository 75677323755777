import { ApiMananger } from "actions/rtkquery";
import { configEmailEndpoint } from "routes/api/endpoints";

export const configEmailApi = ApiMananger.injectEndpoints({
    endpoints: (builder) => ({
        getConfigEmail: builder.query({
            query: (hotel_id) => ({
                url: configEmailEndpoint.getConfigEmail(hotel_id),
                method: "GET"
            })
        }),
        getConfigEmailById: builder.query({
            query: (configId) => ({
                url: configEmailEndpoint.getSingleConfigEmail(configId),
                method: "GET"
            })
        })
    })
});

export const { useGetConfigEmailQuery, useGetConfigEmailByIdQuery } =
    configEmailApi;
