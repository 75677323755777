// Dropdown.js
import React, { useState, useEffect, useRef } from "react";
import {
    CreateNewButton,
    DropdownContainer,
    DropdownWrapper,
    HotelItem,
    HotelList,
    SearchInput,
    SelectedHotel,
} from "pages/Admin/DashboardAdmin/HotelConfig/StyleCOnfigHotel";

const Dropdown = ({ selectedHotel, hotels, onSelect }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const dropdownRef = useRef(null);

    const filteredHotels = hotels?.filter((hotel) =>
        hotel?.nom_hotel?.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    const handleSelectHotel = (hotel) => {
        onSelect(hotel);
        setIsDropdownOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <DropdownWrapper ref={dropdownRef}>
            <SelectedHotel onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                {selectedHotel?.nom_hotel || selectedHotel}
            </SelectedHotel>
            {isDropdownOpen && (
                <DropdownContainer>
                    <SearchInput
                        type="text"
                        placeholder="Rechercher"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <HotelList>
                        {filteredHotels?.map((hotel, index) => (
                            <HotelItem key={index} onClick={() => handleSelectHotel(hotel)}>
                                {hotel.nom_hotel}
                            </HotelItem>
                        ))}
                    </HotelList>
                    <CreateNewButton
                        onClick={() => handleSelectHotel("Choisir un nouvel hôtel")}
                    >
                        <span
                            style={{
                                color: "#000000",
                                fontWeight: 400,
                                fontFamily: "Roboto",
                                fontSize: "18px",
                            }}
                        >
                            Créer un nouvel hôtel
                        </span>
                    </CreateNewButton>
                </DropdownContainer>
            )}
        </DropdownWrapper>
    );
};

export default Dropdown;
