import React from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { privateRoutes } from "routes/navigation/navigationRoutes/private";
import { NavLink } from "react-router-dom";

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(privateRoutes);

  const breadcrumbStyle = {
    fontSize: "14px",
    fontWeight: "200",
    color: "blue",
    textDecoration: "none",
    padding: "2px",
  };

  return (
    <div style={{ textAlign: "left" }}>
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <React.Fragment key={index}>
          <NavLink
            key={match.pathname}
            to={match.pathname}
            style={breadcrumbStyle}
          >
            {breadcrumb}
          </NavLink>
          {index > 0 && <span> / </span>}
        </React.Fragment>
      ))}
    </div>
  );
};
export default Breadcrumbs;
