import React, { useEffect } from "react";
import Modal from "components/Modal";
import { ButtonRow, FormGroup, FormRow, Input, Label } from "./StyledHotel";
import { useAddNewHotelierMutation } from "actions/createHotel/mutations";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export default function AddHotelModal({ opened, onClosed }) {
  const [addNewHotelier, { isLoading, isError, isSuccess }] =
    useAddNewHotelierMutation();
  const currentUser = useSelector((state) => state?.persist?.auth);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      hotelier: "",
      nom_hotel: "",
      email: "",
      phone: "",
      pays: "",
      adresse: "",
      ville: "",
      admin: currentUser?.user_id,
    },
  });

  useEffect(() => {
    if (isError) {
      console.error("An error occurred while adding hotelier.");
    }
    if (isSuccess) {
      console.log("Hotelier ajouté avec succées!");
    }
    if (isLoading) {
      console.log("Hotelier loading!");
    }
  }, [isError, isSuccess, isLoading]);

  const onSubmit = async (data) => {
    try {
      await addNewHotelier(data).unwrap();
      toast.success("L'hôtel est ajouté avec succès");
      console.log("Form data saved:", data);
      onClosed();
      navigate("/gestion-hotelier");
    } catch (error) {
      const errorMessage =
        error?.data?.error ||
        "Une erreur est survenue lors de l'ajout de l'hôtel";
      toast.error(errorMessage);
      console.error("Une erreur est survenue :", error);
    }
  };

  return (
    <Modal opened={opened} onClosed={onClosed} title="NOUVEL HÔTELIER">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="d-flex flex-column gap-3"
      >
        <FormRow>
          <FormGroup>
            <Label>Nom de l'hôtelier</Label>
            <Input
              type="text"
              {...register("hotelier", { required: "Ce champ est requis" })}
            />
            {errors.hotelier && (
              <span className="text-danger">{errors.hotelier.message}</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Gérant de l'hôtel</Label>
            <Input
              type="text"
              {...register("nom_hotel", { required: "Ce champ est requis" })}
            />
            {errors.nom_hotel && (
              <span className="text-danger">{errors.nom_hotel.message}</span>
            )}
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <Label>Email officile de l'hotel</Label>
            <Input
              type="text"
              {...register("email", {
                required: "Ce champ est requis",
                pattern: {
                  value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                  message: "Adresse email invalide",
                },
              })}
            />
            {errors.email && (
              <span className="text-danger">{errors.email.message}</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Numéro de téléphone</Label>
            <Input
              type="tel"
              {...register("phone", { required: "Ce champ est requis" })}
            />
            {errors.phone && (
              <span className="text-danger">{errors.phone.message}</span>
            )}
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <Label>Adresse</Label>
            <Input type="text" {...register("ville")} />
          </FormGroup>
          <FormGroup>
            <Label>Pays</Label>
            <Input type="text" {...register("pays")} />
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <Label>Ville</Label>
            <Input type="text" {...register("ville")} />
          </FormGroup>
        </FormRow>
        <ButtonRow className="justify-content-end">
          <button type="button" className="btn" onClick={onClosed}>
            Annuler
          </button>
          <button type="submit" className="btn" disabled={isLoading}>
            {isLoading ? "Chargement..." : "Sauvegarder"}
          </button>
        </ButtonRow>
      </form>
    </Modal>
  );
}
