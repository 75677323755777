// HotelConfig.js
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Header, SubHeader } from "../StyleCOnfigHotel";
import Dropdown from "components/ConfigHotel/DropDown";
import { Label } from "../Hotel/StyledHotel";
import { useGetHoteliersQuery } from "actions/createHotel/queries";
import AddHotelModal from "../Hotel/AddHotelModal";

const HotelConfigHeader = () => {
  const { data } = useGetHoteliersQuery();
  const { hotelId } = useParams();
  const navigate = useNavigate();
  const [modalOpened, setModalOpened] = useState(false);

  const hoteliers = data?.data || [];

  const handleSelectHotel = (hotel) => {
    // setSelectedHotel(hotel);
    if (hotel.nom_hotel === "Créer un nouvel hôtel" || !hotel.id) {
      setModalOpened(true);
    } else {
      navigate(`/configuration-hotel/${hotel.id}/`);
    }
  };

  return (
    <div className="d-flex flex-column">
      <AddHotelModal
        opened={modalOpened}
        onClosed={() => setModalOpened(false)}
      />{" "}
      {/* Add the modal */}
      <Container>
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <Header>Configuration Hôtel</Header>
            <SubHeader>Retrouvez tous vos paramètres d&lsquo;hôtel ici</SubHeader>
          </div>
          <div>
            <Label>Rechercher, choisir, créer un hôtel</Label>
            <Dropdown
              selectedHotel={
                hoteliers?.find((hotel) => hotel?.id === +hotelId)?.nom_hotel ||
                "Sélectionner un hôtel"
              }
              hotels={hoteliers}
              onSelect={handleSelectHotel}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HotelConfigHeader;
