import FormBuilder from "components/Formulaire/Formulaire_Pre_Stay";
import React from "react";
import { useParams } from "react-router-dom";

function FormPreStayPage() {
  const { hotelId } = useParams();
  return <FormBuilder hotelId={hotelId} />;
}

export default FormPreStayPage;
