import styled from "styled-components";

export const ContainerDemandeClient = styled.div`
  background: #fff;
  border-radius: 14px;
  // min-height: 100vh;
`;
export const Paragraphe = styled.p`
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  padding: 0;
`;
export const TextList = styled.p`
  font-size: 20px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
`;
