import React, { useState, useMemo } from "react";
import useWindowDimensions from "../hooks/useWindowsDimention";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Navbar from "components/HeaderListe/Navbar";
import { DivBreadCrumb } from "components/RelationClientDetail/InfoClient/InfoClientCSS";
import BreadCrumb from "components/RelationClientDetail/BreadCrumb";
import "./index.css";

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const NavbarPaner = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 4;
`;

const SidebarPaner = styled.div`
  width: ${({ sidebarWidth }) => sidebarWidth};
  display: flex;
  justify-content: center;
`;
const BodyContainer = styled.div`
  display: flex;
  margin-top: 55px;
  height: 100vh;
  overflow: hidden;
`;

const BigContainer = styled.div`
  transition: padding 0.3s;
  overflow: hidden;
  z-index: 1;
  width: 100%;
`;
const ContaintOutlet = styled.div`
  height: 100vh;
  padding: 100px 40px;
  background-color: white;
  transition: padding 0.3s;
  overflow-x: hidden;
  z-index: 1;
`;

const SpliTemplateScreen = ({ children }) => {
  const [openedMenu, setOpenedMenu] = useState(false);
  const [minViewPort, setMinViewPort] = useState(false);

  const sidebarWidth = useSelector(
    (state) => state?.persist?.sidebarWidth.width,
  );
  const navbarPanerWidth = useSelector(
    (state) => state?.persist?.sidebarWidth.navbarPanerWidth,
  );

  const [sidebar, navbar, outlet] = children;

  const { width } = useWindowDimensions();

  useMemo(() => {
    if (width <= 900) {
      setMinViewPort(true);
      setOpenedMenu(true);
    } else {
      setMinViewPort(false);
      setOpenedMenu(false);
    }
  }, [width]);

  const navLinks = [
    { path: "#", label: "Relation client" },
    { path: "marketing", label: "Marketing" },
    { path: "/dashboard", label: "Admin" },
  ];
  const breadcrumbLinks = [{ text: "Accueil", url: "#" }];

  return (
    <Container>
      <NavbarPaner navbarPanerWidth={navbarPanerWidth}>{navbar}</NavbarPaner>
      <BodyContainer>
        <SidebarPaner sidebarWidth={sidebarWidth}>{sidebar}</SidebarPaner>
        <BigContainer openedMenu={openedMenu} minViewPort={minViewPort}>
          <Navbar links={navLinks} />
          <div>
            <DivBreadCrumb style={{ marginTop: "70px" }}>
              <BreadCrumb links={breadcrumbLinks} />
            </DivBreadCrumb>
          </div>
          <ContaintOutlet>{outlet}</ContaintOutlet>
        </BigContainer>
      </BodyContainer>
    </Container>
  );
};

export default SpliTemplateScreen;
