// HotelConfig.js
import HotelConfigLinks from "components/ConfigHotel/HotelConfigLinks";
import React from "react";
import { Outlet } from "react-router-dom";

const navLinks = [
  { name: "Activation e-mails", path: "" },
  { name: "E-mails", path: "list" },
  { name: "Signatures", path: "signatures" },
  {
    name: "Avantages et codes de fidélité",
    path: "loyalty-benefits-codes",
  },
];

const ParametreEmailLayout = () => {
  return (
    <div className="d-flex flex-column">
      <HotelConfigLinks links={navLinks} />
      <Outlet />
    </div>
  );
};

export default ParametreEmailLayout;
