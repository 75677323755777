import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const cErrorPassword = yup
  .object()
  .shape({
    phone: yup.string().required("telephone obligatoire"),
    password: yup.number().required(),
  })
  .required();
const ErrorPasswordProvider = ({ children }) => {
  const methods = useForm({
    values: {
      phone: "",
      password: "",
    },
    defaultValues: {
      phone: "",
      password: "",
    },
    resolver: yupResolver(cErrorPassword),
  });
  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default ErrorPasswordProvider;
