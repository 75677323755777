import React from "react";
import CharteGraphique from "../../CharteGraphique/CharteGraphique";
import useConfigHotel from "hooks/Hotel/useConfigHotel";

function IdentiteVisuellePage() {
  const { hotel } = useConfigHotel();
  return hotel?.isLoading ? (
    "Chargement en cours"
  ) : (
    <CharteGraphique selectedHotel={hotel?.data || null} />
  );
}

export default IdentiteVisuellePage;
