import React, { useState } from "react";
import {
  FormContainer,
  FormTitle,
  Label,
} from "components/ConfigHotel/StyledCard";
import {
  Input,
  FormGroup,
  ButtonRow,
} from "pages/Admin/DashboardAdmin/HotelConfig/Hotel/StyledHotel";
import { ChromePicker } from "react-color";
import { toast } from "react-toastify";
import { useUpdateCharteGraphiqueMutation } from "actions/CharteGraphic/mutations";

const ParametreCouleurs = ({ selectedHotel }) => {
  const [colorPickerVisible, setColorPickerVisible] = useState({
    fondEmail: false,
    liens: false,
    textes: false,
  });

  const [colors, setColors] = useState({
    couleur_fond_email: selectedHotel?.chart_graphique?.couleur_fond_email || "",
    couleur_liens: selectedHotel?.chart_graphique?.couleur_liens || "",
    couleur_textes: selectedHotel?.chart_graphique?.couleur_textes || "",
  });

  const [loading, setLoading] = useState(false);
  const [updateCharteGraphique] = useUpdateCharteGraphiqueMutation();

  const handleColorChange = (updatedColor, name) => {
    const colorHex = updatedColor.hex;
    setColors((prev) => ({ ...prev, [name]: colorHex }));
  };

  const toggleColorPicker = (field) => {
    setColorPickerVisible((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleSave = async () => {
    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append("couleur_fond_email", colors.couleur_fond_email);
    formDataToSend.append("couleur_liens", colors.couleur_liens);
    formDataToSend.append("couleur_textes", colors.couleur_textes);

    try {
      const chartGraphiqueId = selectedHotel.chart_graphique.id;
      await updateCharteGraphique({
        id: chartGraphiqueId,
        hotelierData: formDataToSend,
      });
      toast.success("Couleurs de l'email mises à jour avec succès");
    } catch (error) {
      toast.error("Erreur lors de la mise à jour: " + error.message);
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="card" style={{ backgroundColor: "#F3F3F3" }}>
      <div className="card-body">
        <FormContainer>
          <div className="row">
            <FormTitle>Paramétrage des couleurs de l'email</FormTitle>
            <div className="col-md-6">
              <FormGroup>
                <Label>Couleur de fond</Label>
                <Input
                  type="text"
                  name="couleur_fond_email"
                  value={colors.couleur_fond_email}
                  onClick={() => toggleColorPicker("fondEmail")}
                  readOnly
                />
                {colorPickerVisible.fondEmail && (
                  <ChromePicker
                    color={colors.couleur_fond_email}
                    onChange={(updatedColor) =>
                      handleColorChange(updatedColor, "couleur_fond_email")
                    }
                  />
                )}
              </FormGroup>
              <FormGroup className="mt-3">
                <Label>Couleur des liens</Label>
                <Input
                  type="text"
                  name="couleur_liens"
                  value={colors.couleur_liens}
                  onClick={() => toggleColorPicker("liens")}
                  readOnly
                />
                {colorPickerVisible.liens && (
                  <ChromePicker
                    color={colors.couleur_liens}
                    onChange={(updatedColor) =>
                      handleColorChange(updatedColor, "couleur_liens")
                    }
                  />
                )}
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup>
                <Label>Couleur du texte</Label>
                <Input
                  type="text"
                  name="couleur_textes"
                  value={colors.couleur_textes}
                  onClick={() => toggleColorPicker("textes")}
                  readOnly
                />
                {colorPickerVisible.textes && (
                  <ChromePicker
                    color={colors.couleur_textes}
                    onChange={(updatedColor) =>
                      handleColorChange(updatedColor, "couleur_textes")
                    }
                  />
                )}
              </FormGroup>
            </div>
          </div>
          <ButtonRow>
            <button
              type="button"
              className="btn"
              onClick={handleSave}
              disabled={loading}
            >
              {loading ? "Chargement..." : "Sauvegarder"}
            </button>
          </ButtonRow>
        </FormContainer>
      </div>
    </div>
  );
};

export default ParametreCouleurs;
