import { useGetHotelByIdQuery } from "actions/createHotel/queries";
import React, { createContext, useCallback } from "react";
import { useParams } from "react-router-dom";

export const ConfigContext = createContext({});

export const ConfigProvider = ({ children }) => {
  const { hotelId } = useParams();
  const { data, isLoading, isError } = useGetHotelByIdQuery(hotelId);

  const handleUpdateHotel = useCallback(() => {
    console.log("update hotel");
  }, []);

  return (
    <ConfigContext.Provider
      value={{ hotel: { data, isLoading, isError }, handleUpdateHotel }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
