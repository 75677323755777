import {
  voyageurEndpoint,
  voyageurReservationEndpoint,
} from "routes/api/endpoints";
import { ApiMananger } from "./rtkquery";

export const voyageurApi = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    addNewVoyageur: builder.mutation({
      query: (voyageurData) => ({
        url: voyageurEndpoint.addNewVoyageur(),
        method: "POST",
        body: voyageurData,
      }),
    }),
    getVoyageurs: builder.query({
      query: (userId) => ({
        url: voyageurEndpoint.addNewVoyageur(),
        method: "GET",
        params: { user: userId },
      }),
    }),
    addNewVoyageurReservation: builder.mutation({
      query: (voyageurReservationData) => ({
        url: voyageurReservationEndpoint.addNewVoyageurReservation(),
        method: "POST",
        body: voyageurReservationData,
      }),
    }),
  }),
});

export const {
  useAddNewVoyageurReservationMutation,
  useAddNewVoyageurMutation,
  useGetVoyageursQuery,
} = voyageurApi;

export default voyageurApi;
