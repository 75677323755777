// HotelConfig.js
import React from "react";
import { Outlet } from "react-router-dom";
import { HotelName } from "../StyleCOnfigHotel";
import Tabs from "components/ConfigHotel/Tabs";
import useConfigHotel from "hooks/Hotel/useConfigHotel";

const navLinks = [
  {
    name: "Configuration de l'hôtel",
    path: "",
    subNavLinks: [
      { name: "Hotel", path: "configuration-hotel/hotel" },
      {
        name: "Paramètres expéditeur e-mails",
        path: "configuration-hotel/email-settings",
      },
      {
        name: "Gestion des notifications",
        path: "configuration-hotel/notifications",
      },
      {
        name: "Configuration avancée",
        path: "configuration-hotel/advanced-settings",
      },
    ],
  },
  {
    name: "Charte graphique",
    path: "charte-graphique",
    subNavLinks: [
      { name: "Identité visuelle", path: "charte-graphique/visual-identity" },
      {
        name: "Paramètres des réseaux sociaux",
        path: "charte-graphique/social-media-settings",
      },
    ],
  },
  {
    name: "E-mails",
    path: "emails",
    subNavLinks: [
      { name: "Activation e-mails", path: "emails/email-activation" },
      { name: "E-mails", path: "emails/list" },
      { name: "Signatures", path: "emails/signatures" },
      {
        name: "Avantages et codes de fidélité",
        path: "emails/loyalty-benefits-codes",
      },
    ],
  },
  {
    name: "Formulaires",
    path: "formulaires",
    subNavLinks: [
      { name: "Pre-Stay", path: "formulaires/form-pre-stay" },
      { name: "In-Stay", path: "formulaires/form-in-stay" },
      { name: "Post-Stay", path: "formulaires/form-post-stay" },
    ],
  },
];

const ConfigHotelHeaderLayout = () => {
  const { hotel } = useConfigHotel();

  return (
    <div className="d-flex flex-column">
      {hotel?.isLoading
        ? "Chargement en cours..."
        : hotel?.isError
          ? "Erreur survenue"
          : hotel?.data && (
            <div>
              <div className="d-flex justify-content-between align-items-start">
                <div>
                  <HotelName>{hotel?.data?.nom_hotel}</HotelName>
                </div>
              </div>
              <Tabs tabLabels={navLinks} />
            </div>
          )}
      <Outlet />
    </div>
  );
};

export default ConfigHotelHeaderLayout;
