import { ApiMananger } from "actions/rtkquery";

const preStayApi = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    addPreStay: builder.mutation({
      query: (serviceData) => ({
        url: "cofiguration/prestay/",
        method: "POST",
        body: serviceData,
      }),
    }),
    updatePreStay: builder.mutation({
      query: ({ id, serviceData }) => ({
        url: `${id}`,
        method: "PUT",
        body: serviceData,
      }),
    }),
    patchPreStay: builder.mutation({
      query: ({ id, serviceData }) => ({
        url: `${id}`,
        method: "PATCH",
        body: serviceData,
      }),
    }),
    deletePreStay: builder.mutation({
      query: (id) => ({
        url: `${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddPreStayMutation,
  useDeletePreStayMutation,
  usePatchPreStayMutation,
  useUpdatePreStayMutation,
} = preStayApi;
