import React from "react";

function CardDashboard({ number, title, pourcent }) {
  return (
    <div
      className="border rounded d-flex flex-column py-2 bg-white p-2"
      style={{
        fontFamily: "Roboto",
        flexBasis: "230px",
        maxWidth: "328px",
        height: "80px",
      }}
    >
      <span
        style={{ fontFamily: "Roboto", fontSize: "10px", color: "#71717A" }}
      >
        {title}
      </span>
      <div className="d-flex justify-content-between">
        <span
          className=""
          style={{
            fontFamily: "Plus Jakarta Sans",
            color: "#18181B",
            fontSize: "24px",
            marginTop: "15px",
          }}
        >
          {number}
        </span>
        <span
          className=""
          style={{
            fontSize: "15px",
            color: "green",
            marginTop: "20px",
            fontFamily: "Plus Jakarta Sans",
          }}
        >
          {pourcent}
        </span>
      </div>
    </div>
  );
}

export default CardDashboard;
