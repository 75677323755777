import React, { useState } from "react";
import { useGetHoteliersQuery } from "actions/createHotel/queries";
import { useDeleteHotelierMutation } from "actions/createHotel/mutations";
import {
  Th,
  Td, ContainInput,
  InputCheckbox,
} from "components/RelationClientDetail/InfoClient/InfoClientCSS";
import { useNavigate } from "react-router-dom";
import { LiaSearchSolid } from "react-icons/lia";
import "./Cadre.css";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { RiDeleteBin5Line } from "react-icons/ri";
import { HiDownload } from "react-icons/hi";
import { FiRefreshCcw } from "react-icons/fi";
import { toast, ToastContainer } from "react-toastify";
import Modal from "components/Modal";
import { formatDate } from "utils/date";
import { truncateText } from "utils/constants"

const CadreTableau = ({ data, search, setSearch }) => {
  const { data: listHotels, refetch } = useGetHoteliersQuery();
  const listHotel = listHotels?.data || [];
  const navigate = useNavigate();
  const [selectedHotelId, setSelectedHotelId] = useState(new Set());
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllChange = (event) => {
    const { checked } = event.target;
    setSelectAll(checked);

    if (checked) {
      const allHotelIds = new Set(listHotel.map((hotel) => hotel.id));
      setSelectedHotelId(allHotelIds);
    } else {
      // Désélectionner tous les hôtels
      setSelectedHotelId(new Set());
    }
  };

  const handleCheckboxChange = (id) => {
    const updatedSelectedHotelId = new Set(selectedHotelId);
    if (updatedSelectedHotelId.has(id)) {
      updatedSelectedHotelId.delete(id);
    } else {
      updatedSelectedHotelId.add(id);
    }
    setSelectedHotelId(updatedSelectedHotelId);
  };
  const handleModifierClick = () => {
    if (selectedHotelId.size === 1) {
      navigate(`/configuration-hotel/${Array.from(selectedHotelId)[0]}/`);
    }
  };
  const handleLienClick = (id) => {
    navigate(`/configuration-hotel/${id}/`);
  };
  const [deleteHotel] = useDeleteHotelierMutation();
  console.log({ deleteHotel });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleDeleteClick = async () => {
    const idsToDelete = Array.from(selectedHotelId);
    try {
      if (idsToDelete.length === 1) {
        // Supprimer un seul hôtel
        await deleteHotel(idsToDelete[0]);
      } else if (idsToDelete.length > 1) {
        // Supprimer plusieurs hôtels
        await Promise.all(idsToDelete.map((id) => deleteHotel(id)));
      }
      toast.success("La suppression a passé avec succès.");
      closeModal();
      refetch();
      setSelectedHotelId(new Set()); // Réinitialiser la sélection
      setSelectAll(false); // Réinitialiser la sélection tout
    } catch (error) {
      console.error("Erreur lors de la suppression :", error);
      toast.error("Une erreur est survenue lors de la suppression.");
    }
  };
  const countSelected = () => selectedHotelId.size;

  return (
    <div>
      <ToastContainer className={"mt-5"}/>
      <Modal opened={modalIsOpen} onClosed={closeModal} title={"Confirmation"}>
        <p>Es-tu sûr de vouloir supprimer cet hôtelier ?</p>
        <button onClick={handleDeleteClick} className="btn btn-danger m-2">Oui</button>
        <button onClick={closeModal} className="btn btn-secondary m-2">Non</button>
        </Modal>
      <div className="bg-body-tertiary">
      <div className="d-flex align-items-center justify-content-between px-3 py-2">
        <div className="d-flex align-items-baseline justify-content-between gap-4">
        <ContainInput className="bg-white border border-white rounded d-flex align-items-center gap-3">
              <input
                type="text"
                name="name"
                className="w-100 border border-0 no-focus"
                placeholder="Rechercher par un nom"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
               <button className="border border-0 bg-white">
                <LiaSearchSolid className="" />
              </button>
              </ContainInput>
              <div className=""><p className="mb-0">{countSelected()} sélectionné{countSelected() > 1 ? "s" : ""}</p></div>
              <div className="d-flex align-items-center">
                <HiOutlinePencilSquare/>
              <button className="border border-0 bg-body-tertiary" onClick={handleModifierClick} disabled={selectedHotelId.size !== 1}>
        Modifier
      </button>
              </div>
              <div className="d-flex align-items-center">
                <RiDeleteBin5Line/>
              <button onClick={openModal} disabled={selectedHotelId.size === 0} className="border border-0 bg-body-tertiary">
        Supprimer
      </button></div>
        </div>
        <div className="d-flex align-items-center gap-3">
          <button className="border border-0 bg-body-tertiary"><HiDownload/></button>
          <button className="border border-0 bg-body-tertiary"><FiRefreshCcw/></button>
        </div>
      </div>
      </div>
      <table className="tables">
        <thead className="table-light table">
          <tr>
            <Th className="bg-white">
              <InputCheckbox type="checkbox" id="select-all"
              checked={selectAll}
              onChange={handleSelectAllChange} />
            </Th>
            <Th className="bg-white">Hôtel</Th>
            <Th className="bg-white">Nom de l'hôtelier</Th>
            <Th className="bg-white">Date de création</Th>
            <Th className="bg-white">Numéro de téléphone</Th>
            <Th className="bg-white">E-mail</Th>
            <Th className="bg-white">Pays/Région</Th>
            <Th className="bg-white">Ville</Th>
          </tr>
        </thead>
        {/* {showTableBody && ( */}
        <tbody>
          {data && data.map((item) =>
            item.archived ? null : (
              <tr key={item?.id} onClick={() =>handleLienClick(item.id)} className="cursorPointer">
                <Td>
                  <InputCheckbox
                    type="checkbox"
                id={`hotel-${item.id}`}
                checked={selectedHotelId.has(item.id)}
                onChange={() => handleCheckboxChange(item.id)}
                onClick={(e) => e.stopPropagation()}
                  />
                </Td>
                <Td>{truncateText(item?.nom_hotel)}</Td>
                <Td>{truncateText(item?.hotelier) || "Non défini"}</Td>
                <Td>{formatDate(item?.created_at)}</Td>
                <Td>{item?.phone}</Td>
                <Td>{truncateText(item?.email)}</Td>
                <Td>{truncateText(item?.pays)}</Td>
                <Td>{truncateText(item?.ville)}</Td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CadreTableau;
