import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledText = styled.span`
  font-family: "Roboto", sans-serif;
  font-size: ${(props) => props.size || "18px"};
  font-weight: ${(props) => props.weight || "500"};
  margin-bottom: 0;
  word-wrap: break-word;
  word-break: break-word;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 11px;
  }
  color: ${(props) => props.color || "#000"};
`;

const Text = ({
  content,
  size,
  weight,
  color,
  contentLink,
  contentLinke,
  link,
  linke,
  colorLink,
}) => (
  <StyledText size={size} weight={weight} color={color}>
    {content}
    <a
      href={link}
      style={{ color: `${colorLink}` }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {contentLink}
    </a>
    <Link to={linke} style={{ color: `${colorLink}` }}>
      {contentLinke}
    </Link>
  </StyledText>
);

export default Text;
