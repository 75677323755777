import styled, { keyframes } from "styled-components";
import ImageFond from "image/Seconnecter.png";
export const ConnexionBody = styled.div`
  background-image: url(${ImageFond});
  background-repeat: no-repeat;
  background-size: cover;
`;
export const rotate = keyframes`
  0% {
    transform: rotateY(0deg);
    opacity: 1;
  }
  50% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  100% {
    transform: rotateY(360deg);
    opacity: 1;
  }
`;
export const ImageLogin = styled.div`
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-repeat: no-repeat;
  background-size: cover;
  width: 100% !important;
  height: 100vh !important;
`;

export const LogoForm = styled.img`

`;
export const ParaLogoForm = styled.p`
  margin-top: 15px;
  margin-right: 25px;
  font-size: 18px;
  font-weight: 700;
`;
