import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const LinkItem = styled(NavLink)`
  font-family: "Roboto"
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 13px;
  padding-left: 13px;
  padding-right: 13px;
  border-radius: 5px;
  height: 35px;
  width: auto;
  overflow: hidden;
  font-weight: 600;
  text-decoration: none;
  color: ${({ color }) => color};
  &:hover {
    color: ${({ coloronhover }) => coloronhover};
  }
  &.active {
    color: ${({ coloronactive }) => coloronactive};
    background-color: ${({ activelinkbgcolor }) => activelinkbgcolor};
  }
`;

const SidebarItem = ({
  linkItem,
  activeLinkColor,
  activeLinkBgColor,
  colorOnHover,
  linkColor,
}) => {
  const { path, label } = linkItem;
  const isReduire = useSelector(
    (state) => state?.persist?.sidebarWidth.isReduire,
  );
  return (
    <LinkItem
      color={linkColor}
      coloronhover={colorOnHover}
      coloronactive={activeLinkColor}
      activelinkbgcolor={activeLinkBgColor}
      to={path}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          gap: "25px",
        }}
      >
        <linkItem.icon size={24} className="icon" />
        {isReduire ? null : (
          <p
            className="m-0 p-0"
            style={{
              // left: 45,
              fontSize: 18,
            }}
          >
            {label}
          </p>
        )}
      </div>
    </LinkItem>
  );
};

export default SidebarItem;
