import React from "react";

const GestionClient = () => {
  return (
    <div>
      <p>Gestion client</p>
    </div>
  );
};

export default GestionClient;
