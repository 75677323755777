import { ApiMananger } from "actions/rtkquery";
import { emailEndpoint } from "routes/api/endpoints";

export const emailApi = ApiMananger.injectEndpoints({
    endpoints: (builder) => ({
        createEmail: builder.mutation({
            query: (emailData) => ({
                url: emailEndpoint.createEmail(),
                method: "POST",
                body: emailData
            })
        }),
        updateEmail: builder.mutation({
            query: ({ emailId, emailData }) => ({
                url: emailEndpoint.updateEmail(emailId),
                method: "PUT",
                body: emailData
            })
        }),
        deleteEmail: builder.mutation({
            query: (emailId) => ({
                url: emailEndpoint.deleteEmail(emailId),
                method: "DELETE"
            })
        })
    })
});

export const {
    useCreateEmailMutation,
    useUpdateEmailMutation,
    useDeleteEmailMutation
} = emailApi;
