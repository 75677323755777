import React, { useState, useEffect } from "react";
import { LuBellRing } from "react-icons/lu";
import styled, { keyframes, css } from "styled-components";
import LanguageDropdown from "./LanguageDropdown";
import AssistanceDropdown from "./AssistanceDropdown";
import HotelDropdown from "./HotelDropdown";
import hotele from "image/hotele.png";
import { SearchInput } from "components/HeaderListe/Navbarcss";

const Logo = styled.img`
  width: 125px;
  height: 45px;
`;

const ParaLogo = styled.p`
  color: #000000;
  margin-top: 15px;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 700;
`;

const swayAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const BellIcon = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  color: #000000;
  font-family: Roboto;
  font-weight: 400;
  cursor: pointer;
  ${(props) =>
    props.isFloating &&
    css`
      animation: ${swayAnimation} 2s ease-in-out infinite;
      transform-origin: top center;
    `}
`;

const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 55px;
  background-color: ${(props) => props.bgColor || "#FFC857"};
  font-size: 16px;
`;

const NavbarComponent = ({ bgColor }) => {
  const [valueSearch, setValueSearch] = useState("");
  const [lang, setLang] = useState("FR");
  const [nomHotel, setNomHotel] = useState(" Dakar");
  const [isFloating, setIsFloating] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFloating(true);
      setTimeout(() => {
        setIsFloating(false);
      }, 2000);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <NavbarContainer bgColor={bgColor}>
      <div style={{ display: "flex" }}>
        <div className="d-inline-flex align-items-center mb-2">
          {/* <Logo src={hotele} alt="Hotels" className="me-2 ms-1" /> */}
          <ParaLogo>
            <Logo src={hotele} alt="Hotels" className="me-5 ms-1" />
          </ParaLogo>
        </div>
        <div className="d-flex align-items-center ms-5">
          <SearchInput
            value={valueSearch}
            onChange={(e) => setValueSearch(e.target.value)}
            placeholder="Rechercher un client..."
          />
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <LanguageDropdown lang={lang} setLang={setLang} />
        <AssistanceDropdown />
        <BellIcon isFloating={isFloating}>
          <LuBellRing />
        </BellIcon>
        <HotelDropdown nomHotel={nomHotel} setNomHotel={setNomHotel} />
      </div>
    </NavbarContainer>
  );
};

export default NavbarComponent;
