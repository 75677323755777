import React from "react";
import Roots from "./routes/Routes";
// import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

function App() {
  return (
    <div>
      <Roots />
    </div>
  );
}

export default App;
