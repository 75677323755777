import { BsDisplayFill } from "react-icons/bs";
import { RiDashboardFill } from "react-icons/ri";
import { FaHandHolding } from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";
import { MdOutlineVpnLock } from "react-icons/md";
import { IoIosSettings } from "react-icons/io";
import {
  API_CONNECT,
  CONFIGURATION_HOTEL_PATH,
  DASHBOARD_PATH,
  GESTION_ENVOIE_PATH,
  GESTION_HOTELIER_PATH,
  PARAMETRE,
  REPORTING,
} from "./navigationPaths";
import { GrDocumentConfig } from "react-icons/gr";

/*
|--------------------------------------------------------------------------
! Sidebar Items
|--------------------------------------------------------------------------
|
| Ici se trouve tous les liens et icons qui doivent etre sur le sidebar
| de SplitShell
*/
export const linksNavebar = [
  { path: DASHBOARD_PATH, icon: RiDashboardFill, label: "Dashboard" },
  {
    path: CONFIGURATION_HOTEL_PATH,
    icon: GrDocumentConfig,
    label: "Configuration hôtel",
  },
  {
    path: GESTION_HOTELIER_PATH,
    icon: BsDisplayFill,
    label: "Gestion des hôtels",
  },
  {
    path: GESTION_ENVOIE_PATH,
    icon: FaHandHolding,
    label: "Gestion des envois",
  },
  { path: REPORTING, icon: GiTeacher, label: "Reporting" },
  { path: API_CONNECT, icon: MdOutlineVpnLock, label: "Connexion via API" },
  { path: PARAMETRE, icon: IoIosSettings, label: "Paramétre" },
];
export const links = [];
