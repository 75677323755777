export const OptionVenez = [
  { id: 0, name: "Vous venez .." },
  { id: 1, name: "Seul" },
  { id: 2, name: "En couple" },
  { id: 3, name: "En famille" },
  { id: 4, name: "En groupe d'amis" },
];

export const OptionReserve = [
  { id: 0, name: "Quelle est la raison de votre voyage ?" },
  { id: 1, name: "Visite familiale" },
  { id: 2, name: "Business" },
  { id: 3, name: "Voyage de noce" },
  { id: 4, name: "Voyage touristique" },
  { id: 5, name: "Autre" },
];

export const OptionConnu = [
  { id: 0, name: "Comment nous avez-vous connus ?" },
  { id: 1, name: "Bouche à oreille" },
  { id: 2, name: "Facebook" },
  { id: 3, name: "Instagram" },
  { id: 4, name: "Presse" },
  { id: 5, name: "Influenceur" },
  { id: 6, name: "Google" },
  { id: 7, name: "TripAdvisor" },
  { id: 8, name: "Partenaire de réservation : Booking, Expedia, Staycation" },
  { id: 9, name: "Autre" },
];
export const stateLink = [
  { id: 1, number: "01", texte: "À propos de vous" },
  { id: 2, number: "02", texte: "Votre séjour" },
  { id: 3, number: "03", texte: "Les services" },
  { id: 4, number: "04", texte: "Validation" },
];
