import React from "react";
import LineDemo from "./Graph/ChartLine";
import { styledRelationClientDetail } from "./RelationClientDetailPage/RelationClientDetailCss";
import { ContainerDemandeClient } from "components/RelationClientDetail/DemandeClient/DemandeClientCSS";
import styled from "styled-components";
import CardDashboard from "components/RelationClientDetail/MenuClientDetails/ReserveAndTotalCard";
import BarProgress from "./Graph/Progressbar";
import rapport from "image/Export.png";
import "./chart.css";
import { useGetHoteliersQuery } from "actions/createHotel/queries";

const Heading1 = styled.h1`
  font-size: 26px;
  font-family: Roboto;
  padding-top: 50px;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const DashboardRelation = () => {
  const { data } = useGetHoteliersQuery();

  // Accessing the hoteliers array from the response payload
  const hoteliers = data?.data || [];

  return (
    <div className="" style={styledRelationClientDetail.relationClient}>
      <ContainerDemandeClient className="p-1">
        <Heading1>Dashboard</Heading1>
        <ContainerDemandeClient className="container-fluid">
          <div
            className="row d-flex gap-2 p-0 mb-3"
            style={{ flexWrap: "wrap" }}
          >
            <CardDashboard
              flex={1}
              pourcent="30%"
              number={hoteliers.length}
              title={"HOTELIERS"}
            />
            <CardDashboard
              flex={1}
              pourcent="30%"
              number="25.580.460"
              title={"ROI"}
            />
            <CardDashboard
              flex={1}
              pourcent="30%"
              number="24"
              title={"E-MAILS CREES"}
            />
            <CardDashboard
              flex={1}
              pourcent="30%"
              number="33"
              title={"MESSAGES CREES"}
            />
            <CardDashboard
              flex={1}
              pourcent="30%"
              number="9"
              title={"FORMULAIRES CREES"}
            />
          </div>
          <div className="row mb-5">
            <div
              className="card col-12 col-md-8 py-2 mb-5"
              style={{ height: "370px", width: "64%" }}
            >
              <div className="d-flex justify-content-between">
                <div className="py-1 text-center">
                  <p className="rapport">Rapport des Roi</p>
                </div>
                <div className="d-flex">
                  <div
                    className="py-1 px-2 text-center chart"
                    style={{
                      border: "1px solid #D4D4D8",
                      height: "30px",
                      borderRadius: "8px",
                    }}
                  >
                    12 mois
                  </div>
                  <div className="py-1 px-2 text-center">6 mois</div>
                  <div className="py-1 px-2 text-center">30 jours</div>
                  <div className="py-1 px-2 text-center">7 jours</div>
                  <div className="py-1 px-2 text-center">1 jours</div>
                  <div className="py-1 px-2 text-center">
                    <img src={rapport} style={{ width: "90px" }} />
                  </div>
                </div>
              </div>
              <LineDemo />
            </div>
            <div
              className="card col-12 col-md-4 py-2"
              style={{ height: "370px", marginLeft: "20px", borderRadius: "7" }}
            >
              <div className="d-flex justify-content-between mb-3">
                <div className="py-1 text-center">
                  <h6>Roi par entité</h6>
                </div>
                <div className="py-1 text-center">
                  <select
                    style={{
                      border: "none",
                      outline: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <option value="7days">Les 7 derniers jours</option>
                    <option value="30days">Les 30 derniers jours</option>
                    <option value="90days">Les 90 derniers jours</option>
                  </select>
                </div>
              </div>
              <BarProgress
                value={80}
                nomHotel="Azalai Hotel"
                prix="12.143.382 FXOF"
              />
              <BarProgress
                value={70}
                nomHotel="Terrou-bi"
                prix="12.143.382 FXOF"
              />
              <BarProgress
                value={40}
                nomHotel="Les Iles du Saloum"
                prix="12.143.382 FXOF"
              />
              <BarProgress
                value={20}
                nomHotel="King Fahd Place"
                prix="12.143.382 FXOF"
              />
            </div>
          </div>
        </ContainerDemandeClient>
      </ContainerDemandeClient>
    </div>
  );
};

export default DashboardRelation;
