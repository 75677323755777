import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utilise la fonction useForm de react-hook-form pour configurer les méthodes du formulaire.
const connexionSchema = yup.object().shape({
  email: yup.string().email("Email invalide!").required("L'email est requis."),
  password: yup.string().required("Le mot de passe est requis."),
});

const ConnexionProvider = ({ children }) => {
  const methods = useForm({
    values: {
      email: "",
      password: "",
    },
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(connexionSchema),
  });
  // Enveloppe les composants enfants dans le contexte du formulaire (FormProvider).
  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default ConnexionProvider;
