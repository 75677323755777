// HotelConfig.js
import HotelConfigLinks from "components/ConfigHotel/HotelConfigLinks";
import React from "react";
import { Outlet } from "react-router-dom";

const navLinks = [
  { name: "Identité visuelle", path: "" },
  {
    name: "Paramètres des réseaux sociaux",
    path: "social-media-settings",
  },
];

const CharteGraphiqueLayout = () => {
  return (
    <div className="d-flex flex-column">
      <HotelConfigLinks links={navLinks} />
      <Outlet />
    </div>
  );
};

export default CharteGraphiqueLayout;
