// HotelConfig.js
import HotelConfigLinks from "components/ConfigHotel/HotelConfigLinks";
import React from "react";
import { Outlet } from "react-router-dom";

const navLinks = [
  { name: "Pre-Stay", path: "" },
  { name: "In-Stay", path: "form-in-stay" },
  { name: "Post-Stay", path: "form-post-stay" },
];

const FormulaireLayout = () => {
  return (
    <div className="d-flex flex-column">
      <HotelConfigLinks links={navLinks} />
      <Outlet />
    </div>
  );
};

export default FormulaireLayout;
