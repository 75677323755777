import { Link } from "react-router-dom";
import React, { useState } from "react";
import "./form.css";
import hotels from "../../image/hotels.png";
// import { sendPasswordResetEmail } from "firebase/auth";
import "react-toastify/dist/ReactToastify.css";
// import { auth } from "../../firebase";
import "react-toastify/dist/ReactToastify.css";
import Input from "components/Inputs/Input";
import "./form.css";

const ErrorPasswordForm = () => {
  const [loading] = useState(false);
  const [email, setEmail] = useState("");
  // Assurez-vous de remplacer par le bon contexte d'authentification

  const handleSubmit = async (e) => {
    e.preventDefault();

    // try {
    //   setLoading(true);
    //   await sendPasswordResetEmail(auth, email);

    //   // Succès - Afficher un message ou rediriger vers une page de confirmation
    //   console.log(
    //     "E-mail de réinitialisation du mot de passe envoyé avec succès!"
    //   );
    // } catch (error) {
    //   // Erreur - Afficher un message d'erreur ou gérer l'erreur selon vos besoins
    //   console.error(
    //     "Erreur lors de l'envoi de l'e-mail de réinitialisation du mot de passe:",
    //     error.message
    //   );
    // } finally {
    //   setLoading(false);
    // }
  };

  return (
    <>
      <div className="containerrr">
        <div className="">
          <div className="d-flex flex-column gap-4 align-items-center justify-content-center">
            <div className="mb-3">
              {" "}
              <img src={hotels} />
            </div>
            <form
              className="auth-forms MotDePasse p-4 mx-0 mx-lg-5"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <div
                className="d-flex flex-column gap-3"
                style={{ padding: " 20px" }}
              >
                <div>
                  <h3
                    style={{
                      color: "rgba(0, 0, 0, 0.87)",
                      fontFamily: "Roboto",
                      fontSize: " 18.67px",
                      fontWeight: "400",
                    }}
                  >
                    Mot de passe oublié?
                  </h3>
                </div>
                <div>
                  <p
                    style={{
                      color: "rgba(0, 0, 0, 0.87)",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    Entrez votre adresse e-mail ci-dessous et nous vous envoyons
                    des instructions sur la façon de modifier votre mot de
                    passe.
                  </p>
                </div>
                <div>
                  <Input
                    label="Votre e-mail"
                    className="input-form border-2 border-bottom border-light-subtle"
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    style={{ width: "100%" }}
                  />
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    className="w-100 "
                    style={{
                      backgroundColor: "#9F5540",
                      borderRadius: "5.333px",
                      padding: "12px 0px",
                      border: "none",
                      fontSize: "21.33px",
                    }}
                  >
                    <span style={{ color: "#FFF" }}>
                      {loading ? "Chargement..." : "Envoyer"}
                    </span>
                  </button>
                </div>
              </div>
            </form>
            <div>
              <div
                style={{
                  color: "#FFD964",
                }}
                className="text-center"
              >
                {/* <span style={{ flex: '1' }}>Vous n&apos;avez pas de compte?</span> */}
                <Link
                  to="/"
                  style={{ textDecoration: "none", fontSize: "18.667px" }}
                >
                  <span style={{ color: "#FFD964" }}>
                    {" "}
                    Revenir à la connexion
                  </span>
                </Link>
              </div>
            </div>
            {/* <hr /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPasswordForm;
