import React, { useEffect, useRef, useState } from "react";
import CustomButton from "components/Boutton/CustomButton";
import { MdOutlineFileDownload } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import CadreTableau from "./CadreTableau";
import ImportModal from "./ImportModal";
import { useGetHoteliersQuery } from "actions/createHotel/queries";
import { useSearchParams } from "react-router-dom";
import {
  CadreHeader,
  CadreHeader2,
  CadreTab,
  GrpBtn,
  Icone,
  InputSelect,
  InputSelectDate,
  SpanIcon,
  ContainSelectData,
  TextBut,
} from "./GestionVoyageurCss";
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
import styled from "styled-components";
import {
  ContainerDemandeClient,
  Paragraphe,
  TextList,
} from "components/RelationClientDetail/DemandeClient/DemandeClientCSS";
import AddHotelModal from "../HotelConfig/Hotel/AddHotelModal";
import { formatDate } from "utils/date";
import { PiCalendarBold } from "react-icons/pi";
import { PaginationFiltre, Pagination } from "components/FormulairePreStay/Pagination";

const Heading1 = styled.h1`
  font-size: 32px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #000000de;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const GestionHotelier = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalIsOpen] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const dropdownRef = useRef(null);

  const openModal = () => {
    setModalOpened(true);
  };

  const closeModal = () => {
    setModalOpened(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { data: listHotels } = useGetHoteliersQuery();
  const listHotel = listHotels?.data || [];

  // Lire les paramètres de recherche depuis l'URL
  const filterName = searchParams.get("name") || "";
  const filterDate = searchParams.get("date") || "";
  const filterCity = searchParams.get("city") || "";
  const filterCountry = searchParams.get("country") || "";
  const search = searchParams.get("search") || "";
  const sortedVoyageurs = [...listHotel].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );
  const filteredHotels = sortedVoyageurs.filter((hotel) => {
    return (
      (!search || hotel.nom_hotel.toLowerCase().includes(search.toLowerCase())) &&
      (!filterName || hotel.nom_hotel.toLowerCase().includes(filterName.toLowerCase())) &&
      (!filterDate || hotel.created_at === filterDate) &&
      (!filterCity || hotel.ville.toLowerCase().includes(filterCity.toLowerCase())) &&
      (!filterCountry || hotel.pays.toLowerCase().includes(filterCountry.toLowerCase()))
    );
  });

  const handleFilterChange = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set(key, value);
    } else {
      newSearchParams.delete(key);
    }
    setSearchParams(newSearchParams);
  }
  const page = parseInt(searchParams.get("page")) || 1;
  const limit = parseInt(searchParams.get("limit")) || 5;
  const startIndex = (page - 1) * limit;
  const endIndex = startIndex + limit;
  const paginatedHotels = filteredHotels.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredHotels.length / limit);
  const handlePageChange = (newPage) => {
    handleFilterChange("page", newPage);
  };

  return (
    <div className="" style={styledRelationClientDetail.relationClient}>
      <AddHotelModal opened={modalOpened} onClosed={closeModal} />
      <ContainerDemandeClient className="">
        <ContainerDemandeClient className="">
          <CadreHeader className="row justify-content-between align-items-center">
            <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12">
              <Heading1 className="m-0 pb-0">Gestion des hôtels</Heading1>
              <Paragraphe>Retrouvez tous vos hôtels ici</Paragraphe>
            </div>
            <GrpBtn className="col-md-6 col-lg-6 col-xl-6 col-sm-12 align-items-center justify-content-end">
              <CustomButton
                className="gap-1"
                padding="10px 20px"
                fontSize="20px"
                fontWeight={"500"}
                fontFamily="Roboto"
                borderRadius="50px"
                border="1px solid #FFC857"
                backgroundColor={"white"}
              >
                <Icone>
                  <MdOutlineFileDownload />
                </Icone>
                <TextBut>Importer</TextBut>
              </CustomButton>
              <CustomButton
                className="gap-1"
                padding="10px 20px"
                fontSize="20px"
                fontWeight={"500"}
                fontFamily="Roboto"
                borderRadius="50px"
                border="none"
                backgroundColor="#FFC857"
                onClick={openModal}
              >
                <Icone>
                  <FaPlus />
                </Icone>
                <TextBut>Nouvel Hôtel</TextBut>
              </CustomButton>
            </GrpBtn>
          </CadreHeader>
          <div className="border-bottom border-grey">
            <CustomButton
              backgroundColor={"#E76F51"}
              padding={"5px 17px"}
              border={"solid #E76F51"}
            >
              <TextList className="mb-0 pb-0 text-white">
                Liste de tous mes hôtels
              </TextList>
            </CustomButton>
          </div>
          <CadreHeader2>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-between align-items-center gap-1">
                <div style={{ fontWeight: "500" }} className="">
                  Filtrer par
                </div>
                <InputSelect
                  value={filterName}
                  onChange={(e) => handleFilterChange("name", e.target.value)}
                >
                  <option value="">Hôtel</option>
                  {listHotel.map((item) => (
                    <option key={item.id} value={item.nom_hotel}>
                      {item.nom_hotel}
                    </option>
                  ))}
                </InputSelect>
                <ContainSelectData>
                  <InputSelectDate
                    value={filterDate}
                    onChange={(e) => handleFilterChange("date", e.target.value)}
                  >
                    <option value="">Date de création</option>
                    {listHotel.map((item) => (
                      <option key={item.id} value={item.created_at}>
                        {formatDate(item.created_at)}
                      </option>
                    ))}
                  </InputSelectDate>
                  <SpanIcon>
                    <PiCalendarBold className=""/>
                  </SpanIcon>
                </ContainSelectData>
                <InputSelect
                  value={filterCountry}
                  onChange={(e) => handleFilterChange("country", e.target.value)}
                >
                  <option value="">Pays/Région</option>
                  {listHotel.map((item) => (
                    <option key={item.id} value={item.pays}>
                      {item.pays}
                    </option>
                  ))}
                </InputSelect>
                <InputSelect
                  value={filterCity}
                  onChange={(e) => handleFilterChange("city", e.target.value)}
                >
                  <option value="">Ville</option>
                  {listHotel.map((item) => (
                    <option key={item.id} value={item.ville}>
                      {item.ville}
                    </option>
                  ))}
                </InputSelect>
              </div>
              <PaginationFiltre
              onChange={(e) => handleFilterChange("limit", e.target.value)}
              limit={limit}
              />
            </div>
          </CadreHeader2>
          <CadreTab>
          <CadreTableau data={paginatedHotels} search={search} setSearch={(value) => handleFilterChange("search", value)} />
          </CadreTab>
          <Pagination totalPages={totalPages} currentPage={page} onPageChange={handlePageChange}/>
          <ImportModal isOpen={modalIsOpen} onRequestClose={closeModal} />
        </ContainerDemandeClient>
      </ContainerDemandeClient>
    </div>
  );
};

export default GestionHotelier;