const COLORS = {
  primary: "#E76F51",
  secondary: "#3498DB",
  accent: "#F39C12",
  text: "#333333",
  background: "#F5F5F5",
  secondaryBackground: "#6C5B7B",
  tertiaryBackground: "#EAECF0",
  primaryBorderColor: "#AEAEAE",
  activeColor: "#FFC857",
  white: "#fff",
};

export default COLORS;
