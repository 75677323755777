import React, { useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { OptionVenez, OptionReserve, OptionConnu } from "./data";
import Text from "components/Text";
import BoutonNeomorphisme from "components/BoutonNeomorphisme";
import clockSrc from "image/clock.png";
import calendarSrc from "image/calendar.png";
import { useNavigate } from "react-router-dom";
import { FORME2_PATH } from "routes/navigation/navigationPaths";
import Button from "./Bouton";

const Container = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: start;
  flex-direction: column;
  gap: 16px;
`;
const IconContainer = styled.div`
  border-radius: 10px;
  border: 2px solid #00000020;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Select = styled.select`
  border: none;
  width: 100%;
  outline: none;
  box-shadow: none;
  color: #212529;
`;
const CustomInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0px 100px 0px 0px;
`;
const NeumorphicButtonContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 5px;
  gap: 10px;
  margin-right: 10%;
`;
const ResponseText = styled.span`
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
`;
const Img = styled.img`
  width: 4%;
`;

const Propos = () => {
  const [startDate, setStartDate] = useState(null);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const toggleButton = () => {
    setIsButtonActive((prev) => !prev);
  };
  const [selectedTime, setSelectedTime] = useState(null);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(FORME2_PATH);
  };

  return (
    <Container>
      <IconContainer>
        <Select>
          {OptionVenez.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Select>
      </IconContainer>
      <IconContainer>
        <Select>
          {OptionReserve.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Select>
      </IconContainer>
      <IconContainer>
        <Select>
          {OptionConnu.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Select>
      </IconContainer>
      <NeumorphicButtonContainer>
        <div>
          <Text
            color="#555555"
            size={"16px"}
            content="Avez-vous l'habitude de venir à Dakar ?"
          />
        </div>
        <BoutonNeomorphisme onClick={toggleButton} active={isButtonActive} />
        <ResponseText>{isButtonActive ? "Oui" : "Non"}</ResponseText>{" "}
        {/* Change le texte en fonction de l'état */}
      </NeumorphicButtonContainer>
      <IconContainer>
        <DatePicker
          selected={startDate}
          placeholderText="Indiquez votre date de naissance"
          onChange={(date) => setStartDate(date)}
          customInput={<CustomInput />}
        />
        <Img src={calendarSrc} />
      </IconContainer>
      <IconContainer>
        <DatePicker
          selected={selectedTime}
          onChange={(date) => setSelectedTime(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={1} // Intervalles de 15 minutes
          timeFormat="HH:mm"
          dateFormat="HH:mm"
          placeholderText="Indiquez votre horaire d'arrivée estimée"
          customInput={<CustomInput />}
        />
        <Img src={clockSrc} />
      </IconContainer>
      <Button onClick={handleClick} name="Suivant" />
    </Container>
  );
};

export default Propos;
