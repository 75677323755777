import styled from "styled-components";

export const FormContainer = styled.div`
  padding: 20px 0px;
  width: 100%;
  margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
  width: fit-content;
  background-color: #60506d;
  color: #fff;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
`;
export const ButtonDelete = styled.div`
  width: 100%;
  color: #ff0a0a;
  border-radius: 13px;
  padding: 15px;
  text-align: center;
`;
