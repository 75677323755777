import { ApiMananger } from "actions/rtkquery";
import { HotelierEndpoint } from "routes/api/endpoints";

export const hotelierApi = ApiMananger.injectEndpoints({
  endpoints: (builder) => ({
    addNewHotelier: builder.mutation({
      query: (hotelierData) => ({
        url: HotelierEndpoint.addNewHotelier(),
        method: "POST",
        body: hotelierData,
      }),
      invalidatesTags: ["HOTELIERS"],
    }),
    updateHotelier: builder.mutation({
      query: ({ id, hotelierData }) => ({
        url: HotelierEndpoint.updateHotelier(id),
        method: "PUT",
        body: hotelierData,
      }),
      invalidatesTags: ["HOTELIERS"],
    }),
    deleteHotelier: builder.mutation({
      query: (id) => ({
        url: HotelierEndpoint.deleteHotelier(id),
        method: "DELETE",
      }),
      invalidatesTags: ["HOTELIERS"],
    }),
  }),
});

export const {
  useAddNewHotelierMutation,
  useUpdateHotelierMutation,
  useDeleteHotelierMutation,
} = hotelierApi;
