import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import { NavbarContainer } from "./Navbarcss";

const Navbar = ({ links }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <NavbarContainer className="row navbar-container px-2 pe-4">
      <div className="navbar">
        {links.map((link, index) => (
          <NavLink
            key={index}
            to={link.path}
            className={`nav-item ${activeIndex === index ? "active" : ""}`}
            onClick={() => handleItemClick(index)}
          >
            {link.label}
          </NavLink>
        ))}
      </div>
    </NavbarContainer>
  );
};

Navbar.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Navbar;
