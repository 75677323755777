import React from "react";
import Cardcomponent from "components/Cartegraphic/Cardcomponent";
import CouleurComponent from "components/Cartegraphic/CouleurComponent";
import ParametreCouleurs from "components/Cartegraphic/ParametreCouleurs";
import PhotoComponent from "components/Cartegraphic/PhotoComponent";
import {
  FormContainer,
  FormTitle,
} from "../HotelConfig/Hotel/StyledHotel";
const CharteGraphique = ({ selectedHotel }) => {
  return (
    <FormContainer>
      <FormTitle>Identité visuelle</FormTitle>
      <div className="mb-4">
        <Cardcomponent
          selectedHotel={selectedHotel}
        />
      </div>
      <div className="mb-4">
        <CouleurComponent selectedHotel={selectedHotel} />
      </div>
      <div className="mb-4">
        <PhotoComponent selectedHotel={selectedHotel} />
      </div>
      <div className="mb-4">
        <ParametreCouleurs selectedHotel={selectedHotel} />
      </div>
    </FormContainer>
  );
};

export default CharteGraphique;
