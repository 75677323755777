// HotelConfig.js
import HotelConfigLinks from "components/ConfigHotel/HotelConfigLinks";
import React from "react";
import { Outlet } from "react-router-dom";

const navLinks = [
  { name: "Hotel", path: "" },
  {
    name: "Paramètres expéditeur e-mails",
    path: "parametre-emails",
  },
  {
    name: "Gestion des notifications",
    path: "notifications",
  },
  {
    name: "Configuration avancée",
    path: "configuration-avance",
  },
];

const ConfigurationHotelLayout = () => {
  return (
    <div className="d-flex flex-column">
      <HotelConfigLinks links={navLinks} />
      <Outlet />
    </div>
  );
};

export default ConfigurationHotelLayout;
