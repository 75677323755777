import React from "react";
import {
  CardContainer,
  CardHeader,
  CardContent,
  CardActions,
} from "./StyledCard";

const Card = ({
  title,
  children,
  description,
  actions,
  notice,
  notice2,
  noticeColor,
  textColor,
  listItem,
  listItem2,
}) => {
  return (
    <CardContainer>
      <CardHeader>{title}</CardHeader>
      {description && <p className="p-0 m-0">{description}</p>}
      {notice && (
        <div
          style={{
            backgroundColor: noticeColor,
            color: textColor,
            padding: "10px",
            margin: "20px 0px",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontWeight: 400,
          }}
        >
          <span>{notice}</span>
          <span>{notice2}</span>
          {listItem && (
            <ul>
              <li>{listItem}</li>
              <li>{listItem2}</li>
            </ul>
          )}
        </div>
      )}
      <CardContent>{children}</CardContent>
      <CardActions>{actions}</CardActions>
    </CardContainer>
  );
};

export default Card;
