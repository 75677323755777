import React, { useEffect, useState } from "react";
import {
  ButtonRow,
  FormContainer,
  FormGroup,
  FormRow,
  FormTitle,
  Input,
  Label,
} from "pages/Admin/DashboardAdmin/HotelConfig/Hotel/StyledHotel";
import { useParams } from "react-router-dom";
import { useUpdateCharteGraphiqueMutation } from "actions/CharteGraphic/mutations";
import { toast } from "react-toastify";

const ParametreReseauxSociaux = ({ selectedHotel }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    instagram: selectedHotel?.chart_graphique?.instagram,
    facebook: selectedHotel?.chart_graphique?.facebook,
    twitter: selectedHotel?.chart_graphique?.twitter,
    linkedin: selectedHotel?.chart_graphique?.linkedin
  });

  const [updateCharteGraphique, { data, error }] =
    useUpdateCharteGraphiqueMutation();

  useEffect(() => {
    if (
      selectedHotel &&
      selectedHotel.id === parseInt(id) &&
      selectedHotel.chart_graphique
    ) {
      setFormData({
        instagram: selectedHotel.chart_graphique.instagram || "",
        facebook: selectedHotel.chart_graphique.facebook || "",
        twitter: selectedHotel.chart_graphique.twitter || "",
        linkedin: selectedHotel.chart_graphique.linkedin || "",
      });
    }
  }, [selectedHotel, id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(name, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    })

    try {
      const chartGraphiqueId = selectedHotel?.chart_graphique?.id;
      if (!chartGraphiqueId) throw new Error("Chart Graphique ID not found.");

      await updateCharteGraphique({
        id: chartGraphiqueId,
        hotelierData: formDataToSend,
      });
      toast.success("Charte graphique modified successfully");
    } catch (error) {
      toast.error(
        "Error updating charte graphique: " +
        (error.message || "Unknown error"),
      );
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <FormContainer>
        <FormTitle>Paramètres des réseaux sociaux</FormTitle>
        <FormRow>
          <FormGroup>
            <Label>Lien Facebook</Label>
            <Input
              type="text"
              name="facebook"
              value={formData.facebook}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Lien Twitter</Label>
            <Input
              type="text"
              name="twitter"
              value={formData.twitter}
              onChange={handleInputChange}
            />
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <Label>Lien Instagram</Label>
            <Input
              type="text"
              name="instagram"
              value={formData.instagram}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Lien LinkedIn</Label>
            <Input
              type="text"
              name="linkedin"
              value={formData?.linkedin}
              onChange={handleInputChange}
            />
          </FormGroup>
        </FormRow>
        <ButtonRow>
          <button
            type="button"
            className="btn"
            onClick={() => {
              // Handle cancel action
            }}
          >
            Annuler
          </button>
          <button type="submit" className="btn" disabled={loading}>
            {loading ? "Chargement..." : "Sauvegarder"}
          </button>
        </ButtonRow>
        {error && <div className="d-none">Error: {error.message}</div>}
        {data && <div className="d-none">Updated successfully!</div>}
      </FormContainer>
    </form>
  );
};

export default ParametreReseauxSociaux;
