import styled from "styled-components";
import COLORS from "utils/Variables/color";

export const FormContainer = styled.div`
  padding: 20px 0px;
  width: 100%;
  margin-bottom: 20px;
`;

export const FormTitle = styled.p`
  font-size: 30px;
  margin: 0;
  padding: 12px 0px;
  font-weight: 400;
  font-family: Roboto;
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FormGroup = styled.div`
  flex: 0 0 48%;
  margin-bottom: 20px;
`;
export const FormGroupFull = styled.div`
  flex: 0 0 100%;
`;
export const ObjectFormGroupFull = styled.div`
  flex: 0 0 78%;
`;
export const LangueFormGroupFull = styled.div`
  flex: 0 0 20%;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #555555;
  font-size: 14px;
  font-family: Roboto;
`;
export const LabelDescription = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: 400;
  color: #555555;
  font-size: 16px;
  font-style: italic;
  font-family: Roboto;
`;

export const Input = styled.input`
  width: 100%;
  height: 42px;
  padding: 8px;
  border: 1.3px solid #dddddd;
  border-radius: 30px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
`;
export const DropdownInput = styled.select`
  width: 100%;
  padding: 8px;
  height: 42px;
  border: 1.3px solid #dddddd;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;
  font-family: Roboto;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    border-color: none;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  gap: 10px;

  & > button {
    border-radius: 45px;
    padding: 5px 20px;
    font-size: 16px;
    font-weight: 500;
    font-family: Roboto;
    color: black;
  }
  & > button:first-child {
    background-color: ${COLORS.white};
    border-color: ${COLORS.activeColor};
  }
  & > button:last-child {
    background-color: ${COLORS.activeColor};
    border-color: ${COLORS.activeColor};
  }
  &:hover {
    opacity: 0.9;
  }
`;

export const Div = styled.div`
  padding: 8px 40px;
  width: fit-content;
  background: #e76f51;
  color: #fff;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  font-weight: 700;
  font-size: 15px;
`;
