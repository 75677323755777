import React from "react";
import styled from "styled-components";

const NeumorphicButton = styled.button`
  background: ${(props) =>
    props.active ? "#4CAF50" : "#e0e0e0"}; /* Vert si actif, gris sinon */
  border: none;
  border-radius: 20px;
  width: 40px;
  height: 25px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.active ? "flex-end" : "flex-start")};
  padding: 0 4px;
`;

const SmallButton = styled.div`
  width: 18px;
  height: 18px;
  border: none;
  border-radius: 50%;
  background: white;
  transition: all 0.3s ease;
`;

const BoutonNeomorphisme = ({ onClick, active }) => {
  return (
    <NeumorphicButton onClick={onClick} active={active}>
      <SmallButton />
    </NeumorphicButton>
  );
};

export default BoutonNeomorphisme;
