import React from "react";
import Apropos from "components/FormulairePreStay/Apropos";
import Titre from "components/Titre";
import {
  FORME2_PATH,
  FORME3_PATH,
  FORME4_PATH,
  FORME_PATH,
} from "routes/navigation/navigationPaths";
import LinkState from "components/FormulairePreStay/LinkState";

function Formulaire1() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "25px",
        }}
      >
        <LinkState
          number={"01"}
          texte={"À propos de vous"}
          color={"orange"}
          Link={FORME_PATH}
        />
        <LinkState number={"02"} texte={"Votre séjour"} Link={FORME2_PATH} />
        <LinkState number={"03"} texte={"Les services"} Link={FORME3_PATH} />
        <LinkState number={"04"} texte={"Validation"} Link={FORME4_PATH} />
      </div>
      <Titre
        text="À propos de vous"
        style={{
          fontFamily: "Roboto",
          fontSize: "28px",
          fontWeight: 600,
        }}
      />
      <Apropos />
    </div>
  );
}

export default Formulaire1;
