import { createSlice } from "@reduxjs/toolkit";

export const splitShellSlice = createSlice({
  name: "sidebarWidth",
  initialState: {
    navbarPanerWidth: "99%",
    width: "22%",
    navbarWidth: "77.5%",
    outletWidth: "80%",
    isReduire: false,
    isHover: false,
  },
  reducers: {
    setIsReduire: (state, action) => {
      state.isReduire = action.payload;
    },
    setIsHover: (state, action) => {
      state.isHover = action.payload;
    },
    setNavbarPanerWidth: (state, action) => {
      state.navbarPanerWidth = action.payload;
    },
    setSidebarWidth: (state, action) => {
      state.width = action.payload;
    },
    setNavbarWidth: (state, action) => {
      state.navbarWidth = action.payload;
    },
    setOutletWidth: (state, action) => {
      state.outletWidth = action.payload;
    },
  },
});

export const {
  setOutletWidth,
  setIsHover,
  setNavbarPanerWidth,
  setNavbarWidth,
  setSidebarWidth,
  setIsReduire,
} = splitShellSlice.actions;
