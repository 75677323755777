import React, { useState } from "react";
import Navbar from "layouts/navbar/Navbar";
import Footer from "layouts/Footer/Footer";
import { Outlet } from "react-router-dom";
import logoSrc from "image/LogoAzalai.png";

function SplitShellForm() {
  const [lang, setLang] = useState("Français");
  return (
    <div>
      <Navbar
        logoSrc={logoSrc}
        setLang={setLang}
        lang={lang}
        link={"accueilForm"}
      />
      <div style={{ paddingBottom: "4%" }}></div>
      <Outlet />
      <Footer />
    </div>
  );
}

export default SplitShellForm;
