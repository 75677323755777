import React from "react";
import {
  ContainerAdminTextPrep,
  ContentTextPrep,
  SidebarTextPrep,
  ContenuTextPrep,
} from "../../StyledAdmin";
import SidebarTextPrepares from "components/AdminTextPrepares/TextesPrepares/SidebarTextPrepares";
import { Outlet, useLocation } from "react-router-dom";
import {
  TEXTESPREPARES_NEW,
  GESTION_ENVOIE_PATH,
} from "routes/navigation/navigationPaths";
import styled from "styled-components";
import { styledRelationClientDetail } from "pages/RelationClient/DashboardRelationClient/RelationClientDetailPage/RelationClientDetailCss";
const Heading1 = styled.h1`
  font-size: 26px;
  font-family: Roboto;
  padding-top: 50px;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
function TextesPrepares() {
  const location = useLocation();
  return (
    <div className="" style={styledRelationClientDetail.relationClient}>
      <ContentTextPrep className="container-fluid">
        <Heading1 className="ms-4">
          {location.pathname === `/${GESTION_ENVOIE_PATH}`
            ? "Textes préparés"
            : location.pathname ===
                `/${GESTION_ENVOIE_PATH}/${TEXTESPREPARES_NEW}`
              ? "Nouveau Email"
              : ""}
        </Heading1>
        <ContainerAdminTextPrep className="container mb-3 pb-5">
          <div className="row">
            <div className="row">
              <SidebarTextPrep
                className="col-md-3 py-3"
                style={{ borderRadius: "14px" }}
              >
                <SidebarTextPrepares />
              </SidebarTextPrep>
              <ContenuTextPrep className="col-md-9">
                <Outlet />
              </ContenuTextPrep>
            </div>
          </div>
        </ContainerAdminTextPrep>
      </ContentTextPrep>
    </div>
  );
}

export default TextesPrepares;
