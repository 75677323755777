import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { FaTrashAlt, FaCopy } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  FormTitle,
  ButtonRow,
  Div,
} from "pages/Admin/DashboardAdmin/HotelConfig/Hotel/StyledHotel";
import ButtonFormulaire from "./ButtonFormulaire";
import { useAddFormulaireMutation } from "actions/Formulaires/mutations";
import { ButtonDelete } from "./FormulaireStyled";
import { useNavigate } from "react-router-dom";
import { GoInbox } from "react-icons/go";

const styles = {
  sectionContainer: {
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
  },
  questionContainer: {
    marginBottom: "16px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
  },
  questionTop: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  optionContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  input: {
    width: "100%",
    padding: "8px",
    marginBottom: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  iconButton: {
    marginLeft: "8px",
    cursor: "pointer",
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: "8px",
  },
};

const FormBuilder = ({ hotelId }) => {
  const [addFormulaire, { isLoading, isError, isSuccess }] =
    useAddFormulaireMutation();
  const [sections, setSections] = useState([
    { id: uuid(), title: "", questions: [] }, // Default section
  ]);
  const [showResponse, setShowResponse] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (sections.length > 0) {
      localStorage.setItem("forms", JSON.stringify(sections));
    }
  }, [sections]);

  const addSection = () => {
    setSections([...sections, { id: uuid(), title: "", questions: [] }]);
  };

  const handleSectionTitleChange = (id, title) => {
    setSections(
      sections.map((section) =>
        section.id === id ? { ...section, title } : section,
      ),
    );
  };

  const addQuestion = (sectionId) => {
    setSections(
      sections.map((section) =>
        section.id === sectionId
          ? {
              ...section,
              questions: [
                ...section.questions,
                {
                  id: uuid(),
                  text: "",
                  type: "text",
                  options: [],
                  required: false,
                },
              ],
            }
          : section,
      ),
    );
  };

  const handleQuestionChange = (sectionId, questionId, key, value) => {
    setSections(
      sections.map((section) =>
        section.id === sectionId
          ? {
              ...section,
              questions: section.questions.map((question) =>
                question.id === questionId
                  ? { ...question, [key]: value }
                  : question,
              ),
            }
          : section,
      ),
    );
  };

  const handleOptionChange = (sectionId, questionId, optionIndex, value) => {
    setSections(
      sections.map((section) =>
        section.id === sectionId
          ? {
              ...section,
              questions: section.questions.map((question) =>
                question.id === questionId
                  ? {
                      ...question,
                      options: question.options.map((option, index) =>
                        index === optionIndex ? value : option,
                      ),
                    }
                  : question,
              ),
            }
          : section,
      ),
    );
  };

  const handleAddOption = (sectionId, questionId) => {
    setSections(
      sections.map((section) =>
        section.id === sectionId
          ? {
              ...section,
              questions: section.questions.map((question) =>
                question.id === questionId
                  ? {
                      ...question,
                      options: [...question.options, ""],
                    }
                  : question,
              ),
            }
          : section,
      ),
    );
  };

  const handleDeleteQuestion = (sectionId, questionId) => {
    setSections(
      sections.map((section) =>
        section.id === sectionId
          ? {
              ...section,
              questions: section.questions.filter(
                (question) => question.id !== questionId,
              ),
            }
          : section,
      ),
    );
  };

  const handleDeleteSection = (sectionId) => {
    setSections(sections.filter((section) => section.id !== sectionId));
  };

  const handleCopyQuestion = (sectionId, questionId) => {
    const section = sections.find((s) => s.id === sectionId);
    const question = section.questions.find((q) => q.id === questionId);
    const copiedQuestion = { ...question, id: uuid() };

    setSections(
      sections.map((sec) =>
        sec.id === sectionId
          ? { ...sec, questions: [...sec.questions, copiedQuestion] }
          : sec,
      ),
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    try {
      const response = await addFormulaire({
        id: hotelId, // Pass the hotelId as the id
        FormulairData: {
          form_type: "pre_stay",
          form_structure: sections,
        },
      }).unwrap(); // unwrap() extracts the data or throws an error

      if (isSuccess) {
        toast.success("Form created successfully!");
        setShowResponse(false);
        console.log("Success:", response);
      }
    } catch (error) {
      if (isError) {
        toast.error(
          "Failed to create form, please check if a hotel is selected.",
        );
        console.error("Error:", error);
      }
    }
  };

  return (
    <div>
      {!showResponse && (
        <form onSubmit={handleSubmit}>
          <div className="d-flex align-items-start justify-content-between my-3">
            <FormTitle>Sections et questions Pre-Stay</FormTitle>
            <ButtonRow>
              <button type="button" className="btn" onClick={() => navigate(0)}>
                Créer un nouveau hôtel
              </button>
              <button type="submit" className="btn" disabled={isLoading}>
                {isLoading ? "Chargement..." : "Sauvegarder"}
              </button>
            </ButtonRow>
          </div>
          {sections.map((section, index) => (
            <div
              key={section.id}
              className="sectionContainer bg-light"
              style={styles.sectionContainer}
            >
              <Div className="col-7 col-lg-7">Section {index + 1}</Div>
              <div className="d-flex align-items-start gap-4 m-4 pb-5">
                <div className="d-flex flex-column gap-2 w-50">
                  <ButtonFormulaire
                    textButton="Ajouter une nouvelle section"
                    onClick={addSection}
                  />
                  <ButtonFormulaire
                    textButton="Ajouter un nom de section"
                    onClick={() => {
                      // Toggle visibility logic for section title input
                      setSections(
                        sections.map((sec) =>
                          sec.id === section.id
                            ? { ...sec, showTitleInput: !sec.showTitleInput }
                            : sec,
                        ),
                      );
                    }}
                  />
                  <ButtonFormulaire
                    textButton="Ajouter une question"
                    onClick={() => addQuestion(section.id)}
                  />
                  <ButtonFormulaire textButton="Ajouter des services gratuits" />
                  <ButtonFormulaire textButton="Ajouter des services payants" />
                  <ButtonFormulaire textButton="Ajouter Recuperation e-mail" />
                  {sections.length > 1 && (
                    <ButtonDelete
                      className="btn border bg-white"
                      onClick={() => handleDeleteSection(section.id)}
                    >
                      Supprimer cette section
                    </ButtonDelete>
                  )}
                </div>
                <div className="rounded h-100 d-flex flex-column w-100">
                  <div style={styles.sectionTitle}>
                    {section.showTitleInput ? (
                      <input
                        style={styles.input}
                        placeholder={`Nom de la Section ${index + 1}`}
                        value={section.title}
                        onChange={(e) =>
                          handleSectionTitleChange(section.id, e.target.value)
                        }
                      />
                    ) : (
                      section.title || `Nom de la Section ${index + 1}`
                    )}
                  </div>
                  {section.questions.length === 0 ? (
                    <div
                      className="d-flex card w-100 align-items-center justify-content-center flex-column"
                      style={{ height: "20em" }}
                    >
                      <GoInbox className="fs-2" />
                      <span>Aucune donnée pour le moment</span>
                    </div>
                  ) : (
                    section.questions.map((question) => (
                      <div key={question.id} style={styles.questionContainer}>
                        <div className="w-100">
                          <input
                            style={styles.input}
                            placeholder="Question"
                            value={question.text}
                            onChange={(e) =>
                              handleQuestionChange(
                                section.id,
                                question.id,
                                "text",
                                e.target.value,
                              )
                            }
                          />
                          <div style={styles.questionTop}>
                            <select
                              style={styles.input}
                              value={question.type}
                              onChange={(e) =>
                                handleQuestionChange(
                                  section.id,
                                  question.id,
                                  "type",
                                  e.target.value,
                                )
                              }
                            >
                              <option value="text">Text</option>
                              <option value="email">Email</option>
                              <option value="multiple_choice">
                                Multiple Choice
                              </option>
                              <option value="checkbox">Checkbox</option>
                              <option value="textarea">Textarea</option>
                            </select>
                            <FaTrashAlt
                              style={styles.iconButton}
                              onClick={() =>
                                handleDeleteQuestion(section.id, question.id)
                              }
                            />
                            <FaCopy
                              style={styles.iconButton}
                              onClick={() =>
                                handleCopyQuestion(section.id, question.id)
                              }
                            />
                          </div>
                          {(question.type === "checkbox" ||
                            question.type === "multiple_choice") && (
                            <div>
                              {question.options.map((option, index) => (
                                <div key={index} style={styles.optionContainer}>
                                  <input
                                    style={styles.input}
                                    placeholder={`Option ${index + 1}`}
                                    value={option}
                                    onChange={(e) =>
                                      handleOptionChange(
                                        section.id,
                                        question.id,
                                        index,
                                        e.target.value,
                                      )
                                    }
                                  />
                                </div>
                              ))}
                              <ButtonFormulaire
                                textButton="Ajouter une option"
                                onClick={() =>
                                  handleAddOption(section.id, question.id)
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          ))}
        </form>
      )}
    </div>
  );
};

export default FormBuilder;
