import {
  ButtonRow,
  FormContainer,
  FormRow,
  FormTitle,
  Label,
} from "pages/Admin/DashboardAdmin/HotelConfig/Hotel/StyledHotel";
import { Editor } from "primereact/editor";
import React, { useState } from "react";
import styled from "styled-components";

export default function Signatures() {
  const [text, setText] = useState("");
  const FormGroup = styled.div`
    margin-bottom: 20px;
    flex: 0 0 48%;
  `;
  const languages = [
    { code: "fr", label: "Francais" },
    { code: "en", label: "Anglais" },
    { code: "pt", label: "Portugais" },
    { code: "it", label: "Italien" },
  ];

  return (
    <FormContainer>
      <FormTitle>Signatures</FormTitle>
      <FormRow className="flex-wrap gap-2">
        {languages.map((lang) => (
          <FormGroup key={lang.code}>
            <Label>{lang.label}</Label>
            <Editor
              value={text}
              onTextChange={(e) => setText(e.htmlValue)}
              style={{ height: "150px" }}
              headerTemplate={renderHeader()}
            />
            <ButtonRow>
              <button className="btn">Annuler</button>
              <button className="btn">Sauvegarder</button>
            </ButtonRow>
          </FormGroup>
        ))}
      </FormRow>
    </FormContainer>
  );
}
const renderHeader = () => (
  <div className="ql-toolbar ql-snow">
    <span className="ql-formats">
      <button className="ql-bold">B</button>
      <button className="ql-italic">I</button>
      <button className="ql-underline">U</button>
      <button className="ql-color" value="black" />
      <select className="ql-size">
        <option value="small">S</option>
        <option value="normal" selected>
          M
        </option>
        <option value="large">L</option>
        <option value="huge">XL</option>
      </select>
      <button className="ql-align" value=""></button>
      <button className="ql-align" value="center"></button>
      <button className="ql-align" value="right"></button>
      <button className="ql-align" value="justify"></button>
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>
      <button className="ql-blockquote"></button>
      <button className="ql-link"></button>
      <button className="ql-image"></button>
      <button className="ql-emoji"></button>
      <button className="ql-code-block"></button>
    </span>
  </div>
);
