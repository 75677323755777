import React from "react";
import { BiPlus } from "react-icons/bi";
import { ButtonContainer } from "./FormulaireStyled";

export default function ButtonFormulaire({ textButton, onClick }) {
  return (
    <div
      onClick={onClick}
      className="btn border gap-3 d-flex align-items-center bg-white p-0 m-0 w-100"
      style={{ cursor: "pointer", borderRadius: "13px" }}
    >
      <ButtonContainer style={{ padding: "15px" }}>
        <BiPlus className="fs-4" />
      </ButtonContainer>
      <div>{textButton}</div>
    </div>
  );
}
