// ButtonRow.js
import {
  SubNavLink,
  ButtonRow,
} from "pages/Admin/DashboardAdmin/HotelConfig/StyleCOnfigHotel";
import React from "react";
import { IoArrowForward } from "react-icons/io5";
// import { ButtonRow } from "./StyledCard";

const HotelConfigLinks = ({ links }) => {
  return (
    <ButtonRow>
      {links.map((link, index) => (
        <SubNavLink
          key={index}
          to={link?.path}
          end
        >
          {link?.name}
          <IoArrowForward />
        </SubNavLink>
      ))}
    </ButtonRow>
  );
};

export default HotelConfigLinks;
