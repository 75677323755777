import React, { useState } from "react";

export default function Checkbox({
  flexDirection,
  options,
  my,
  me,
  justifycontent,
}) {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    onChange(option);
  };
  const flexDirectionClass =
    flexDirection === "row" ? "flex-row" : "flex-column";
  return (
    <div className={`d-flex ${justifycontent} ${flexDirectionClass}`}>
      {options.map((option) => (
        <label className={me} key={option.value}>
          <input
            className={`me-2 ${my}`}
            type="radio"
            name="autorisation"
            value={option.value}
            checked={selectedOption === option.value}
            onChange={() => handleOptionChange(option.value)}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
}
