// // Tabs.js
// import {
//   Tab,
//   TabsContainer,
// } from "pages/Admin/DashboardAdmin/HotelConfig/StyleCOnfigHotel";
// import React from "react";

// const Tabs = ({ activeTab, onChangeTab, tabLabels }) => {
//   return (
//     <TabsContainer>
//       {tabLabels.map((label, index) => (
//         <Tab
//           key={index}
//           active={activeTab === label}
//           onClick={() => onChangeTab(label)}
//         >
//           {label}
//         </Tab>
//       ))}
//     </TabsContainer>
//   );
// };

// export default Tabs;

// Tabs.js
import {
  Tab,
  TabsContainer,
} from "pages/Admin/DashboardAdmin/HotelConfig/StyleCOnfigHotel";
import React from "react";
// import { NavLink } from "react-router-dom";

const Tabs = ({ activeTab, onChangeTab, tabLabels }) => {
  return (
    <TabsContainer>
      {tabLabels.map((link, index) => (
        <Tab
          to={link?.path}
          key={index}
          // active={activeTab === label}
          // onClick={() => onChangeTab(label)}
          end={false}
        >
          {link?.name}
        </Tab>
      ))}
    </TabsContainer>
  );
};

export default Tabs;
