import styled from "styled-components";

export const FormGroup = styled.div`
  flex: 1;
  margin-right: 10px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  height: 134px;
  font-weight: 400;
  font-size: 16px;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  .btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #ffa500;
    color: white;
  }
`;

export const CardContainer = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
`;

export const CardHeader = styled.h3`
  margin-bottom: 10px;
`;

export const CardContent = styled.div`
  margin-bottom: 20px;
`;

export const CardActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const CardNotice = styled.div`
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
`;
